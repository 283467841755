import styled from "@emotion/styled";

import { useCustomTranslation } from "@/utils/useCustomTranslation";

import { CourseTypeEnum } from "@shared/generated/api/model";

export const ClassCard = ({
  type,
  title,
  teacherName,
  thumbnailImage,
  handleRoute,
}: {
  type: CourseTypeEnum;
  title: string;
  teacherName: string;
  thumbnailImage: string;
  handleRoute: () => void;
}) => {
  const { t } = useCustomTranslation();
  return (
    <CardWrapper onClick={handleRoute}>
      <ThumbnailWrapper>
        <img src={thumbnailImage} />
      </ThumbnailWrapper>
      <ContentWrapper>
        <ClassTypeText type={type}>
          {type === CourseTypeEnum.SINGLE
            ? t("one_day")
            : t("multiple_sessions")}
        </ClassTypeText>
        <ClassTitleText>{title}</ClassTitleText>
        <TeacherText>{teacherName}</TeacherText>
      </ContentWrapper>
    </CardWrapper>
  );
};

const CardWrapper = styled.div`
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.gray.gray10};
  display: flex;
  flex-direction: column;
  max-height: 384px;
  cursor: pointer;
`;

const ThumbnailWrapper = styled.div`
  width: 100%;
  max-height: 60%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px 8px 0 0;
  }
`;

const ContentWrapper = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  ${({ theme }) => theme.media.mobile} {
    padding: 8px;
  }
`;

const ClassTypeText = styled.div<{ type: string }>`
  ${({ theme }) => theme.typography.body1.b};
  ${({ theme }) => theme.media.mobile} {
    font-size: 14px;
  }
  color: ${({ type, theme }) =>
    type === CourseTypeEnum.SINGLE
      ? theme.colors.sub.orange.default
      : theme.colors.sub.green.default};
`;

const ClassTitleText = styled.div`
  ${({ theme }) => theme.typography.body1.b};
  min-height: 44px;
  ${({ theme }) => theme.media.mobile} {
    font-size: 14px;
  }
  color: ${({ theme }) => theme.colors.text.high.white};
`;

const TeacherText = styled.div`
  ${({ theme }) => theme.typography.body2.m};
  color: ${({ theme }) => theme.colors.text.medium.white};
`;
