/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * KAC-API
 * <b>K-Artist Class API<b/>
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  CreatePurchaseRequestDto,
  InitialPurchaseResponseDto,
  PaginatedPurchasesResponseDto,
  PaymentResponseDto,
  PurchaseByIdResponseDto,
  PurchaseControllerHandleTossFailureParams,
  PurchaseControllerHandleTossPaypalSuccessParams,
  PurchaseControllerHandleTossSuccessParams,
  PurchaseControllerPurchasesParams,
} from "../../../model";
import { axiosInstance } from "../../../config/axios-instance";
import type { ErrorType } from "../../../config/axios-instance";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * <strong>기능 설명:</strong><br>
<dd> 토스 결제 위젯 호출 전 결제 정보 검증 및 신규 혹은 기존 구매내용 재전달</dd><br>
<dd> orderId는 백단에서 생성하며, 기 생성된 orderId는 재활용(유효기간 10분)</dd><br>
<dd> 가상 계좌 결제 시, <b>isVirtualAccount:true</b> body에 추가 바랍니다.</dd><br>

<strong> 입력값 검증 내용</strong><br>
<ul>
  <li>강의 검증 - 존재하고, 공개된 강의인지 확인</li>
  <li>가격 검증 - 강의의 실 판매가와, 구매가격(amount)의 동일 여부, 할인율(discountRate)의 동일 여부 검증</li>
  <li>최종 결제 금액 검증 - 최종 결제 금액이 할인율, 포인트 사용된 금액과 일치한지 확인 </li>
  <li>중복 구매, 등록 검증 - 이미 구매했거나,이미 존재하는 수강생이거나, 결제로그가 FAILURE, PENDING이 아닌게 존재 하는지 검증</li>
  <li>수강 정원 초과 검증</li>
  <li>유저 포인트 검증</li>
  <li>가상 계좌 결제 시, 회차 시작 시간 -5일 검즘 (isVirtualAccount:true 옵션 필요) </li>
</ul>
 * @summary (강의) 구매 요청
 */
export const purchaseControllerInitiatePurchase = (
  createPurchaseRequestDto: CreatePurchaseRequestDto,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<InitialPurchaseResponseDto>(
    {
      url: `/purchase`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: createPurchaseRequestDto,
    },
    options,
  );
};

export const getPurchaseControllerInitiatePurchaseMutationOptions = <
  TError = ErrorType<InitialPurchaseResponseDto>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof purchaseControllerInitiatePurchase>>,
    TError,
    { data: CreatePurchaseRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof purchaseControllerInitiatePurchase>>,
  TError,
  { data: CreatePurchaseRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof purchaseControllerInitiatePurchase>>,
    { data: CreatePurchaseRequestDto }
  > = (props) => {
    const { data } = props ?? {};

    return purchaseControllerInitiatePurchase(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PurchaseControllerInitiatePurchaseMutationResult = NonNullable<
  Awaited<ReturnType<typeof purchaseControllerInitiatePurchase>>
>;
export type PurchaseControllerInitiatePurchaseMutationBody =
  CreatePurchaseRequestDto;
export type PurchaseControllerInitiatePurchaseMutationError =
  ErrorType<InitialPurchaseResponseDto>;

/**
 * @summary (강의) 구매 요청
 */
export const usePurchaseControllerInitiatePurchase = <
  TError = ErrorType<InitialPurchaseResponseDto>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof purchaseControllerInitiatePurchase>>,
    TError,
    { data: CreatePurchaseRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof purchaseControllerInitiatePurchase>>,
  TError,
  { data: CreatePurchaseRequestDto },
  TContext
> => {
  const mutationOptions =
    getPurchaseControllerInitiatePurchaseMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 수강생의 구매 내역 조회
 */
export const purchaseControllerPurchases = (
  params?: PurchaseControllerPurchasesParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<PaginatedPurchasesResponseDto>(
    { url: `/purchase`, method: "GET", params, signal },
    options,
  );
};

export const getPurchaseControllerPurchasesQueryKey = (
  params?: PurchaseControllerPurchasesParams,
) => {
  return [`/purchase`, ...(params ? [params] : [])] as const;
};

export const getPurchaseControllerPurchasesQueryOptions = <
  TData = Awaited<ReturnType<typeof purchaseControllerPurchases>>,
  TError = ErrorType<PaginatedPurchasesResponseDto>,
>(
  params?: PurchaseControllerPurchasesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof purchaseControllerPurchases>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getPurchaseControllerPurchasesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof purchaseControllerPurchases>>
  > = ({ signal }) =>
    purchaseControllerPurchases(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof purchaseControllerPurchases>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PurchaseControllerPurchasesQueryResult = NonNullable<
  Awaited<ReturnType<typeof purchaseControllerPurchases>>
>;
export type PurchaseControllerPurchasesQueryError =
  ErrorType<PaginatedPurchasesResponseDto>;

/**
 * @summary 수강생의 구매 내역 조회
 */
export const usePurchaseControllerPurchases = <
  TData = Awaited<ReturnType<typeof purchaseControllerPurchases>>,
  TError = ErrorType<PaginatedPurchasesResponseDto>,
>(
  params?: PurchaseControllerPurchasesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof purchaseControllerPurchases>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPurchaseControllerPurchasesQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 토스페이먼츠 결제 성공 콜백
 */
export const purchaseControllerHandleTossSuccess = (
  params: PurchaseControllerHandleTossSuccessParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<PaymentResponseDto>(
    { url: `/purchase/toss/success`, method: "GET", params, signal },
    options,
  );
};

export const getPurchaseControllerHandleTossSuccessQueryKey = (
  params: PurchaseControllerHandleTossSuccessParams,
) => {
  return [`/purchase/toss/success`, ...(params ? [params] : [])] as const;
};

export const getPurchaseControllerHandleTossSuccessQueryOptions = <
  TData = Awaited<ReturnType<typeof purchaseControllerHandleTossSuccess>>,
  TError = ErrorType<PaymentResponseDto>,
>(
  params: PurchaseControllerHandleTossSuccessParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof purchaseControllerHandleTossSuccess>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getPurchaseControllerHandleTossSuccessQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof purchaseControllerHandleTossSuccess>>
  > = ({ signal }) =>
    purchaseControllerHandleTossSuccess(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof purchaseControllerHandleTossSuccess>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PurchaseControllerHandleTossSuccessQueryResult = NonNullable<
  Awaited<ReturnType<typeof purchaseControllerHandleTossSuccess>>
>;
export type PurchaseControllerHandleTossSuccessQueryError =
  ErrorType<PaymentResponseDto>;

/**
 * @summary 토스페이먼츠 결제 성공 콜백
 */
export const usePurchaseControllerHandleTossSuccess = <
  TData = Awaited<ReturnType<typeof purchaseControllerHandleTossSuccess>>,
  TError = ErrorType<PaymentResponseDto>,
>(
  params: PurchaseControllerHandleTossSuccessParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof purchaseControllerHandleTossSuccess>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPurchaseControllerHandleTossSuccessQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 토스페이먼츠 페이팔 결제 성공 콜백
 */
export const purchaseControllerHandleTossPaypalSuccess = (
  params: PurchaseControllerHandleTossPaypalSuccessParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<PaymentResponseDto>(
    { url: `/purchase/toss/paypal/success`, method: "GET", params, signal },
    options,
  );
};

export const getPurchaseControllerHandleTossPaypalSuccessQueryKey = (
  params: PurchaseControllerHandleTossPaypalSuccessParams,
) => {
  return [
    `/purchase/toss/paypal/success`,
    ...(params ? [params] : []),
  ] as const;
};

export const getPurchaseControllerHandleTossPaypalSuccessQueryOptions = <
  TData = Awaited<ReturnType<typeof purchaseControllerHandleTossPaypalSuccess>>,
  TError = ErrorType<PaymentResponseDto>,
>(
  params: PurchaseControllerHandleTossPaypalSuccessParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof purchaseControllerHandleTossPaypalSuccess>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getPurchaseControllerHandleTossPaypalSuccessQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof purchaseControllerHandleTossPaypalSuccess>>
  > = ({ signal }) =>
    purchaseControllerHandleTossPaypalSuccess(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof purchaseControllerHandleTossPaypalSuccess>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PurchaseControllerHandleTossPaypalSuccessQueryResult = NonNullable<
  Awaited<ReturnType<typeof purchaseControllerHandleTossPaypalSuccess>>
>;
export type PurchaseControllerHandleTossPaypalSuccessQueryError =
  ErrorType<PaymentResponseDto>;

/**
 * @summary 토스페이먼츠 페이팔 결제 성공 콜백
 */
export const usePurchaseControllerHandleTossPaypalSuccess = <
  TData = Awaited<ReturnType<typeof purchaseControllerHandleTossPaypalSuccess>>,
  TError = ErrorType<PaymentResponseDto>,
>(
  params: PurchaseControllerHandleTossPaypalSuccessParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof purchaseControllerHandleTossPaypalSuccess>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPurchaseControllerHandleTossPaypalSuccessQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 토스페이먼츠 결제 실패 콜백
 */
export const purchaseControllerHandleTossFailure = (
  params: PurchaseControllerHandleTossFailureParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<void>(
    { url: `/purchase/toss/failure`, method: "GET", params, signal },
    options,
  );
};

export const getPurchaseControllerHandleTossFailureQueryKey = (
  params: PurchaseControllerHandleTossFailureParams,
) => {
  return [`/purchase/toss/failure`, ...(params ? [params] : [])] as const;
};

export const getPurchaseControllerHandleTossFailureQueryOptions = <
  TData = Awaited<ReturnType<typeof purchaseControllerHandleTossFailure>>,
  TError = ErrorType<unknown>,
>(
  params: PurchaseControllerHandleTossFailureParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof purchaseControllerHandleTossFailure>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getPurchaseControllerHandleTossFailureQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof purchaseControllerHandleTossFailure>>
  > = ({ signal }) =>
    purchaseControllerHandleTossFailure(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof purchaseControllerHandleTossFailure>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PurchaseControllerHandleTossFailureQueryResult = NonNullable<
  Awaited<ReturnType<typeof purchaseControllerHandleTossFailure>>
>;
export type PurchaseControllerHandleTossFailureQueryError = ErrorType<unknown>;

/**
 * @summary 토스페이먼츠 결제 실패 콜백
 */
export const usePurchaseControllerHandleTossFailure = <
  TData = Awaited<ReturnType<typeof purchaseControllerHandleTossFailure>>,
  TError = ErrorType<unknown>,
>(
  params: PurchaseControllerHandleTossFailureParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof purchaseControllerHandleTossFailure>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPurchaseControllerHandleTossFailureQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary (강의) 구매 완료 (전액 포인트 결제인 경우)
 */
export const purchaseControllerCompletePurchase = (
  purchaseId: number,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<PaymentResponseDto>(
    { url: `/purchase/${purchaseId}/complete`, method: "POST" },
    options,
  );
};

export const getPurchaseControllerCompletePurchaseMutationOptions = <
  TError = ErrorType<PaymentResponseDto>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof purchaseControllerCompletePurchase>>,
    TError,
    { purchaseId: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof purchaseControllerCompletePurchase>>,
  TError,
  { purchaseId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof purchaseControllerCompletePurchase>>,
    { purchaseId: number }
  > = (props) => {
    const { purchaseId } = props ?? {};

    return purchaseControllerCompletePurchase(purchaseId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PurchaseControllerCompletePurchaseMutationResult = NonNullable<
  Awaited<ReturnType<typeof purchaseControllerCompletePurchase>>
>;

export type PurchaseControllerCompletePurchaseMutationError =
  ErrorType<PaymentResponseDto>;

/**
 * @summary (강의) 구매 완료 (전액 포인트 결제인 경우)
 */
export const usePurchaseControllerCompletePurchase = <
  TError = ErrorType<PaymentResponseDto>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof purchaseControllerCompletePurchase>>,
    TError,
    { purchaseId: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof purchaseControllerCompletePurchase>>,
  TError,
  { purchaseId: number },
  TContext
> => {
  const mutationOptions =
    getPurchaseControllerCompletePurchaseMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 구매 내역 개별 조회
 */
export const purchaseControllerPurchase = (
  purchaseId: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<PurchaseByIdResponseDto>(
    { url: `/purchase/${purchaseId}`, method: "GET", signal },
    options,
  );
};

export const getPurchaseControllerPurchaseQueryKey = (purchaseId: number) => {
  return [`/purchase/${purchaseId}`] as const;
};

export const getPurchaseControllerPurchaseQueryOptions = <
  TData = Awaited<ReturnType<typeof purchaseControllerPurchase>>,
  TError = ErrorType<PurchaseByIdResponseDto>,
>(
  purchaseId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof purchaseControllerPurchase>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getPurchaseControllerPurchaseQueryKey(purchaseId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof purchaseControllerPurchase>>
  > = ({ signal }) =>
    purchaseControllerPurchase(purchaseId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!purchaseId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof purchaseControllerPurchase>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PurchaseControllerPurchaseQueryResult = NonNullable<
  Awaited<ReturnType<typeof purchaseControllerPurchase>>
>;
export type PurchaseControllerPurchaseQueryError =
  ErrorType<PurchaseByIdResponseDto>;

/**
 * @summary 구매 내역 개별 조회
 */
export const usePurchaseControllerPurchase = <
  TData = Awaited<ReturnType<typeof purchaseControllerPurchase>>,
  TError = ErrorType<PurchaseByIdResponseDto>,
>(
  purchaseId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof purchaseControllerPurchase>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPurchaseControllerPurchaseQueryOptions(
    purchaseId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
