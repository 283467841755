import { useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useRouter } from "next/router";
import { signIn } from "next-auth/react";
import * as yup from "yup";
import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";

import { encoding } from "@shared/lib/utils/crypto";
import { MENU } from "@/constants/menu";
import { MAX_WIDTH } from "@/constants/style/layout";
import { useCustomTranslation } from "@/utils/useCustomTranslation";

import {
  BottomLinks,
  LoginCard,
  LoginForm,
  SnsLoginContainer,
  SnsLoginRedirect,
} from "./";
import { useAuthControllerSignIn } from "@shared/generated/api/fn/kac/auth/auth";
import {
  ALERT_MODAL_TYPE,
  AlertModal,
  AlertModalType,
} from "@shared/lib/components/common/molecules/modal/alert-modal";

interface LoginFormInputs {
  email: string;
  password: string;
}

export const LoginTemplate = () => {
  const { t } = useCustomTranslation();
  const router = useRouter();
  const { callbackUrl } = router.query;
  const decodeCallbackUrl = useMemo(
    () => (callbackUrl ? decodeURIComponent(callbackUrl as string) : "/"),
    [callbackUrl],
  );

  const [isShowPassword, setIsShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );

  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);

  const [alertModalTexts, setAlertModalTexts] = useState<{
    type: AlertModalType;
    title?: string;
    subTitle?: string;
  }>({
    type: ALERT_MODAL_TYPE.ERROR,
    title: "",
    subTitle: "",
  });

  const onShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  const mutationSignIn = useAuthControllerSignIn({
    mutation: {
      onSuccess: async (data) => {
        setErrorMessage(undefined);
        /** 로그인 성공 */
        const { accessToken } = data;
        if (accessToken) {
          const result = await signIn("credentials", {
            accessToken,
            redirect: false,
          });
          if (result?.status === 200) {
            router.push(decodeCallbackUrl);
          }
        } else {
          /** 이미 소셜로 가입한 이메일로 일반 로그인 시도 시 */
          const { email, name, phoneNumber, socialType } = data;
          const queryObject = {
            email: email,
            name,
            phone: phoneNumber,
            providerType: socialType,
          };
          const encodeString = encoding(JSON.stringify(queryObject));
          router.push(
            `${MENU.CONNECT_USER.link.url}?user=${encodeURIComponent(encodeString)}`,
          );
        }
      },
      onError: (error) => {
        const data = error.response?.data;
        if (data) {
          const { errorCode } = data;
          if (errorCode === 100007) {
            setErrorMessage(t("account_does_not_exist"));
          } else if (errorCode === 100005) {
            setErrorMessage(t("email_or_password_is_incorrect"));
          } else if (errorCode === 100040) {
            setAlertModalTexts({
              type: ALERT_MODAL_TYPE.ERROR,
              title: t("password_reset_instructions"),
              subTitle: t(
                "hello_kac_site_renewal_password_reset_required",
              ).replaceAll("\\n", "\n"),
            });
            setIsAlertModalOpen(true);
          } else {
            setErrorMessage(t("login_failed"));
          }
        }
      },
    },
  });

  const schema = yup.object({
    email: yup
      .string()
      .trim(t("no_spaces_allowed"))
      .email(t("enter_valid_email_address"))
      .matches(/^(?!.* )/, t("cannot_contain_empty_spaces"))
      .required(t("enter_your_email")),
    password: yup
      .string()
      .required(t("enter_password"))
      .trim(t("no_spaces_allowed")),
  });

  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
    getValues,
  } = useForm<LoginFormInputs>({
    resolver: yupResolver<LoginFormInputs>(schema),
    mode: "onChange",
  });

  const onSubmit: SubmitHandler<LoginFormInputs> = async (data) => {
    mutationSignIn.mutate({
      data: {
        ...data,
        // isSns: false,
      },
    });
  };

  const onResetPassword = () => {
    const emailValue = getValues("email");
    const queryParam = emailValue
      ? `?email=${encodeURIComponent(emailValue)}`
      : "";
    router.push(`/find/password${queryParam}`);
  };

  return (
    <LoginTemplateContainer>
      {isAlertModalOpen && (
        <AlertModal
          {...alertModalTexts}
          isOpen={isAlertModalOpen}
          onClose={onResetPassword}
          confirmText={t("find_password")}
        />
      )}
      <SnsLoginRedirect />
      <LoginTemplateInnerContainer>
        <LoginCard>
          <LoginTitleContainer>{t("signin")}</LoginTitleContainer>
          <LoginForm
            onSubmit={handleSubmit(onSubmit)}
            register={register}
            errors={errors}
            isShowPassword={isShowPassword}
            onShowPassword={onShowPassword}
            errorMessage={errorMessage}
            isValid={isValid}
            isPending={mutationSignIn.isPending}
          />
          <BottomLinks />
          <SnsLoginContainer />
        </LoginCard>
      </LoginTemplateInnerContainer>
    </LoginTemplateContainer>
  );
};

const LoginTemplateContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoginTemplateInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: ${MAX_WIDTH.INNER_CONTAINER.PC}px;
  padding: 48px 24px;
`;

const LoginTitleContainer = styled.div`
  ${({ theme }) => theme.typography.h1.b};
  color: ${({ theme }) => theme.colors.text.high.black};

  text-align: center;
`;
