import { useState } from "react";
import { SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { useRouter } from "next/router";
import * as yup from "yup";
import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";

import { Button } from "@shared/lib/components/common/atom/button";
import { Icon } from "@shared/lib/components/common/atom/icon";
import { TextField } from "@shared/lib/components/common/atom/text-feild";
import {
  ALERT_MODAL_TYPE,
  AlertModal,
  AlertModalType,
} from "@shared/lib/components/common/molecules/modal/alert-modal";
import { ICON_SVG } from "@shared/lib/constants/import/icon-svg";
import { BUTTON_TYPE } from "@shared/lib/styles/theme";
import { MENU } from "@/constants/menu";
import { MAX_WIDTH } from "@/constants/style/layout";
import { useCustomTranslation } from "@/utils/useCustomTranslation";

import { useAuthControllerModifyPassword } from "@shared/generated/api/fn/kac/auth/auth";

interface ResetPasswordForm {
  password: string;
  matchNewPassword: string;
}

export const ResetPasswordTemplate = () => {
  const { t } = useCustomTranslation();
  const router = useRouter();
  const { resetCode } = router.query;

  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowMatchPassword, setIsShowMatchPassword] = useState(false);

  const onShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  const onShowMatchPassword = () => {
    setIsShowMatchPassword(!isShowMatchPassword);
  };

  const updatePasswordMutation = useAuthControllerModifyPassword({
    mutation: {
      onSuccess: () => {
        setIsUpdateSuccess(true);
        setAlertModalTexts({
          type: ALERT_MODAL_TYPE.SUCCESS,
          title: t("password_reset_success"),
          subTitle: t("password_changed_login_with_new_password").replaceAll(
            "\\n",
            "\n",
          ),
        });
        setIsAlertModalOpen(true);
      },
      onError: (e) => {
        setAlertModalTexts({
          type: ALERT_MODAL_TYPE.ERROR,
          title: t("password_reset_failed"),
          subTitle: t("password_reset_failed_due_to_timeout_or_error"),
        });
        setIsAlertModalOpen(true);
      },
    },
  });

  const schema = yup.object({
    password: yup
      .string()
      .min(8, t("password_must_be_8_to_20_characters"))
      .max(20, t("password_must_be_8_to_20_characters"))
      .matches(/[a-zA-Z0-9d~!@#$%^&*()_+=]$/, t("invalid_special_characters"))
      .matches(/^(?!.* )/, t("cannot_contain_empty_spaces"))
      .matches(
        /^(?!((?:[A-Za-z]+)|(?:[~!@#$%^&*()_+=]+)|(?:[0-9]+))$)[A-Za-z\d~!@#$%^&*()_+=]/,
        t("pwd_must_use_combination"),
      )
      .trim(t("no_spaces_allowed"))
      .required(t("enter_password")),
    matchNewPassword: yup
      .string()
      .min(8, t("password_must_be_8_to_20_characters"))
      .max(20, t("password_must_be_8_to_20_characters"))
      .matches(/[a-zA-Z0-9d~!@#$%^&*()_+=]$/, t("invalid_special_characters"))
      .matches(/^(?!.* )/, t("cannot_contain_empty_spaces"))
      .matches(
        /^(?!((?:[A-Za-z]+)|(?:[~!@#$%^&*()_+=]+)|(?:[0-9]+))$)[A-Za-z\d~!@#$%^&*()_+=]/,
        t("pwd_must_use_combination"),
      )
      .oneOf([yup.ref("password")], t("passwords_do_not_match"))
      .required(t("enter_password")),
  });

  const {
    handleSubmit,
    watch,
    setValue,
    getValues,
    setError,
    register,
    trigger,
    clearErrors,
    formState: { errors, isValid },
  } = useForm<ResetPasswordForm>({
    resolver: yupResolver<ResetPasswordForm>(schema),
    mode: "onChange",
  });

  const onSubmit: SubmitHandler<ResetPasswordForm> = async (data) => {
    updatePasswordMutation.mutate({
      data: {
        newPassword: data.password,
        resetCode: resetCode as string,
      },
    });
  };

  const onError: SubmitErrorHandler<ResetPasswordForm> = async (error) => {
    console.error(error);
  };

  const [isUpdateSuccess, setIsUpdateSuccess] = useState(false);
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
  const onAlertModalClose = () => {
    if (isUpdateSuccess) {
      router.push(MENU.LOGIN.link.url);
    }
    setIsAlertModalOpen(false);
  };

  const [alertModalTexts, setAlertModalTexts] = useState<{
    type: AlertModalType;
    title?: string;
    subTitle?: string;
  }>({
    type: ALERT_MODAL_TYPE.ERROR,
    title: "",
    subTitle: "",
  });

  return (
    <ResetPasswordTemplateContainer>
      <ResetPasswordTemplateInnerContainer>
        <ResetPasswordCardContainer>
          <ResetPasswordTitleContainer>
            <Title>{t("reset_password")}</Title>
            <SubTitle>
              <p>{t("enter_new_password")}</p>
            </SubTitle>
          </ResetPasswordTitleContainer>

          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <ResetPasswordFormContainer>
              <ResetPasswordTextField
                type={isShowPassword ? "text" : "password"}
                label={t("password")}
                placeholder={t("pwd_combination_min_8_chars")}
                {...register("password")}
                maxLength={50}
                isError={!!errors.password}
                message={errors?.password?.message}
                endAdornment={
                  <a onClick={onShowPassword}>
                    <Icon
                      icon={
                        isShowPassword
                          ? ICON_SVG.LINE.SIZE_18.ICON_EYE
                          : ICON_SVG.LINE.SIZE_18.ICON_EYE_OFF
                      }
                    />
                  </a>
                }
              />

              <ResetPasswordTextField
                type={isShowMatchPassword ? "text" : "password"}
                label={t("confirm_password")}
                placeholder={t("reenter_same_password")}
                {...register("matchNewPassword")}
                maxLength={50}
                isError={!!errors.matchNewPassword}
                message={errors?.matchNewPassword?.message}
                endAdornment={
                  <a onClick={onShowMatchPassword}>
                    <Icon
                      icon={
                        isShowMatchPassword
                          ? ICON_SVG.LINE.SIZE_18.ICON_EYE
                          : ICON_SVG.LINE.SIZE_18.ICON_EYE_OFF
                      }
                    />
                  </a>
                }
              />

              <ResetPasswordButtonContainer>
                <ResetPasswordButton
                  styleType={BUTTON_TYPE.FILL}
                  type={"submit"}
                  disabled={!isValid}
                >
                  {t("reset")}
                </ResetPasswordButton>
              </ResetPasswordButtonContainer>
            </ResetPasswordFormContainer>
          </form>
        </ResetPasswordCardContainer>

        {isAlertModalOpen && (
          <AlertModal
            {...alertModalTexts}
            isOpen={isAlertModalOpen}
            onClose={onAlertModalClose}
          />
        )}
      </ResetPasswordTemplateInnerContainer>
    </ResetPasswordTemplateContainer>
  );
};

const ResetPasswordTemplateContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 50vh;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const ResetPasswordTemplateInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  max-width: ${MAX_WIDTH.INNER_CONTAINER.PC}px;

  padding: 96px 24px;

  ${({ theme }) => theme.media.mobile} {
    padding: 96px 16px;
  }
`;

const ResetPasswordCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 365px;

  gap: 40px;

  ${({ theme }) => theme.media.mobile} {
    width: 100%;
    max-width: 343px;
  }
`;

const ResetPasswordTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

const Title = styled.div`
  ${({ theme }) => theme.typography.h1.b};
  color: ${({ theme }) => theme.colors.text.high.black};
`;

const SubTitle = styled.div`
  p {
    padding: 0;
    margin: 0;

    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 145%; /* 20.3px */
    letter-spacing: 0.5px;

    color: ${({ theme }) => theme.colors.text.medium.black};
  }
`;

const ResetPasswordFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const ResetPasswordTextField = styled(TextField)`
  gap: 4px;
  flex: 1 0 0;
`;

const ResetPasswordButtonContainer = styled.div``;

const ResetPasswordButton = styled(Button)`
  width: 100%;

  &.btn-fill {
    border-radius: 4px;
  }
`;
