import { useRouter } from "next/router";
import styled from "@emotion/styled";

import { Button } from "@shared/lib/components/common/atom/button";
import { BUTTON_TYPE } from "@shared/lib/styles/theme";
import { MAX_WIDTH } from "@/constants/style/layout";
import { useCustomTranslation } from "@/utils/useCustomTranslation";

const language = "ko";
const reviewCards = Array.from({ length: 4 }, (_, index) => ({
  id: index + 1,
  imageUrl: `/images/main/teacher-review${index + 1}_${language}.png`,
}));

export const TeacherApplyBanner = () => {
  const { t } = useCustomTranslation();
  const router = useRouter();

  return (
    <TeacherApplyContainer>
      <TeacherApplyContent>
        <TitleSection>
          <Title>{t("hear_actual_reviews_from_kac_instructors")}</Title>
          <Description>
            {t("register_as_instructor_and_meet_students")}
          </Description>
        </TitleSection>
        <ReviewCardsSection>
          {reviewCards.map((card) => (
            <ReviewCard key={card.id}>
              <ReviewImage
                src={card.imageUrl}
                alt={`Teacher Review ${card.id}`}
              />
            </ReviewCard>
          ))}
        </ReviewCardsSection>
        <BtnWrapper>
          <DetailButton
            type={"button"}
            styleType={BUTTON_TYPE.FILL}
            onClick={() => router.push("/teacher/support")}
          >
            {t("see_details")}
          </DetailButton>
        </BtnWrapper>
      </TeacherApplyContent>
    </TeacherApplyContainer>
  );
};

const TeacherApplyContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 80px 0;
  background: ${({ theme }) => theme.colors.brand.secondary.default};
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  ${({ theme }) => theme.media.tabletSm} {
    padding: 40px 0;
  }
`;

const TeacherApplyContent = styled.div`
  width: 100%;
  max-width: ${MAX_WIDTH.INNER_CONTAINER.PC}px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 40px;
  /* padding: 0 16px;
  @media (max-width: 930px) {
    padding: 0 0 0 16px;
  } */
  ${({ theme }) => theme.media.tabletSm} {
    gap: 24px;
  }
`;

const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 16px;
`;

const Title = styled.div`
  ${({ theme }) => theme.typography.h1.b};
  color: ${({ theme }) => theme.colors.text.high.white};
  ${({ theme }) => theme.media.tabletSm} {
    ${({ theme }) => theme.typography.h2.b};
    color: ${({ theme }) => theme.colors.text.high.white};
  }
`;

const Description = styled.div`
  ${({ theme }) => theme.typography.body1.b};
  color: ${({ theme }) => theme.colors.text.medium.white};
  ${({ theme }) => theme.media.tabletSm} {
    ${({ theme }) => theme.typography.body2.b};
    color: ${({ theme }) => theme.colors.text.medium.white};
  }
`;

const ReviewCardsSection = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;
  overflow-x: auto;
  padding-bottom: 10px;
  width: 100%;
  box-sizing: border-box;
  scroll-behavior: smooth;
  padding: 0 16px;
  &.scrolled {
    padding: 0;
  }
`;

const ReviewCard = styled.div`
  flex: 0 0 auto;
  width: 275px;
`;

const ReviewImage = styled.img`
  width: 100%;
  object-fit: cover;
`;

const DetailButton = styled(Button)`
  &.btn-${BUTTON_TYPE.FILL.toLowerCase()} {
    width: 100%;
    max-width: 385px;
    border-radius: 8px;
    margin: 0 auto;
    ${({ theme }) => theme.typography.body1.b};
    padding: 12px 24px;
    background-color: ${({ theme }) => theme.colors.brand.white.default};
    &:focus,
    &:hover {
      background-color: ${({ theme }) => theme.colors.brand.white.default};
      box-shadow: none;
    }
    ${({ theme }) => theme.media.tabletSm} {
      max-width: 345px;
    }
  }
`;

const BtnWrapper = styled.div`
  padding: 0 16px;
`;
