import { ChangeEvent, FocusEvent, KeyboardEvent, useRef } from "react";
import styled from "@emotion/styled";
import { TextField } from "@mui/material";

import { priceToLocaleString } from "@shared/lib/utils/price";
import { ContentCard } from "@/components/common";
import { useCustomTranslation } from "@/utils/useCustomTranslation";

interface PointProps {
  currentPoints: number;
  value: number | null;
  error: string | null;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur: (event: FocusEvent<HTMLInputElement>) => void;
  min: number;
  max: number;
}

export const PointForm = ({
  currentPoints,
  value,
  error,
  onChange,
  onBlur,
  min,
  max,
}: PointProps) => {
  const { t } = useCustomTranslation();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      inputRef.current?.blur();
    }
  };

  return (
    <ContentCard>
      <PointFormContainer>
        <TitleWrapper>
          <PointText>{t("point")}</PointText>
          <HasPointText>
            <span>{t("available_points")}</span>
            <span>
              {value === null
                ? priceToLocaleString(currentPoints)
                : priceToLocaleString(currentPoints - value)}
            </span>
            <span>P</span>
          </HasPointText>
        </TitleWrapper>
        <InputWrapper>
          <CustomTextField
            placeholder="0"
            type="number"
            value={value !== null ? value : ""}
            onChange={onChange}
            onBlur={onBlur}
            onKeyDown={handleKeyDown}
            inputRef={inputRef}
            error={!!error}
            slotProps={{ htmlInput: { min, max } }}
            disabled={currentPoints < 1000}
          />
          <Description error={!!error}>
            {error ?? t("available_from_1000_points")}
          </Description>
        </InputWrapper>
      </PointFormContainer>
    </ContentCard>
  );
};

const PointFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PointText = styled.div`
  ${({ theme }) => theme.typography.h3.b};
  color: ${({ theme }) => theme.colors.text.high.white};
`;

const HasPointText = styled.div`
  ${({ theme }) => theme.typography.body2.b};
  color: ${({ theme }) => theme.colors.text.high.white};
  span {
    font-weight: 900;
  }
  display: flex;
  gap: 4px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-transition: background-color 9999s ease-out;
    -webkit-box-shadow: 0 0 0px 1000px background-color inset !important;
    -webkit-text-fill-color: white !important;
  }
`;

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    backgroundColor: "black",
    color: "white",
    "&.Mui-focused": {
      backgroundColor: "black",
      color: "white !important",
    },
    "&:-webkit-autofill": {
      backgroundColor: "black",
      WebkitBoxShadow: "0 0 0px 1000px black inset",
      color: "white !important",
    },
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.colors.brand.white.disabled,
    },
    "&:hover fieldset": {
      borderColor: theme.colors.gray.gray80,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.colors.gray.gray80,
    },
    "&.Mui-disabled fieldset": {
      borderColor: theme.colors.brand.white.disabled,
    },
  },
}));

const Description = styled.div<{ error: boolean }>`
  ${({ theme }) => theme.typography.body3.m};
  color: ${({ error, theme }) =>
    error ? theme.colors.state.error.default : theme.colors.text.medium.white};
`;
