import { useRouter } from "next/router";
import styled from "@emotion/styled";

import {
  formatClassPeriodWithTime,
  formatClassStartTime,
} from "@shared/lib/utils/date/formatSchedule";
import {
  convertPriceString,
  discountedTotalPrice,
  priceToLocaleString,
} from "@shared/lib/utils/price";
import { ContentCard } from "@/components/common";
import { useCustomTranslation } from "@/utils/useCustomTranslation";

import {
  CourseInDetailResponseDto,
  CourseTypeEnum,
  InstructorInfoDto,
} from "@shared/generated/api/model";

interface ClassContentProps {
  classData: CourseInDetailResponseDto;
  paymentStatus?: boolean | false;
}

export const ClassContent = ({
  classData,
  paymentStatus,
}: ClassContentProps) => {
  const { t } = useCustomTranslation();
  const router = useRouter();
  const { cycleId } = router.query;

  const {
    title = "",
    type = CourseTypeEnum.SINGLE,
    thumbnailImage = "",
    price = 0,
    discountRate = 0,
    totalSessions = 0,
    cycles = [],
    language,
  } = classData || {};
  const { artistName } = (classData?.instructor as InstructorInfoDto) || {};
  const { name } = classData?.academy || {};

  // selectedCycle이 유효한지 확인
  const selectedCycle = cycles.find((cycle) => cycle.id === Number(cycleId));

  // selectedCycle이 없으면 기본값 설정
  const startsAt = selectedCycle?.startsAt || null;
  const endsAt = selectedCycle?.endsAt || null;

  // startsAt과 endsAt이 존재할 때만 처리
  const selectedDate =
    startsAt && endsAt
      ? formatClassPeriodWithTime(
          new Date(startsAt),
          new Date(endsAt),
          totalSessions ?? 0,
          false,
        )
      : "no date";

  return (
    <ContentCard>
      <CardTitle>
        {paymentStatus ? t("total_order_history") : t("class_information")}
      </CardTitle>
      <ClassInfoSection>
        <ThumbnailWrapper>
          <img
            src={thumbnailImage}
            alt="class image"
          />
        </ThumbnailWrapper>
        <ClassInfoWrapper>
          <TopWrapper>
            <ClassTitle>{title}</ClassTitle>
            <TeacherName>{artistName ? artistName : name}</TeacherName>
          </TopWrapper>
          <MidWrapper>
            <InfoBox>
              <IconTitle>
                <img src={"/images/icons/line-calendar.svg"} />
                <span>{t("class_type")}</span>
              </IconTitle>
              <InfoTitle
                isType={true}
                type={type}
              >
                LIVE{" "}
                {type === CourseTypeEnum.SINGLE
                  ? t("one_day")
                  : t("multiple_sessions")}
              </InfoTitle>
            </InfoBox>
            <InfoBox>
              <IconTitle>
                <img src={"/images/icons/line-time.svg"} />
                <span>{t("selected_schedule")}</span>
              </IconTitle>
              <InfoTitle>
                {selectedDate && type === CourseTypeEnum.SINGLE && startsAt
                  ? formatClassStartTime(new Date(startsAt))
                  : selectedDate}
              </InfoTitle>
            </InfoBox>
          </MidWrapper>
          {!paymentStatus && (
            <div>
              {discountRate !== 0 && (
                <CancelledText>
                  {language !== "ko" && t("dollar")}
                  {priceToLocaleString(price || 0)}{" "}
                  {language === "ko" && t("won")}
                </CancelledText>
              )}
              <CardTitle>
                {type === CourseTypeEnum.MULTI && t("per_session")}{" "}
                {language !== "ko" && t("dollar")}
                {convertPriceString(
                  price,
                  discountRate,
                  type,
                  totalSessions,
                  language,
                )}
                {language === "ko" && t("won")}
              </CardTitle>
              {type === CourseTypeEnum.MULTI && (
                <DetailText>
                  {t("total")} {language !== "ko" && t("dollar")}
                  {discountedTotalPrice(price || 0, discountRate, language)}
                  {language === "ko" && t("won")} / {totalSessions}
                  {t("times")}
                </DetailText>
              )}
            </div>
          )}
        </ClassInfoWrapper>
      </ClassInfoSection>
    </ContentCard>
  );
};

const CardTitle = styled.div`
  ${({ theme }) => theme.typography.h3.b};
  color: ${({ theme }) => theme.colors.text.high.white};
`;

const ClassInfoSection = styled.div`
  width: 100%;
  display: flex;
  gap: 26px;
  padding-top: 24px;
  @media (max-width: 430px) {
    flex-wrap: wrap;
  }
`;

const ThumbnailWrapper = styled.div`
  width: 40%;
  @media (max-width: 570px) {
    width: 50%;
  }
  @media (max-width: 430px) {
    width: 100%;
  }
  border-radius: 8px;
  img {
    width: 100%;
    border-radius: 8px;
    object-fit: cover;
  }
`;

const ClassInfoWrapper = styled.div`
  width: 60%;
  @media (max-width: 570px) {
    width: 50%;
  }
  @media (max-width: 430px) {
    width: 100%;
  }
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const TopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const MidWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ClassTitle = styled.div`
  ${({ theme }) => theme.typography.h3.m};
  color: ${({ theme }) => theme.colors.text.high.white};
`;

const TeacherName = styled.div`
  ${({ theme }) => theme.typography.body2.b};
  color: ${({ theme }) => theme.colors.text.medium.white};
  display: flex;
  align-items: center;
  gap: 2px;
`;

const InfoBox = styled.div`
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  letter-spacing: -0.4px;
`;

const IconTitle = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.typography.body2.m};
  color: ${({ theme }) => theme.colors.icon.inactive.white};
  width: 90px;
  span {
    padding-left: 4px;
  }
`;

const InfoTitle = styled.div<{ isType?: boolean; type?: CourseTypeEnum }>`
  ${({ theme }) => theme.typography.body2.m};
  color: ${({ isType, type, theme }) =>
    isType
      ? type === CourseTypeEnum.SINGLE
        ? theme.colors.sub.orange.default
        : theme.colors.sub.green.default
      : theme.colors.gray.gray100};
`;

const CancelledText = styled.s`
  ${({ theme }) => theme.typography.body1.m};
  color: ${({ theme }) => theme.colors.text.medium.white};
  display: flex;
`;

const DetailText = styled.div`
  ${({ theme }) => theme.typography.body1.m};
  color: ${({ theme }) => theme.colors.text.medium.white};
  display: flex;
`;
