import { useState } from "react";
import { useRouter } from "next/router";
import styled from "@emotion/styled";

import { getRemaining } from "@shared/lib/utils/classData";
import { formatSingleClassDate } from "@shared/lib/utils/date/formatSchedule";

import { AccordionList } from "@/components";
import { useAccessToken } from "@/hooks/auth/use-access-token";
import {
  InquiryType,
  useInquiryTypeStore,
  useModalStore,
  useScheduleStore,
} from "@/stores";
import { useCustomTranslation } from "@/utils/useCustomTranslation";

import { useCourseControllerCourse } from "@shared/generated/api/fn/kac/course/course";
import {
  CourseInDetailResponseDto,
  CourseTypeEnum,
} from "@shared/generated/api/model";
import {
  ALERT_MODAL_TYPE,
  AlertModal,
  AlertModalType,
} from "@shared/lib/components/common/molecules/modal/alert-modal";

export const ScheduleSelection = () => {
  const { t } = useCustomTranslation();
  const router = useRouter();
  const { id } = router.query;
  const isKo = router.locale === "ko";
  const { accessToken } = useAccessToken();
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
  const [alertModalTexts, setAlertModalTexts] = useState<{
    type: AlertModalType;
    title?: string;
    subTitle?: string;
  }>({
    type: ALERT_MODAL_TYPE.ERROR,
    title: "",
    subTitle: "",
  });

  const setSchedule = useScheduleStore((state) => state.setScheduleId);
  const clearSchedule = useScheduleStore((state) => state.clearScheduleId);

  const toggleItem = (index: number, cycleId: number) => {
    if (openIndex === index) {
      setOpenIndex(null);
      clearSchedule();
    } else {
      setOpenIndex(index);
      setSchedule(cycleId);
    }
  };

  const { setInquiryType } = useInquiryTypeStore();
  const { openModal } = useModalStore();

  const handleScheduleInquiry = () => {
    if (accessToken) {
      setInquiryType(InquiryType.Schedule);
      openModal("inquiryModal");
    } else {
      setAlertModalTexts({
        type: ALERT_MODAL_TYPE.ERROR,
        title: t("login_required"),
        subTitle: "",
      });
      setIsAlertModalOpen(true);
    }
  };

  const { data: classData, isLoading } = useCourseControllerCourse(Number(id), {
    isCyclePublic: true,
    isCoursePublic: true,
    isDeleted: false,
  });

  if (isLoading) return <div>Loading...</div>;

  const { cycles, type, totalSessions } =
    classData as CourseInDetailResponseDto;

  return (
    <>
      {isAlertModalOpen && (
        <AlertModal
          {...alertModalTexts}
          isOpen={isAlertModalOpen}
          onClose={() => setIsAlertModalOpen(false)}
          onConfirm={() => router.push(`/login?callbackUrl=${router.asPath}`)}
          confirmText={t("signin")}
        />
      )}
      <Title>{t("schedule_selection")}</Title>
      <SubTitle>{t("select_period_and_check_schedule")}</SubTitle>
      {type === CourseTypeEnum.SINGLE ? (
        <CardWrapper>
          {cycles.map((cycle, index) => {
            const { dates, day, time } = formatSingleClassDate(
              new Date(cycle.startsAt),
            );
            return (
              <ScheduleCard
                key={index}
                onClick={() =>
                  !cycle.enrollmentClosed && toggleItem(index, cycle.id)
                }
                isOpen={!cycle.enrollmentClosed && openIndex === index}
                disabled={cycle.enrollmentClosed}
              >
                <ScheduleText
                  isOpen={!cycle.enrollmentClosed && openIndex === index}
                  disabled={cycle.enrollmentClosed}
                >
                  <div>{dates}</div>
                  <div>{day}</div>
                  <div>{time}</div>
                </ScheduleText>
                <RemainText>
                  {cycle.enrollmentClosed || !cycle.selling
                    ? t("closed")
                    : `${getRemaining(
                        cycle.maxEnrollments,
                        cycle.cycleEnrollments.length,
                      )}${t("spots_remaining")}
                  `}
                </RemainText>
              </ScheduleCard>
            );
          })}
          <AddScheduleCard onClick={handleScheduleInquiry}>
            {t("schedule_consultation").replaceAll("\\n", "\n")}
          </AddScheduleCard>
        </CardWrapper>
      ) : (
        <ListWrapper>
          <AccordionList
            cycles={cycles}
            totalSessions={totalSessions || 0}
          />
        </ListWrapper>
      )}
    </>
  );
};

const Title = styled.div`
  ${({ theme }) => theme.typography.h3.b};
  color: ${({ theme }) => theme.colors.text.high.white};
`;

const SubTitle = styled.div`
  ${({ theme }) => theme.typography.body2.m};
  padding-top: 4px;
  color: ${({ theme }) => theme.colors.text.medium.white};
`;

const CardWrapper = styled.div`
  cursor: pointer;
  width: 100%;
  padding-top: 24px;
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(7, 1fr);
  @media (max-width: 1180px) {
    grid-template-columns: repeat(5, 1fr);
  }
  ${({ theme }) => theme.media.tablet} {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 890px) {
    grid-template-columns: repeat(3, 1fr);
  }
  ${({ theme }) => theme.media.tabletSm} {
    grid-template-columns: repeat(6, 1fr);
  }
  @media (max-width: 670px) {
    grid-template-columns: repeat(5, 1fr);
  }
  @media (max-width: 575px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 460px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const ScheduleCard = styled.div<{ isOpen: boolean; disabled: boolean }>`
  padding: 16px;
  min-height: 130px;
  border-radius: 10px;
  border: ${({ disabled, isOpen, theme }) =>
    disabled
      ? `1px solid ${theme.colors.gray.gray20}`
      : isOpen
        ? `2px solid ${theme.colors.icon.active.white}`
        : `1px solid ${theme.colors.icon.inactive.white}`};
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
`;

const ScheduleText = styled.div<{ isOpen: boolean; disabled: boolean }>`
  ${({ theme }) => theme.typography.body2.b};
  color: ${({ disabled, isOpen, theme }) =>
    disabled
      ? theme.colors.gray.gray20
      : isOpen
        ? theme.colors.icon.active.white
        : theme.colors.icon.inactive.white};
`;

const RemainText = styled.div`
  ${({ theme }) => theme.typography.body2.r};
  color: ${({ theme }) => theme.colors.state.error.default};
`;

const AddScheduleCard = styled.div`
  white-space: pre-line;
  padding: 16px;
  min-height: 130px;
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.brand.secondary.default};
  ${({ theme }) => theme.typography.body2.b};
  color: ${({ theme }) => theme.colors.text.high.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
`;

const ListWrapper = styled.div`
  padding-top: 24px;
`;
