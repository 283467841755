import styled from "@emotion/styled";

import { useCustomTranslation } from "@/utils/useCustomTranslation";

interface InquiryTypeButtonProps {
  selected: string;
  handleInquiryType: (type: string) => void;
  readonly?: boolean | false;
}

export const InquiryTypeButtons = ({
  selected,
  handleInquiryType,
  readonly,
}: InquiryTypeButtonProps) => {
  const { t } = useCustomTranslation();

  return (
    <ButtonWrapper>
      <TypeButton
        active={selected === t("class_inquiry")}
        onClick={() => handleInquiryType(t("class_inquiry"))}
        readonly={readonly || false}
        type="button"
      >
        {t("class_inquiry")}
      </TypeButton>
      <TypeButton
        active={selected === t("class_qna")}
        onClick={() => handleInquiryType(t("class_qna"))}
        readonly={readonly || false}
        type="button"
      >
        {t("class_qna")}
      </TypeButton>
    </ButtonWrapper>
  );
};

const ButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const TypeButton = styled.button<{
  active: boolean | false;
  readonly: boolean | false;
}>`
  ${({ theme, active }) =>
    active ? theme.typography.body2.b : theme.typography.body2.r};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 10px 24px;
  border-radius: 8px;
  cursor: ${({ readonly }) => (readonly ? "initial" : "pointer")};
  padding: 8px 16px;
  background-color: ${({ theme, active }) =>
    active ? theme.colors.sub.blue.default : theme.colors.mui.text.disabled};
  color: ${({ theme, active }) =>
    active ? theme.colors.text.high.white : theme.colors.text.disabled.black};

  &:hover {
    background-color: ${({ theme, active, readonly }) =>
      !readonly &&
      (active
        ? theme.colors.sub.blue.hover
        : theme.colors.brand.tertiary.hover)};
    box-shadow: none;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.brand.white.disabled};
    color: ${({ theme }) => theme.colors.text.disabled.white};
  }
`;
