import { useState } from "react";
import { Autoplay, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import styled from "@emotion/styled";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";

import { useMediaQueryMaxWidth } from "@shared/lib/hooks/use-media-query-max-width";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { AcademyMainContentsDto } from "@shared/generated/api/model";
import { useRouter } from "next/router";

export const AcademyBrandBanner = ({
  academyData,
}: {
  academyData: AcademyMainContentsDto;
}) => {
  const [selectedId, setSelectedId] = useState<number>(
    academyData.section[0].sectionId,
  );
  const { academyBanners, section } = academyData;
  const filteredBanners = academyBanners.filter(
    (banner) => banner.sectionId === selectedId,
  );
  const comingSoonBanner = {
    academyId: null,
    thumbnailImagePc: "/images/main/coming-soon_pc.png",
    thumbnailImageMo: "/images/main/coming-soon_mo.png",
  };
  const updatedBanners =
    filteredBanners.length <= 1
      ? [...filteredBanners, comingSoonBanner]
      : filteredBanners;

  const { isTabletSm } = useMediaQueryMaxWidth();

  const [isScrolled, setIsScrolled] = useState(false);

  const handleResize = (swiper: any) => {
    const isOverflowing =
      swiper.wrapperEl.scrollWidth > swiper.wrapperEl.clientWidth;
    setIsScrolled(isOverflowing);
  };

  const router = useRouter();

  return (
    <BannerWrapper isScrolled={isScrolled}>
      <ControllerContainer>
        <SectionButtonsWrapper>
          {section.map((section) => (
            <SectionButton
              key={section.sectionId}
              active={section.sectionId === selectedId}
              onClick={() => setSelectedId(section.sectionId)}
            >
              {section.name}
            </SectionButton>
          ))}
        </SectionButtonsWrapper>
        {!isTabletSm && filteredBanners.length !== 0 && (
          <NavigationWrapper>
            <NavigationButton className="prev-button">
              <KeyboardArrowLeftRoundedIcon />
            </NavigationButton>
            <NavigationButton className="next-button">
              <KeyboardArrowRightRoundedIcon />
            </NavigationButton>
          </NavigationWrapper>
        )}
      </ControllerContainer>
      <Swiper
        style={{ paddingLeft: "16px" }}
        modules={[Navigation, Autoplay]}
        slidesPerView={1.3}
        spaceBetween={"20px"}
        centeredSlides={false}
        navigation={{
          prevEl: ".prev-button",
          nextEl: ".next-button",
        }}
        autoplay={{ delay: 3000 }}
        speed={1500}
        loop
        onInit={handleResize}
        onResize={handleResize}
      >
        {updatedBanners.map((banner, idx) => (
          <SwiperSlide
            key={banner?.academyId ?? idx}
            style={{ cursor: "pointer" }}
          >
            <StyledImage
              onClick={() =>
                banner?.academyId && router.push(`/academy/${banner.academyId}`)
              }
              src={
                isTabletSm ? banner.thumbnailImageMo : banner.thumbnailImagePc
              }
              alt={`Banner ${banner.academyId}`}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </BannerWrapper>
  );
};

const BannerWrapper = styled.div<{ isScrolled: boolean }>`
  position: relative;
  padding: ${({ isScrolled }) => (isScrolled ? "0" : "0 16px")};
  width: 100%;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  max-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
  border-radius: 8px;
  ${({ theme }) => theme.media.tabletSm} {
    max-height: 100%;
  }
`;

const ControllerContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  gap: 9px;
  padding: 0 16px 24px;
`;

const SectionButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  overflow-x: auto;
`;

const SectionButton = styled.button<{ active?: boolean | false }>`
  ${({ theme }) => theme.typography.body1.b};
  display: flex;
  padding: 7px 15px;
  justify-content: center;
  align-items: center;
  color: ${({ theme, active }) =>
    active ? theme.colors.text.high.black : theme.colors.text.high.white};
  border: 1px solid ${({ theme }) => theme.colors.icon.active.white};
  background: ${({ theme, active }) =>
    active ? theme.colors.brand.white.default : "transparent"};
  border-radius: 40px;
  letter-spacing: -0.4px;
  white-space: nowrap;
  height: 38px;
`;

const NavigationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  display: flex;
  gap: 8px;
`;

const NavigationButton = styled.button`
  background-color: ${({ theme }) => theme.colors.brand.secondary.default};
  border-radius: 40px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  color: ${({ theme }) => theme.colors.icon.inactive.white};
`;
