import { useRouter } from "next/router";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { Skeleton } from "@mui/material";
import styled from "@emotion/styled";

import { useMediaQueryMaxWidth } from "@shared/lib/hooks/use-media-query-max-width";

import { NoClassPlaceholder } from "@/components/template/my/class/NoClassPlaceholder";
import { PageHeader } from "@/components/ui/headers";
import { useAccessToken } from "@/hooks/auth/use-access-token";
import { useMyWishlistCourses } from "@/hooks/me/use-my-wishlist-courses";
import { useCustomTranslation } from "@/utils/useCustomTranslation";

import {
  getUserControllerCoursesQueryKey,
  useUserControllerCourses,
} from "@shared/generated/api/fn/kac/user/user";
import {
  CourseByStudentResponseDtoCategory,
  CourseSectionDto,
} from "@shared/generated/api/model";
import { ClassCard } from "./ClassCard";
import { Button } from "@shared/lib/components/common/atom/button";
import { BUTTON_TYPE } from "@shared/lib/styles/theme";

export const MyClassTemplate = () => {
  const { t } = useCustomTranslation();
  const router = useRouter();

  const { accessToken } = useAccessToken();
  const { data: myCoursesData, isLoading: isMyCoursesLoading } =
    useUserControllerCourses(
      {},
      {
        request: { accessToken },
        query: {
          enabled: !!accessToken,
          queryKey: getUserControllerCoursesQueryKey(),
        },
      },
    );

  const { data: myWishlistData, isLoading: isMyWishlistLoading } =
    useMyWishlistCourses();

  const { isMobile, isTabletSm } = useMediaQueryMaxWidth();

  const gridColumns = isMobile ? 1 : isTabletSm ? 2 : 3;

  const handleClick = () => {
    router.push("/my/schedule");
  };

  const handleScheduleLink = (id: number | null) => {
    // console.log(id);
    id && router.push(`/my/schedule/${id}`);
  };

  const handleClassLink = (
    category: CourseByStudentResponseDtoCategory,
    id: number,
  ) => {
    const { router: index } = category?.section as CourseSectionDto;
    router.push(`/class/live/${index}/${id}`);
  };

  const CourseListSkeleton = ({
    section,
  }: {
    section: "enrolled" | "wishlist";
  }) => (
    <ClassCardWrapper>
      {Array.from({ length: gridColumns }).map((_, index) => (
        <Skeleton
          key={index}
          variant="rectangular"
          width="100%"
          sx={{ maxWidth: "272px", borderRadius: "8px" }}
          height="300px"
        ></Skeleton>
      ))}
    </ClassCardWrapper>
  );

  return (
    <MyClassTemplateContainer>
      <PageHeader title={t("my_class")} />
      <MyClassContainer>
        <ButtonContainer>
          <ButtonWrapper>
            <TypeButton
              active={true}
              readonly={true}
              type="button"
            >
              LIVE
            </TypeButton>
            {/* <TypeButton
              active={false}
              readonly={true}
              type="button"
            >
              VOD
            </TypeButton> */}
          </ButtonWrapper>
          <ScheduleButton
            type={"submit"}
            styleType={BUTTON_TYPE.OUTLINE}
            disabled={false}
            onClick={handleClick}
          >
            <CalendarTodayIcon
              sx={{ width: 18, height: 18, maxWidth: "145px", pl: "2px" }}
            />
            {t("check_schedule")}
            <ArrowForwardIosIcon />
          </ScheduleButton>
        </ButtonContainer>
        {/* Class List */}
        <ClassCardSectionWrapper>
          <ClassCardSection>
            <LiveTitle>{t("attend_live_class")}</LiveTitle>
            {isMyCoursesLoading ? (
              <CourseListSkeleton section="enrolled" />
            ) : !myCoursesData?.count ? (
              <NoClassPlaceholder placeholder={t("no_purchased_lectures")} />
            ) : (
              <ClassCardWrapper>
                {myCoursesData &&
                  myCoursesData?.rows?.map((course) => (
                    <ClassCard
                      key={`enrolled-${course.id}`}
                      course={course}
                      isLiked={false}
                      handleLink={() =>
                        handleScheduleLink(course?.nearScheduleId || null)
                      }
                    ></ClassCard>
                  ))}
              </ClassCardWrapper>
            )}
          </ClassCardSection>
          <ClassCardSection>
            <LiveTitle>{t("liked_live_classes")}</LiveTitle>
            {isMyWishlistLoading ? (
              <CourseListSkeleton section="wishlist" />
            ) : !myWishlistData?.count ? (
              <NoClassPlaceholder placeholder={t("no_liked_classes")} />
            ) : (
              <ClassCardWrapper>
                {myWishlistData?.rows?.map((course) => (
                  <ClassCard
                    key={`wishlist-${course.id}`}
                    course={course}
                    isLiked={true}
                    handleLink={() =>
                      handleClassLink(course.category, course.id)
                    }
                  ></ClassCard>
                ))}
              </ClassCardWrapper>
            )}
          </ClassCardSection>
        </ClassCardSectionWrapper>
      </MyClassContainer>
    </MyClassTemplateContainer>
  );
};

const MyClassTemplateContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const MyClassContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const ScheduleButton = styled(Button)`
  &.btn-${BUTTON_TYPE.OUTLINE.toLowerCase()} {
    ${({ theme }) => theme.typography.body2.m};
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.colors.icon.disabled.black};
    padding: 8px 10px 8px 12px;
    color: ${({ theme }) => theme.colors.text.high.black};
    &:focus,
    &:hover {
      border: 1px solid ${({ theme }) => theme.colors.icon.disabled.black};
    }
  }
`;

const ClassCardSectionWrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const ClassCardSection = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const LiveTitle = styled.div`
  ${({ theme }) => theme.typography.h3.b};
`;

const ClassCardWrapper = styled.div`
  width: 100%;
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(3, 1fr);
  @media (max-width: 1165px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const TypeButton = styled.button<{
  active: boolean | false;
  readonly: boolean | false;
}>`
  ${({ theme, active }) =>
    active ? theme.typography.body2.b : theme.typography.body2.r};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 10px 24px;
  border-radius: 8px;
  cursor: ${({ readonly }) => (readonly ? "initial" : "pointer")};
  padding: 8px 16px;
  background-color: ${({ theme, active }) =>
    active ? theme.colors.sub.blue.default : theme.colors.mui.text.disabled};
  color: ${({ theme, active }) =>
    active ? theme.colors.text.high.white : theme.colors.text.disabled.black};

  &:hover {
    background-color: ${({ theme, active, readonly }) =>
      !readonly &&
      (active
        ? theme.colors.sub.blue.hover
        : theme.colors.brand.tertiary.hover)};
    box-shadow: none;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.brand.white.disabled};
    color: ${({ theme }) => theme.colors.text.disabled.white};
  }
`;
