import { ChangeEvent, FocusEvent, useEffect, useState } from "react";
import { useRouter } from "next/router";
import styled from "@emotion/styled";
import {
  loadTossPayments,
  TossPaymentsPayment,
} from "@tosspayments/tosspayments-sdk";

import { Button } from "@shared/lib/components/common/atom/button";
import { BUTTON_TYPE } from "@shared/lib/styles/theme";
import { calculateDiscountedPrice } from "@shared/lib/utils/price";
import { openToast, TOAST_TYPE } from "@shared/lib/utils/toast";
import {
  ClassContent,
  CustomerInfo,
  PointForm,
  PriceContent,
  PurchaseForm,
  PurchaseType,
  SuccessContent,
  WidgetsPaymentMethodCode,
} from "@/components";
import { useAccessToken } from "@/hooks/auth/use-access-token";
import { useProfile } from "@/hooks/auth/use-profile";
import { useCustomTranslation } from "@/utils/useCustomTranslation";

import { useCourseControllerCourse } from "@shared/generated/api/fn/kac/course/course";
import {
  usePurchaseControllerCompletePurchase,
  usePurchaseControllerInitiatePurchase,
} from "@shared/generated/api/fn/kac/purchase/purchase";
import { CourseInDetailResponseDto } from "@shared/generated/api/model";

const generateRandomString = (): string =>
  Math.random().toString(36).substring(2, 15) +
  Math.random().toString(36).substring(2, 15);

export const PaymentTemplate = () => {
  const { t } = useCustomTranslation();
  const router = useRouter();
  const lang = router.locale === "ko" ? "" : "/en";
  const { courseId, cycleId } = router.query;
  const [usedPoints, setUsedPoints] = useState<number | null>(null);
  const [finalPrice, setFinalPrice] = useState<number>(0);
  const [checked, setChecked] = useState<boolean>(false);
  const [pointError, setPointError] = useState<string | null>(null);
  const [currentPoints, setCurrentPoints] = useState(0);
  const [orderId, setOrderId] = useState("");
  const [payment, setPayment] = useState<TossPaymentsPayment | null>(null);
  const [amount, setAmount] = useState({
    currency: "KRW", //
    // currency: "USD",
    value: finalPrice,
  });
  const [isKo, setIsKo] = useState(true);
  const [accountError, setAccountError] = useState(false);
  const [selectedPurchaseType, setSelectedPurchaseType] =
    useState<WidgetsPaymentMethodCode | null>(null);
  const [customerData, setCustomerData] = useState({
    name: "",
    phoneNumber: "",
    email: "",
  });
  const [purchaseData, setPurchaseData] = useState();
  const [clientKey, setClientKey] = useState<string | null>(null);

  const { accessToken, isLoading: isTokenLoading } = useAccessToken();
  const { data: profile } = useProfile();
  const { data: classData } = useCourseControllerCourse(Number(courseId));
  const { price, discountRate, language } =
    (classData as CourseInDetailResponseDto) || {};

  const customerKey = generateRandomString();

  useEffect(() => {
    const fetchPayment = async () => {
      if (clientKey) {
        try {
          const tossPayments = await loadTossPayments(clientKey);
          const payment = tossPayments.payment({
            customerKey,
          });
          setPayment(payment);
        } catch (error) {
          console.error("Error fetching payment:", error);
        }
      }
    };

    fetchPayment();
  }, [clientKey]);

  useEffect(() => {
    setAmount((prev) => ({
      ...prev,
      value: finalPrice,
    }));
  }, [finalPrice]);

  useEffect(() => {
    const isKo = language === "ko";
    const key = isKo
      ? process.env.NEXT_PUBLIC_TOSS_PAYMENTS_CLIENT_KEY
      : process.env.NEXT_PUBLIC_TOSS_PAYPAL_CLIENT_KEY || "";
    setIsKo(isKo);
    setClientKey(key || null);
    setAmount((prev) => ({
      ...prev,
      currency: isKo ? "KRW" : "USD",
    }));
  }, [language]);

  const requestPayment = async (
    orderId: string,
    method: WidgetsPaymentMethodCode,
  ) => {
    try {
      const paymentMethod = isKo
        ? method
        : WidgetsPaymentMethodCode.FOREIGN_EASY_PAY;

      const paymentRequest: any = {
        method: paymentMethod,
        amount: amount,
        orderId,
        orderName: classData?.title || "",
        successUrl: `${process.env.NEXT_PUBLIC_HOST}${lang}/payment/success?courseId=${courseId}&cycleId=${cycleId}&paymentMethod=${method}`, // en url 분기 처리
        failUrl: `${process.env.NEXT_PUBLIC_HOST}${lang}/payment/fail?courseId=${courseId}&cycleId=${cycleId}`,
        customerEmail: customerData?.email,
        customerName: customerData?.name,
        customerMobilePhone: customerData?.phoneNumber,
        ...(paymentMethod === WidgetsPaymentMethodCode.VIRTUAL_ACCOUNT && {
          virtualAccount: {
            validHours: 24,
          },
        }),
      };

      if (!isKo) {
        paymentRequest.foreignEasyPay = {
          provider: "PAYPAL",
          country: navigator.language.split("-")[1] ?? "KR",
        };
      }
      console.log(paymentRequest);
      await payment?.requestPayment(paymentRequest);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (profile) {
      setCustomerData({
        name: profile.name || "",
        phoneNumber: profile.phoneNumber || "",
        email: profile.email || "",
      });
      setCurrentPoints(profile.pointInfo?.currentPoints || 0);
    }
  }, [profile]);

  useEffect(() => {
    // 로딩이 완료되었고, accessToken이 없는 경우 리다이렉트
    if (!isTokenLoading && !accessToken) {
      router.push("/");
    }
  }, [accessToken, isTokenLoading, router]);

  const handleChange =
    (field: keyof typeof customerData) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      setCustomerData((prev) => ({
        ...prev,
        [field]: event.target.value,
      }));
    };

  const onChangeUsedPoints = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const numberValue = Number(newValue);

    // 기본 입력값 업데이트
    setUsedPoints(numberValue);

    // 값이 비어 있는 경우
    if (newValue === "0" || newValue === "") {
      setPointError(t("available_from_1000_points"));
      return;
    }

    // 할인된 금액보다 큰 경우
    if (
      numberValue > calculateDiscountedPrice(price || 0, discountRate, language)
    ) {
      setPointError(t("points_exceed_lecture_price"));
      return;
    }

    // 최소값보다 작은 경우
    if (numberValue < 1000) {
      setPointError(t("available_from_1000_points"));
      return;
    }

    // 사용 가능한 포인트를 초과한 경우
    if (numberValue > currentPoints) {
      setPointError(t("points_exceed_available_points"));
      return;
    }

    // 모든 조건을 통과한 경우
    setPointError(null);
  };

  const onBlurUsedPoints = (event: FocusEvent<HTMLInputElement>) => {
    const newValue = event.target.value.trim();
    const numberValue = Number(newValue);
    // console.log("numberValue", numberValue);
    // ✅ 빈 값("") 또는 0이면 오류 없음
    if (newValue === "" || numberValue === 0) {
      setUsedPoints(null);
      setPointError(null);
      return;
    }

    // ✅ 최소값(1000)보다 작으면 오류 발생
    if (numberValue < 1000) {
      setPointError(t("available_from_1000_points"));
      return;
    }

    // 값이 비어 있는 경우
    if (newValue === "0" || newValue === "") {
      setPointError(t("available_from_1000_points"));
      return;
    }

    // 할인된 금액보다 큰 경우
    if (
      numberValue > calculateDiscountedPrice(price || 0, discountRate, language)
    ) {
      setPointError(t("points_exceed_lecture_price"));
      return;
    }

    // 최소값보다 작은 경우
    if (numberValue < 1000) {
      setPointError(t("available_from_1000_points"));
      return;
    }

    // 사용 가능한 포인트를 초과한 경우
    if (numberValue > currentPoints) {
      setPointError(t("points_exceed_available_points"));
      return;
    }

    // 모든 조건을 통과한 경우
    setPointError(null);
  };

  const handleFinalPriceChange = (newPrice: number) => {
    setFinalPrice(newPrice);
  };

  const handlePurchaseType = (type: WidgetsPaymentMethodCode) => {
    // console.log(type);
    setSelectedPurchaseType((cur) => (cur === type ? null : type));
  };

  const handleCheckedChange = (value: boolean) => {
    setChecked(value);
  };

  const postSuccessPurchase = usePurchaseControllerCompletePurchase({
    request: { accessToken },
    mutation: {
      onSuccess: (data) => {
        setPurchaseData(data as any);
      },
      onError: (error) => {
        console.error(error);
      },
    },
  });

  const postPurchase = usePurchaseControllerInitiatePurchase({
    request: { accessToken },
    mutation: {
      onSuccess: (data: any) => {
        // console.log(data);
        const { purchaseId, allPointPay, paymentInfo } = data;
        paymentInfo && setOrderId(paymentInfo.orderId);
        if (allPointPay) {
          postSuccessPurchase.mutate({ purchaseId: purchaseId });
        } else {
          // console.log("결제 요청 실행");
          requestPayment(
            paymentInfo.orderId,
            selectedPurchaseType as WidgetsPaymentMethodCode,
          ); // ✅ CheckoutPage의 requestPayment 실행
        }
      },
      onError: (error) => {
        console.error(error);
        const { errorCode } = error.response?.data as any;
        if (errorCode === 100023) {
          openToast(TOAST_TYPE.ERROR, t("closed_lecture"));
          router.push("/");
        } else if (errorCode === 100025) {
          openToast(TOAST_TYPE.ERROR, t("already_purchased_lecture"));
          router.push("/");
        } else if (errorCode === 100026) {
          openToast(TOAST_TYPE.ERROR, t("already_completed_payment"));
          router.push("/");
        } else if (errorCode === 100015) {
          openToast(TOAST_TYPE.ERROR, t("schedule_cannot_be_purchased"));
        } else if (errorCode === 100039) {
          openToast(
            TOAST_TYPE.ERROR,
            "가상계좌 결제는 수강 시작일 5일 전까지 가능합니다.",
          );
          setAccountError(true);
        }
      },
    },
  });

  const handlePurchase = () => {
    const data = {
      courseId: Number(courseId),
      cycleId: Number(cycleId),
      amount: price ?? 0,
      discountRate,
      usedPoints: usedPoints || 0,
      paidAmount: finalPrice,
      ordererEmail: customerData.email,
      ordererName: customerData.name,
      ordererPhoneNumber: customerData.phoneNumber,
      ...(selectedPurchaseType === WidgetsPaymentMethodCode.VIRTUAL_ACCOUNT
        ? { isVirtualAccount: true }
        : {}),
    };
    // console.log(data);

    if (accessToken && courseId && cycleId) {
      postPurchase.mutate({ data });
    }
  };

  const isPointPayment = selectedPurchaseType === "POINT"; // ✅ 포인트 결제 여부
  const discountedPrice = calculateDiscountedPrice(
    price || 0,
    discountRate,
    language,
  ); // ✅ 최종 가격 계산

  const isNotEnoughPoints =
    isPointPayment && (usedPoints ?? 0) < discountedPrice; // ✅ 포인트 결제 시, 사용 포인트가 부족하면 비활성화

  const isPointUsageMismatch =
    !isPointPayment && (usedPoints ?? 0) === discountedPrice; // ✅ 포인트가 정확히 최종 가격과 같을 때 다른 결제수단 선택 시 비활성화

  const isDisabled =
    !!pointError || // ✅ 포인트 관련 에러가 있을 때
    !checked || // ✅ 이용약관 동의가 체크되지 않았을 때
    selectedPurchaseType === null || // ✅ 결제 방법을 선택하지 않았을 때
    isNotEnoughPoints || // ✅ 포인트 결제 시, 포인트가 부족하면 비활성화
    isPointUsageMismatch || // ✅ 포인트가 정확히 최종 가격과 같을 때, 다른 결제수단 선택 시 비활성화
    postPurchase.isPending || // ✅ 결제 진행 중일 때
    accountError;

  return (
    <PaymentPageContainer>
      {purchaseData ? (
        <PaymentContentWrapper>
          <SuccessContent PurchaseData={purchaseData} />
        </PaymentContentWrapper>
      ) : (
        <>
          {/* <BaseModal
            modalKey="tossPaymentsModal"
            backgroundColor="#fff"
          >
            <Checkout
              orderId={orderId}
              customerData={customerData}
              classTitle={classData?.title || ""}
              finalPrice={finalPrice}
            />
          </BaseModal> */}
          <PaymentContentWrapper>
            <PaymentTitle>{t("pay")}</PaymentTitle>
            <CardWrapper>
              {classData && <ClassContent classData={classData} />}
              <CustomerInfo
                customerData={customerData}
                onChange={handleChange}
              />
              {language === "ko" && (
                <PointForm
                  currentPoints={currentPoints || 0}
                  value={usedPoints}
                  error={pointError}
                  onChange={onChangeUsedPoints}
                  onBlur={onBlurUsedPoints}
                  min={1000}
                  max={currentPoints}
                />
              )}
              {classData && (
                <PriceContent
                  usedPoints={usedPoints || 0}
                  price={price || 0}
                  discountRate={discountRate || 0}
                  onFinalPriceChange={handleFinalPriceChange}
                  language={language}
                />
              )}
              <PurchaseType
                isKo={isKo}
                selectedPurchaseType={selectedPurchaseType}
                handlePurchaseType={handlePurchaseType}
              />
              <PurchaseForm
                // handlePurchase={handlePurchase}
                isKo={isKo}
                checked={checked}
                onCheckedChange={handleCheckedChange}
              >
                {/* <CheckoutPage /> */}
                <BuyButton
                  className="button"
                  type="submit"
                  styleType={BUTTON_TYPE.FILL}
                  onClick={handlePurchase}
                  disabled={isDisabled || postPurchase.isPending}
                >
                  {t("pay")}
                </BuyButton>
              </PurchaseForm>
            </CardWrapper>
          </PaymentContentWrapper>
        </>
      )}
    </PaymentPageContainer>
  );
};

const PaymentPageContainer = styled.div`
  width: 100%;
  padding: 48px 0 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background: ${({ theme }) => theme.colors.brand.primary.default};
`;

const PaymentContentWrapper = styled.div`
  width: 100%;
  max-width: 720px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.media.tabletSm} {
    padding: 0 16px;
  }
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

const PaymentTitle = styled.div`
  ${({ theme }) => theme.typography.h1.b};
  color: ${({ theme }) => theme.colors.text.high.white};
  padding-bottom: 40px;
`;

const BuyButton = styled(Button)`
  &.btn-${BUTTON_TYPE.FILL.toLowerCase()} {
    ${({ theme }) => theme.typography.body2.b};
    width: 100%;
    border-radius: 8px;
    padding: 12px 16px;
    background-color: ${({ theme }) => theme.colors.sub.blue.default};
    color: ${({ theme }) => theme.colors.text.high.white};

    &:focus {
      background-color: ${({ theme }) => theme.colors.sub.blue.pressed};
    }
    &:hover {
      background-color: ${({ theme }) => theme.colors.sub.blue.hover};
    }

    &:disabled {
      background-color: ${({ theme }) => theme.colors.brand.white.disabled};
      color: ${({ theme }) => theme.colors.text.disabled.white};
    }
  }
`;
