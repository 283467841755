/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * KAC-API
 * <b>K-Artist Class API<b/>
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  AcademyAdminAccountInfoDto,
  AcademyAdminAccountRequestDto,
  AcademyBaseResponseDto,
  AcademyControllerComparePasswordParams,
  AcademyControllerDelegatedLoginUrlParams,
  AcademyControllerGetAcademyInstructorsParams,
  AcademyControllerInviteTokenCheckParams,
  AcademyControllerRequestAcademyAffiliationParams,
  AcademyControllerSearchAcademyListParams,
  AcademyControllerUpdateInstructorParams,
  AcademyControllerUserNameDuplicateCheckParams,
  AcademyPublicResponseDto,
  AcademyResponseDto,
  CreateAcademyAffiliationRequestDto,
  CreateAcademyRequestDto,
  DelegatedLoginResDto,
  PaginatedAcademyInstructorsResponseDto,
  RegisterInstructorRequestDto,
  UpdateAcademyInstructorAccountRequestDto,
  UpdateAcademyRequestDto,
} from "../../../model";
import { axiosInstance } from "../../../config/axios-instance";
import type { ErrorType } from "../../../config/axios-instance";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary 학원 정보 조회(with token)-학원 어드민용
 */
export const academyControllerAcademyProfile = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<AcademyAdminAccountInfoDto>(
    { url: `/academy/profile`, method: "GET", signal },
    options,
  );
};

export const getAcademyControllerAcademyProfileQueryKey = () => {
  return [`/academy/profile`] as const;
};

export const getAcademyControllerAcademyProfileQueryOptions = <
  TData = Awaited<ReturnType<typeof academyControllerAcademyProfile>>,
  TError = ErrorType<AcademyAdminAccountInfoDto>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof academyControllerAcademyProfile>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getAcademyControllerAcademyProfileQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof academyControllerAcademyProfile>>
  > = ({ signal }) => academyControllerAcademyProfile(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof academyControllerAcademyProfile>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AcademyControllerAcademyProfileQueryResult = NonNullable<
  Awaited<ReturnType<typeof academyControllerAcademyProfile>>
>;
export type AcademyControllerAcademyProfileQueryError =
  ErrorType<AcademyAdminAccountInfoDto>;

/**
 * @summary 학원 정보 조회(with token)-학원 어드민용
 */
export const useAcademyControllerAcademyProfile = <
  TData = Awaited<ReturnType<typeof academyControllerAcademyProfile>>,
  TError = ErrorType<AcademyAdminAccountInfoDto>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof academyControllerAcademyProfile>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAcademyControllerAcademyProfileQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 학원 정보 조회-public DEPRECATED
 */
export const academyControllerAcademyProfilePublic = (
  academyId: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<AcademyPublicResponseDto>(
    { url: `/academy/profile/${academyId}`, method: "GET", signal },
    options,
  );
};

export const getAcademyControllerAcademyProfilePublicQueryKey = (
  academyId: number,
) => {
  return [`/academy/profile/${academyId}`] as const;
};

export const getAcademyControllerAcademyProfilePublicQueryOptions = <
  TData = Awaited<ReturnType<typeof academyControllerAcademyProfilePublic>>,
  TError = ErrorType<AcademyPublicResponseDto>,
>(
  academyId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof academyControllerAcademyProfilePublic>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getAcademyControllerAcademyProfilePublicQueryKey(academyId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof academyControllerAcademyProfilePublic>>
  > = ({ signal }) =>
    academyControllerAcademyProfilePublic(academyId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!academyId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof academyControllerAcademyProfilePublic>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AcademyControllerAcademyProfilePublicQueryResult = NonNullable<
  Awaited<ReturnType<typeof academyControllerAcademyProfilePublic>>
>;
export type AcademyControllerAcademyProfilePublicQueryError =
  ErrorType<AcademyPublicResponseDto>;

/**
 * @summary 학원 정보 조회-public DEPRECATED
 */
export const useAcademyControllerAcademyProfilePublic = <
  TData = Awaited<ReturnType<typeof academyControllerAcademyProfilePublic>>,
  TError = ErrorType<AcademyPublicResponseDto>,
>(
  academyId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof academyControllerAcademyProfilePublic>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAcademyControllerAcademyProfilePublicQueryOptions(
    academyId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * 해당 언어에 대한 학워 정보가 없을 시, null로 전달됩니다.
 * @summary 학원 정보 조회-public 다국어 세팅 GLOBAL NEW
 */
export const academyControllerAcademyProfilePublicByLanguage = (
  lang: "ko" | "en",
  academyId: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<AcademyPublicResponseDto>(
    { url: `/academy/profile/${lang}/${academyId}`, method: "GET", signal },
    options,
  );
};

export const getAcademyControllerAcademyProfilePublicByLanguageQueryKey = (
  lang: "ko" | "en",
  academyId: number,
) => {
  return [`/academy/profile/${lang}/${academyId}`] as const;
};

export const getAcademyControllerAcademyProfilePublicByLanguageQueryOptions = <
  TData = Awaited<
    ReturnType<typeof academyControllerAcademyProfilePublicByLanguage>
  >,
  TError = ErrorType<AcademyPublicResponseDto>,
>(
  lang: "ko" | "en",
  academyId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<typeof academyControllerAcademyProfilePublicByLanguage>
      >,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getAcademyControllerAcademyProfilePublicByLanguageQueryKey(lang, academyId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof academyControllerAcademyProfilePublicByLanguage>>
  > = ({ signal }) =>
    academyControllerAcademyProfilePublicByLanguage(
      lang,
      academyId,
      requestOptions,
      signal,
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(lang && academyId),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof academyControllerAcademyProfilePublicByLanguage>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AcademyControllerAcademyProfilePublicByLanguageQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof academyControllerAcademyProfilePublicByLanguage>>
  >;
export type AcademyControllerAcademyProfilePublicByLanguageQueryError =
  ErrorType<AcademyPublicResponseDto>;

/**
 * @summary 학원 정보 조회-public 다국어 세팅 GLOBAL NEW
 */
export const useAcademyControllerAcademyProfilePublicByLanguage = <
  TData = Awaited<
    ReturnType<typeof academyControllerAcademyProfilePublicByLanguage>
  >,
  TError = ErrorType<AcademyPublicResponseDto>,
>(
  lang: "ko" | "en",
  academyId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<typeof academyControllerAcademyProfilePublicByLanguage>
      >,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getAcademyControllerAcademyProfilePublicByLanguageQueryOptions(
      lang,
      academyId,
      options,
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 플랫폼 관리자에 의한 학원 관리자 등록
 */
export const academyControllerCreateAcademyAdmin = (
  academyAdminAccountRequestDto: AcademyAdminAccountRequestDto,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<void>(
    {
      url: `/academy/account/admin`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: academyAdminAccountRequestDto,
    },
    options,
  );
};

export const getAcademyControllerCreateAcademyAdminMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof academyControllerCreateAcademyAdmin>>,
    TError,
    { data: AcademyAdminAccountRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof academyControllerCreateAcademyAdmin>>,
  TError,
  { data: AcademyAdminAccountRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof academyControllerCreateAcademyAdmin>>,
    { data: AcademyAdminAccountRequestDto }
  > = (props) => {
    const { data } = props ?? {};

    return academyControllerCreateAcademyAdmin(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AcademyControllerCreateAcademyAdminMutationResult = NonNullable<
  Awaited<ReturnType<typeof academyControllerCreateAcademyAdmin>>
>;
export type AcademyControllerCreateAcademyAdminMutationBody =
  AcademyAdminAccountRequestDto;
export type AcademyControllerCreateAcademyAdminMutationError =
  ErrorType<unknown>;

/**
 * @summary 플랫폼 관리자에 의한 학원 관리자 등록
 */
export const useAcademyControllerCreateAcademyAdmin = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof academyControllerCreateAcademyAdmin>>,
    TError,
    { data: AcademyAdminAccountRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof academyControllerCreateAcademyAdmin>>,
  TError,
  { data: AcademyAdminAccountRequestDto },
  TContext
> => {
  const mutationOptions =
    getAcademyControllerCreateAcademyAdminMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 플랫폼 관리자에 의한 학원 등록 - 한국어(ko) 가 기본 세팅
 */
export const academyControllerRegisterAcademy = (
  createAcademyRequestDto: CreateAcademyRequestDto,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<AcademyResponseDto>(
    {
      url: `/academy`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: createAcademyRequestDto,
    },
    options,
  );
};

export const getAcademyControllerRegisterAcademyMutationOptions = <
  TError = ErrorType<AcademyResponseDto>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof academyControllerRegisterAcademy>>,
    TError,
    { data: CreateAcademyRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof academyControllerRegisterAcademy>>,
  TError,
  { data: CreateAcademyRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof academyControllerRegisterAcademy>>,
    { data: CreateAcademyRequestDto }
  > = (props) => {
    const { data } = props ?? {};

    return academyControllerRegisterAcademy(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AcademyControllerRegisterAcademyMutationResult = NonNullable<
  Awaited<ReturnType<typeof academyControllerRegisterAcademy>>
>;
export type AcademyControllerRegisterAcademyMutationBody =
  CreateAcademyRequestDto;
export type AcademyControllerRegisterAcademyMutationError =
  ErrorType<AcademyResponseDto>;

/**
 * @summary 플랫폼 관리자에 의한 학원 등록 - 한국어(ko) 가 기본 세팅
 */
export const useAcademyControllerRegisterAcademy = <
  TError = ErrorType<AcademyResponseDto>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof academyControllerRegisterAcademy>>,
    TError,
    { data: CreateAcademyRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof academyControllerRegisterAcademy>>,
  TError,
  { data: CreateAcademyRequestDto },
  TContext
> => {
  const mutationOptions =
    getAcademyControllerRegisterAcademyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * 학원 관리자가 학원 정보를 수정한다. (id 없이 가능, 토큰으로 진행), lang 옵션에 따라 언어별로 학원 정보 반영
 * @summary 학원 관리자에 의한 학원 정보 수정(실질적 등록) - GLOBAL NEW
 */
export const academyControllerRegisterAcademyByAcademyAdmin = (
  lang: "ko" | "en",
  updateAcademyRequestDto: UpdateAcademyRequestDto,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<AcademyResponseDto>(
    {
      url: `/academy/info/${lang}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: updateAcademyRequestDto,
    },
    options,
  );
};

export const getAcademyControllerRegisterAcademyByAcademyAdminMutationOptions =
  <TError = ErrorType<AcademyResponseDto>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof academyControllerRegisterAcademyByAcademyAdmin>
      >,
      TError,
      { lang: "ko" | "en"; data: UpdateAcademyRequestDto },
      TContext
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }): UseMutationOptions<
    Awaited<ReturnType<typeof academyControllerRegisterAcademyByAcademyAdmin>>,
    TError,
    { lang: "ko" | "en"; data: UpdateAcademyRequestDto },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof academyControllerRegisterAcademyByAcademyAdmin>
      >,
      { lang: "ko" | "en"; data: UpdateAcademyRequestDto }
    > = (props) => {
      const { lang, data } = props ?? {};

      return academyControllerRegisterAcademyByAcademyAdmin(
        lang,
        data,
        requestOptions,
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type AcademyControllerRegisterAcademyByAcademyAdminMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof academyControllerRegisterAcademyByAcademyAdmin>>
  >;
export type AcademyControllerRegisterAcademyByAcademyAdminMutationBody =
  UpdateAcademyRequestDto;
export type AcademyControllerRegisterAcademyByAcademyAdminMutationError =
  ErrorType<AcademyResponseDto>;

/**
 * @summary 학원 관리자에 의한 학원 정보 수정(실질적 등록) - GLOBAL NEW
 */
export const useAcademyControllerRegisterAcademyByAcademyAdmin = <
  TError = ErrorType<AcademyResponseDto>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof academyControllerRegisterAcademyByAcademyAdmin>>,
    TError,
    { lang: "ko" | "en"; data: UpdateAcademyRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof academyControllerRegisterAcademyByAcademyAdmin>>,
  TError,
  { lang: "ko" | "en"; data: UpdateAcademyRequestDto },
  TContext
> => {
  const mutationOptions =
    getAcademyControllerRegisterAcademyByAcademyAdminMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 강사의 학원 소속 / 소속 취소 신청 (강사 토큰)
 */
export const academyControllerRequestAcademyAffiliation = (
  createAcademyAffiliationRequestDto: CreateAcademyAffiliationRequestDto,
  params?: AcademyControllerRequestAcademyAffiliationParams,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<string>(
    {
      url: `/academy/instructor/affiliation`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: createAcademyAffiliationRequestDto,
      params,
    },
    options,
  );
};

export const getAcademyControllerRequestAcademyAffiliationMutationOptions = <
  TError = ErrorType<string>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof academyControllerRequestAcademyAffiliation>>,
    TError,
    {
      data: CreateAcademyAffiliationRequestDto;
      params?: AcademyControllerRequestAcademyAffiliationParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof academyControllerRequestAcademyAffiliation>>,
  TError,
  {
    data: CreateAcademyAffiliationRequestDto;
    params?: AcademyControllerRequestAcademyAffiliationParams;
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof academyControllerRequestAcademyAffiliation>>,
    {
      data: CreateAcademyAffiliationRequestDto;
      params?: AcademyControllerRequestAcademyAffiliationParams;
    }
  > = (props) => {
    const { data, params } = props ?? {};

    return academyControllerRequestAcademyAffiliation(
      data,
      params,
      requestOptions,
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type AcademyControllerRequestAcademyAffiliationMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof academyControllerRequestAcademyAffiliation>>
  >;
export type AcademyControllerRequestAcademyAffiliationMutationBody =
  CreateAcademyAffiliationRequestDto;
export type AcademyControllerRequestAcademyAffiliationMutationError =
  ErrorType<string>;

/**
 * @summary 강사의 학원 소속 / 소속 취소 신청 (강사 토큰)
 */
export const useAcademyControllerRequestAcademyAffiliation = <
  TError = ErrorType<string>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof academyControllerRequestAcademyAffiliation>>,
    TError,
    {
      data: CreateAcademyAffiliationRequestDto;
      params?: AcademyControllerRequestAcademyAffiliationParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof academyControllerRequestAcademyAffiliation>>,
  TError,
  {
    data: CreateAcademyAffiliationRequestDto;
    params?: AcademyControllerRequestAcademyAffiliationParams;
  },
  TContext
> => {
  const mutationOptions =
    getAcademyControllerRequestAcademyAffiliationMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 학원 관리자에 의한 학원 강사 계정 생성
 */
export const academyControllerCreateTeacherAccount = (
  registerInstructorRequestDto: RegisterInstructorRequestDto,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<void>(
    {
      url: `/academy/account/instructor`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: registerInstructorRequestDto,
    },
    options,
  );
};

export const getAcademyControllerCreateTeacherAccountMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof academyControllerCreateTeacherAccount>>,
    TError,
    { data: RegisterInstructorRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof academyControllerCreateTeacherAccount>>,
  TError,
  { data: RegisterInstructorRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof academyControllerCreateTeacherAccount>>,
    { data: RegisterInstructorRequestDto }
  > = (props) => {
    const { data } = props ?? {};

    return academyControllerCreateTeacherAccount(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AcademyControllerCreateTeacherAccountMutationResult = NonNullable<
  Awaited<ReturnType<typeof academyControllerCreateTeacherAccount>>
>;
export type AcademyControllerCreateTeacherAccountMutationBody =
  RegisterInstructorRequestDto;
export type AcademyControllerCreateTeacherAccountMutationError =
  ErrorType<unknown>;

/**
 * @summary 학원 관리자에 의한 학원 강사 계정 생성
 */
export const useAcademyControllerCreateTeacherAccount = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof academyControllerCreateTeacherAccount>>,
    TError,
    { data: RegisterInstructorRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof academyControllerCreateTeacherAccount>>,
  TError,
  { data: RegisterInstructorRequestDto },
  TContext
> => {
  const mutationOptions =
    getAcademyControllerCreateTeacherAccountMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 플랫폼 관리자 또는 학원 관리자에 의한 학원 관리자 수정
 */
export const academyControllerUpdateAcademy = (
  academyId: number,
  updateAcademyRequestDto: UpdateAcademyRequestDto,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<void>(
    {
      url: `/academy/${academyId}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: updateAcademyRequestDto,
    },
    options,
  );
};

export const getAcademyControllerUpdateAcademyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof academyControllerUpdateAcademy>>,
    TError,
    { academyId: number; data: UpdateAcademyRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof academyControllerUpdateAcademy>>,
  TError,
  { academyId: number; data: UpdateAcademyRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof academyControllerUpdateAcademy>>,
    { academyId: number; data: UpdateAcademyRequestDto }
  > = (props) => {
    const { academyId, data } = props ?? {};

    return academyControllerUpdateAcademy(academyId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AcademyControllerUpdateAcademyMutationResult = NonNullable<
  Awaited<ReturnType<typeof academyControllerUpdateAcademy>>
>;
export type AcademyControllerUpdateAcademyMutationBody =
  UpdateAcademyRequestDto;
export type AcademyControllerUpdateAcademyMutationError = ErrorType<unknown>;

/**
 * @summary 플랫폼 관리자 또는 학원 관리자에 의한 학원 관리자 수정
 */
export const useAcademyControllerUpdateAcademy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof academyControllerUpdateAcademy>>,
    TError,
    { academyId: number; data: UpdateAcademyRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof academyControllerUpdateAcademy>>,
  TError,
  { academyId: number; data: UpdateAcademyRequestDto },
  TContext
> => {
  const mutationOptions =
    getAcademyControllerUpdateAcademyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 학원 소속 강사 목록 조회
 */
export const academyControllerGetAcademyInstructors = (
  academyId: number,
  params?: AcademyControllerGetAcademyInstructorsParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<PaginatedAcademyInstructorsResponseDto>(
    { url: `/academy/${academyId}/instructor`, method: "GET", params, signal },
    options,
  );
};

export const getAcademyControllerGetAcademyInstructorsQueryKey = (
  academyId: number,
  params?: AcademyControllerGetAcademyInstructorsParams,
) => {
  return [
    `/academy/${academyId}/instructor`,
    ...(params ? [params] : []),
  ] as const;
};

export const getAcademyControllerGetAcademyInstructorsQueryOptions = <
  TData = Awaited<ReturnType<typeof academyControllerGetAcademyInstructors>>,
  TError = ErrorType<PaginatedAcademyInstructorsResponseDto>,
>(
  academyId: number,
  params?: AcademyControllerGetAcademyInstructorsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof academyControllerGetAcademyInstructors>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getAcademyControllerGetAcademyInstructorsQueryKey(academyId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof academyControllerGetAcademyInstructors>>
  > = ({ signal }) =>
    academyControllerGetAcademyInstructors(
      academyId,
      params,
      requestOptions,
      signal,
    );

  return {
    queryKey,
    queryFn,
    enabled: !!academyId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof academyControllerGetAcademyInstructors>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AcademyControllerGetAcademyInstructorsQueryResult = NonNullable<
  Awaited<ReturnType<typeof academyControllerGetAcademyInstructors>>
>;
export type AcademyControllerGetAcademyInstructorsQueryError =
  ErrorType<PaginatedAcademyInstructorsResponseDto>;

/**
 * @summary 학원 소속 강사 목록 조회
 */
export const useAcademyControllerGetAcademyInstructors = <
  TData = Awaited<ReturnType<typeof academyControllerGetAcademyInstructors>>,
  TError = ErrorType<PaginatedAcademyInstructorsResponseDto>,
>(
  academyId: number,
  params?: AcademyControllerGetAcademyInstructorsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof academyControllerGetAcademyInstructors>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAcademyControllerGetAcademyInstructorsQueryOptions(
    academyId,
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 학원 관리자에 의한 강사의 학원 소속 승인/해지
 */
export const academyControllerUpdateInstructor = (
  academyId: number,
  instructorId: number,
  params: AcademyControllerUpdateInstructorParams,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<void>(
    {
      url: `/academy/${academyId}/instructors/${instructorId}`,
      method: "PUT",
      params,
    },
    options,
  );
};

export const getAcademyControllerUpdateInstructorMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof academyControllerUpdateInstructor>>,
    TError,
    {
      academyId: number;
      instructorId: number;
      params: AcademyControllerUpdateInstructorParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof academyControllerUpdateInstructor>>,
  TError,
  {
    academyId: number;
    instructorId: number;
    params: AcademyControllerUpdateInstructorParams;
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof academyControllerUpdateInstructor>>,
    {
      academyId: number;
      instructorId: number;
      params: AcademyControllerUpdateInstructorParams;
    }
  > = (props) => {
    const { academyId, instructorId, params } = props ?? {};

    return academyControllerUpdateInstructor(
      academyId,
      instructorId,
      params,
      requestOptions,
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type AcademyControllerUpdateInstructorMutationResult = NonNullable<
  Awaited<ReturnType<typeof academyControllerUpdateInstructor>>
>;

export type AcademyControllerUpdateInstructorMutationError = ErrorType<unknown>;

/**
 * @summary 학원 관리자에 의한 강사의 학원 소속 승인/해지
 */
export const useAcademyControllerUpdateInstructor = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof academyControllerUpdateInstructor>>,
    TError,
    {
      academyId: number;
      instructorId: number;
      params: AcademyControllerUpdateInstructorParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof academyControllerUpdateInstructor>>,
  TError,
  {
    academyId: number;
    instructorId: number;
    params: AcademyControllerUpdateInstructorParams;
  },
  TContext
> => {
  const mutationOptions =
    getAcademyControllerUpdateInstructorMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 학원 관리자에 의한 학원 초대 토큰 발행
 */
export const academyControllerAdminToken = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<string>(
    { url: `/academy/admin/token`, method: "GET", signal },
    options,
  );
};

export const getAcademyControllerAdminTokenQueryKey = () => {
  return [`/academy/admin/token`] as const;
};

export const getAcademyControllerAdminTokenQueryOptions = <
  TData = Awaited<ReturnType<typeof academyControllerAdminToken>>,
  TError = ErrorType<string>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof academyControllerAdminToken>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getAcademyControllerAdminTokenQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof academyControllerAdminToken>>
  > = ({ signal }) => academyControllerAdminToken(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof academyControllerAdminToken>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AcademyControllerAdminTokenQueryResult = NonNullable<
  Awaited<ReturnType<typeof academyControllerAdminToken>>
>;
export type AcademyControllerAdminTokenQueryError = ErrorType<string>;

/**
 * @summary 학원 관리자에 의한 학원 초대 토큰 발행
 */
export const useAcademyControllerAdminToken = <
  TData = Awaited<ReturnType<typeof academyControllerAdminToken>>,
  TError = ErrorType<string>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof academyControllerAdminToken>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAcademyControllerAdminTokenQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 초대 토큰 기반 academy 정보 조회
 */
export const academyControllerInviteTokenCheck = (
  params: AcademyControllerInviteTokenCheckParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<AcademyBaseResponseDto>(
    { url: `/academy/token`, method: "GET", params, signal },
    options,
  );
};

export const getAcademyControllerInviteTokenCheckQueryKey = (
  params: AcademyControllerInviteTokenCheckParams,
) => {
  return [`/academy/token`, ...(params ? [params] : [])] as const;
};

export const getAcademyControllerInviteTokenCheckQueryOptions = <
  TData = Awaited<ReturnType<typeof academyControllerInviteTokenCheck>>,
  TError = ErrorType<AcademyBaseResponseDto>,
>(
  params: AcademyControllerInviteTokenCheckParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof academyControllerInviteTokenCheck>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getAcademyControllerInviteTokenCheckQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof academyControllerInviteTokenCheck>>
  > = ({ signal }) =>
    academyControllerInviteTokenCheck(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof academyControllerInviteTokenCheck>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AcademyControllerInviteTokenCheckQueryResult = NonNullable<
  Awaited<ReturnType<typeof academyControllerInviteTokenCheck>>
>;
export type AcademyControllerInviteTokenCheckQueryError =
  ErrorType<AcademyBaseResponseDto>;

/**
 * @summary 초대 토큰 기반 academy 정보 조회
 */
export const useAcademyControllerInviteTokenCheck = <
  TData = Awaited<ReturnType<typeof academyControllerInviteTokenCheck>>,
  TError = ErrorType<AcademyBaseResponseDto>,
>(
  params: AcademyControllerInviteTokenCheckParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof academyControllerInviteTokenCheck>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAcademyControllerInviteTokenCheckQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * baseUrl 기입시 {baseUrl}/{token} 형태의 스트링 리턴, 미기입시 토큰만 리턴
 * @summary 학원 관리자에 의한 강사 대리로그인 토큰 발급 및 URL 리턴
 */
export const academyControllerDelegatedLoginUrl = (
  params: AcademyControllerDelegatedLoginUrlParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<DelegatedLoginResDto>(
    { url: `/academy/delegated-login`, method: "GET", params, signal },
    options,
  );
};

export const getAcademyControllerDelegatedLoginUrlQueryKey = (
  params: AcademyControllerDelegatedLoginUrlParams,
) => {
  return [`/academy/delegated-login`, ...(params ? [params] : [])] as const;
};

export const getAcademyControllerDelegatedLoginUrlQueryOptions = <
  TData = Awaited<ReturnType<typeof academyControllerDelegatedLoginUrl>>,
  TError = ErrorType<DelegatedLoginResDto>,
>(
  params: AcademyControllerDelegatedLoginUrlParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof academyControllerDelegatedLoginUrl>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getAcademyControllerDelegatedLoginUrlQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof academyControllerDelegatedLoginUrl>>
  > = ({ signal }) =>
    academyControllerDelegatedLoginUrl(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof academyControllerDelegatedLoginUrl>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AcademyControllerDelegatedLoginUrlQueryResult = NonNullable<
  Awaited<ReturnType<typeof academyControllerDelegatedLoginUrl>>
>;
export type AcademyControllerDelegatedLoginUrlQueryError =
  ErrorType<DelegatedLoginResDto>;

/**
 * @summary 학원 관리자에 의한 강사 대리로그인 토큰 발급 및 URL 리턴
 */
export const useAcademyControllerDelegatedLoginUrl = <
  TData = Awaited<ReturnType<typeof academyControllerDelegatedLoginUrl>>,
  TError = ErrorType<DelegatedLoginResDto>,
>(
  params: AcademyControllerDelegatedLoginUrlParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof academyControllerDelegatedLoginUrl>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAcademyControllerDelegatedLoginUrlQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 학원 강사 가입 시 아이디 중복확인(userName)
 */
export const academyControllerUserNameDuplicateCheck = (
  params: AcademyControllerUserNameDuplicateCheckParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<boolean>(
    {
      url: `/academy/instructor/userName/validate`,
      method: "GET",
      params,
      signal,
    },
    options,
  );
};

export const getAcademyControllerUserNameDuplicateCheckQueryKey = (
  params: AcademyControllerUserNameDuplicateCheckParams,
) => {
  return [
    `/academy/instructor/userName/validate`,
    ...(params ? [params] : []),
  ] as const;
};

export const getAcademyControllerUserNameDuplicateCheckQueryOptions = <
  TData = Awaited<ReturnType<typeof academyControllerUserNameDuplicateCheck>>,
  TError = ErrorType<boolean>,
>(
  params: AcademyControllerUserNameDuplicateCheckParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof academyControllerUserNameDuplicateCheck>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getAcademyControllerUserNameDuplicateCheckQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof academyControllerUserNameDuplicateCheck>>
  > = ({ signal }) =>
    academyControllerUserNameDuplicateCheck(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof academyControllerUserNameDuplicateCheck>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AcademyControllerUserNameDuplicateCheckQueryResult = NonNullable<
  Awaited<ReturnType<typeof academyControllerUserNameDuplicateCheck>>
>;
export type AcademyControllerUserNameDuplicateCheckQueryError =
  ErrorType<boolean>;

/**
 * @summary 학원 강사 가입 시 아이디 중복확인(userName)
 */
export const useAcademyControllerUserNameDuplicateCheck = <
  TData = Awaited<ReturnType<typeof academyControllerUserNameDuplicateCheck>>,
  TError = ErrorType<boolean>,
>(
  params: AcademyControllerUserNameDuplicateCheckParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof academyControllerUserNameDuplicateCheck>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAcademyControllerUserNameDuplicateCheckQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 학원 소속 강사 패스워드 일치여부 확인
 */
export const academyControllerComparePassword = (
  params: AcademyControllerComparePasswordParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<boolean>(
    { url: `/academy/instructor/password`, method: "GET", params, signal },
    options,
  );
};

export const getAcademyControllerComparePasswordQueryKey = (
  params: AcademyControllerComparePasswordParams,
) => {
  return [`/academy/instructor/password`, ...(params ? [params] : [])] as const;
};

export const getAcademyControllerComparePasswordQueryOptions = <
  TData = Awaited<ReturnType<typeof academyControllerComparePassword>>,
  TError = ErrorType<boolean>,
>(
  params: AcademyControllerComparePasswordParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof academyControllerComparePassword>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getAcademyControllerComparePasswordQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof academyControllerComparePassword>>
  > = ({ signal }) =>
    academyControllerComparePassword(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof academyControllerComparePassword>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AcademyControllerComparePasswordQueryResult = NonNullable<
  Awaited<ReturnType<typeof academyControllerComparePassword>>
>;
export type AcademyControllerComparePasswordQueryError = ErrorType<boolean>;

/**
 * @summary 학원 소속 강사 패스워드 일치여부 확인
 */
export const useAcademyControllerComparePassword = <
  TData = Awaited<ReturnType<typeof academyControllerComparePassword>>,
  TError = ErrorType<boolean>,
>(
  params: AcademyControllerComparePasswordParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof academyControllerComparePassword>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAcademyControllerComparePasswordQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 학원 소속 강사 개인정보 수정
 */
export const academyControllerUpdateInstructorPersonalInfo = (
  updateAcademyInstructorAccountRequestDto: UpdateAcademyInstructorAccountRequestDto,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<string>(
    {
      url: `/academy/instructor`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: updateAcademyInstructorAccountRequestDto,
    },
    options,
  );
};

export const getAcademyControllerUpdateInstructorPersonalInfoMutationOptions = <
  TError = ErrorType<string>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof academyControllerUpdateInstructorPersonalInfo>>,
    TError,
    { data: UpdateAcademyInstructorAccountRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof academyControllerUpdateInstructorPersonalInfo>>,
  TError,
  { data: UpdateAcademyInstructorAccountRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof academyControllerUpdateInstructorPersonalInfo>>,
    { data: UpdateAcademyInstructorAccountRequestDto }
  > = (props) => {
    const { data } = props ?? {};

    return academyControllerUpdateInstructorPersonalInfo(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AcademyControllerUpdateInstructorPersonalInfoMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof academyControllerUpdateInstructorPersonalInfo>>
  >;
export type AcademyControllerUpdateInstructorPersonalInfoMutationBody =
  UpdateAcademyInstructorAccountRequestDto;
export type AcademyControllerUpdateInstructorPersonalInfoMutationError =
  ErrorType<string>;

/**
 * @summary 학원 소속 강사 개인정보 수정
 */
export const useAcademyControllerUpdateInstructorPersonalInfo = <
  TError = ErrorType<string>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof academyControllerUpdateInstructorPersonalInfo>>,
    TError,
    { data: UpdateAcademyInstructorAccountRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof academyControllerUpdateInstructorPersonalInfo>>,
  TError,
  { data: UpdateAcademyInstructorAccountRequestDto },
  TContext
> => {
  const mutationOptions =
    getAcademyControllerUpdateInstructorPersonalInfoMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 학원 리스트 조회
 */
export const academyControllerSearchAcademyList = (
  params?: AcademyControllerSearchAcademyListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<AcademyPublicResponseDto[]>(
    { url: `/academy/list`, method: "GET", params, signal },
    options,
  );
};

export const getAcademyControllerSearchAcademyListQueryKey = (
  params?: AcademyControllerSearchAcademyListParams,
) => {
  return [`/academy/list`, ...(params ? [params] : [])] as const;
};

export const getAcademyControllerSearchAcademyListQueryOptions = <
  TData = Awaited<ReturnType<typeof academyControllerSearchAcademyList>>,
  TError = ErrorType<AcademyPublicResponseDto[]>,
>(
  params?: AcademyControllerSearchAcademyListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof academyControllerSearchAcademyList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getAcademyControllerSearchAcademyListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof academyControllerSearchAcademyList>>
  > = ({ signal }) =>
    academyControllerSearchAcademyList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof academyControllerSearchAcademyList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AcademyControllerSearchAcademyListQueryResult = NonNullable<
  Awaited<ReturnType<typeof academyControllerSearchAcademyList>>
>;
export type AcademyControllerSearchAcademyListQueryError = ErrorType<
  AcademyPublicResponseDto[]
>;

/**
 * @summary 학원 리스트 조회
 */
export const useAcademyControllerSearchAcademyList = <
  TData = Awaited<ReturnType<typeof academyControllerSearchAcademyList>>,
  TError = ErrorType<AcademyPublicResponseDto[]>,
>(
  params?: AcademyControllerSearchAcademyListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof academyControllerSearchAcademyList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAcademyControllerSearchAcademyListQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
