import { useRouter } from "next/router";
import styled from "@emotion/styled";

import { ContentCard } from "@/components/common";
import { CourseContent, OrderDetailsContent } from "@/components/domain";
import { PageHeader } from "@/components/ui/headers";
import { useAccessToken } from "@/hooks/auth/use-access-token";
import { useCustomTranslation } from "@/utils/useCustomTranslation";

import {
  getPurchaseControllerPurchasesQueryKey,
  usePurchaseControllerPurchase,
} from "@shared/generated/api/fn/kac/purchase/purchase";

export const OrderDetailsTemplate = () => {
  const { t } = useCustomTranslation();
  const { accessToken } = useAccessToken();
  const router = useRouter();
  const { id } = router.query;
  const { data: orderData } = usePurchaseControllerPurchase(Number(id), {
    request: { accessToken },
    query: {
      enabled: !!accessToken,
      queryKey: [getPurchaseControllerPurchasesQueryKey(), { id }],
    },
  });

  // console.log(orderData);

  return (
    <OrderContainer>
      <PageHeader
        backButtonEnabled={true}
        path={"/my/orders"}
        title={t("purchase_details")}
      />
      <OrdersWrapper>
        <InfoWrapper>
          <InfoTitle>{t("payment_information")}</InfoTitle>
          <InfoDesc>
            {t("order_number")}
            <span className="bold">{orderData?.purchaseResult?.orderId}</span>
          </InfoDesc>
        </InfoWrapper>
        {orderData && (
          <ContentCard
            backgroundColor="#fff"
            border={"1px solid #d2d2d2"}
          >
            <OrderDetailsContent
              amountInfo={orderData?.amountInfo}
              purchaseResult={orderData?.purchaseResult}
              userInfo={orderData?.userInfo}
              receipt={orderData?.receipt}
              isKo={orderData?.courseInfo?.language === "ko"}
            />
          </ContentCard>
        )}
      </OrdersWrapper>
      <OrdersWrapper>
        <InfoWrapper>
          <InfoTitle>{t("product_information")}</InfoTitle>
        </InfoWrapper>
        {orderData && (
          <ContentCard
            backgroundColor="#fff"
            border={"1px solid #d2d2d2"}
          >
            <CourseContent
              courseInfo={orderData.courseInfo || {}}
              cycleInfo={orderData.cycleInfo || {}}
            />
          </ContentCard>
        )}
      </OrdersWrapper>
    </OrderContainer>
  );
};

const OrderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const OrdersWrapper = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const InfoTitle = styled.div`
  ${({ theme }) => theme.typography.h3.b};
`;

const InfoDesc = styled.div`
  ${({ theme }) => theme.typography.body2.m};
  color: ${({ theme }) => theme.colors.text.medium.black};
  .bold {
    font-weight: 700;
    padding-left: 4px;
  }
`;
