import styled from "@emotion/styled";

import { useCustomTranslation } from "@/utils/useCustomTranslation";

import { InfoText } from "./InfoText";
import {
  CourseByStudentResponseDto,
  CourseProcessStatusEnum,
  CourseResponseDto,
  CourseTypeEnum,
} from "@shared/generated/api/model";
import { getInstructorName } from "@/utils/inquiry";
import { useRouter } from "next/router";

export const ClassCard = ({
  course,
  isLiked,
  handleLink,
}: {
  course: CourseResponseDto | CourseByStudentResponseDto;
  isLiked?: boolean | false;
  handleLink?: () => void;
}) => {
  const { t } = useCustomTranslation();
  const router = useRouter();
  const locale = router.locale as "ko" | "en";

  const { title, thumbnailImage, type, cycles, instructor, totalSessions } =
    course;
  const { nearScheduleId } = course as CourseByStudentResponseDto;

  const refCycle = cycles[0];
  let isCycleOngoing = true;
  let isOpenForEnrollment = false;

  if (!refCycle || refCycle.status === CourseProcessStatusEnum.PLANNED) {
    isCycleOngoing = false;
    if (refCycle?.selling === true && !refCycle.enrollmentClosed) {
      isOpenForEnrollment = true;
    }
  }

  const isCycleFinished =
    refCycle?.status === CourseProcessStatusEnum.COMPLETED;

  const isLinkActive = isLiked
    ? isOpenForEnrollment || isCycleOngoing || isOpenForEnrollment
    : (nearScheduleId &&
        !isCycleFinished &&
        (isOpenForEnrollment || isCycleOngoing || isOpenForEnrollment)) ||
      false;

  // console.log("course", course);

  return (
    <CardWrapper
      onClick={handleLink}
      isLinkActive={isLinkActive}
    >
      <ThumbnailWrapper isLinkActive={isLinkActive}>
        <img src={thumbnailImage} />
      </ThumbnailWrapper>
      <ContentWrapper>
        <ClassTypeText type={type}>
          {type === CourseTypeEnum.SINGLE
            ? t("one_day")
            : t("multiple_sessions")}
        </ClassTypeText>
        <InfoText
          isLiked={isLiked}
          isCycleOngoing={isCycleOngoing}
          isCycleFinished={isCycleFinished}
          isOpenForEnrollment={isOpenForEnrollment}
          type={type}
          refCycle={refCycle}
          totalSessions={totalSessions ?? 0}
        />
        <ClassTitleText>
          {title.length > 33 ? `${title.slice(0, 33)}...` : title}
        </ClassTitleText>
        <TeacherText>{getInstructorName(course, locale)}</TeacherText>
      </ContentWrapper>
    </CardWrapper>
  );
};

const CardWrapper = styled.div<{ isLinkActive?: boolean | true }>`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  max-height: 300px;
  cursor: ${({ isLinkActive }) => (isLinkActive ? "pointer" : "default")};
  box-shadow: 0px 0px 9.645px 0px rgba(0, 0, 0, 0.2);
`;

const ThumbnailWrapper = styled.div<{ isLinkActive?: boolean | true }>`
  height: 153px;
  opacity: ${({ isLinkActive }) => (isLinkActive ? 1 : 0.5)};
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px 8px 0 0;
  }
`;

const ContentWrapper = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  ${({ theme }) => theme.media.mobile} {
    /* padding: 8px; */
  }
`;

const ClassTypeText = styled.div<{ type: string }>`
  ${({ theme }) => theme.typography.body1.b};
  @media (max-width: 1165px) {
    font-size: 14px;
  }
  color: ${({ type, theme }) =>
    type === CourseTypeEnum.SINGLE
      ? theme.colors.sub.orange.default
      : theme.colors.sub.green.default};
`;

const ClassTitleText = styled.div`
  ${({ theme }) => theme.typography.body1.b};
  min-height: 44px;
  @media (max-width: 1165px) {
    font-size: 14px;
  }
`;

const TeacherText = styled.div`
  ${({ theme }) => theme.typography.body2.m};
  color: ${({ theme }) => theme.colors.text.medium.black};
`;
