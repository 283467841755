/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * KAC-API
 * <b>K-Artist Class API<b/>
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  CreateReviewRequestDto,
  PaginatedReviewsResponseDto,
  ReviewControllerFindReviewsParams,
  UpdateReviewRequestDto,
} from "../../../model";
import { axiosInstance } from "../../../config/axios-instance";
import type { ErrorType } from "../../../config/axios-instance";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary 리뷰 작성
 */
export const reviewControllerCreateReview = (
  createReviewRequestDto: CreateReviewRequestDto,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<string>(
    {
      url: `/review`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: createReviewRequestDto,
    },
    options,
  );
};

export const getReviewControllerCreateReviewMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof reviewControllerCreateReview>>,
    TError,
    { data: CreateReviewRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof reviewControllerCreateReview>>,
  TError,
  { data: CreateReviewRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof reviewControllerCreateReview>>,
    { data: CreateReviewRequestDto }
  > = (props) => {
    const { data } = props ?? {};

    return reviewControllerCreateReview(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ReviewControllerCreateReviewMutationResult = NonNullable<
  Awaited<ReturnType<typeof reviewControllerCreateReview>>
>;
export type ReviewControllerCreateReviewMutationBody = CreateReviewRequestDto;
export type ReviewControllerCreateReviewMutationError = ErrorType<unknown>;

/**
 * @summary 리뷰 작성
 */
export const useReviewControllerCreateReview = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof reviewControllerCreateReview>>,
    TError,
    { data: CreateReviewRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof reviewControllerCreateReview>>,
  TError,
  { data: CreateReviewRequestDto },
  TContext
> => {
  const mutationOptions =
    getReviewControllerCreateReviewMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * 토큰 없이 조회 가능, 토큰과 함께 보내면 해당 유저용 필드가 응답에 추가됨
 * @summary 리뷰 목록 조회
 */
export const reviewControllerFindReviews = (
  params?: ReviewControllerFindReviewsParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<PaginatedReviewsResponseDto>(
    { url: `/review`, method: "GET", params, signal },
    options,
  );
};

export const getReviewControllerFindReviewsQueryKey = (
  params?: ReviewControllerFindReviewsParams,
) => {
  return [`/review`, ...(params ? [params] : [])] as const;
};

export const getReviewControllerFindReviewsQueryOptions = <
  TData = Awaited<ReturnType<typeof reviewControllerFindReviews>>,
  TError = ErrorType<PaginatedReviewsResponseDto>,
>(
  params?: ReviewControllerFindReviewsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof reviewControllerFindReviews>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getReviewControllerFindReviewsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof reviewControllerFindReviews>>
  > = ({ signal }) =>
    reviewControllerFindReviews(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof reviewControllerFindReviews>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ReviewControllerFindReviewsQueryResult = NonNullable<
  Awaited<ReturnType<typeof reviewControllerFindReviews>>
>;
export type ReviewControllerFindReviewsQueryError =
  ErrorType<PaginatedReviewsResponseDto>;

/**
 * @summary 리뷰 목록 조회
 */
export const useReviewControllerFindReviews = <
  TData = Awaited<ReturnType<typeof reviewControllerFindReviews>>,
  TError = ErrorType<PaginatedReviewsResponseDto>,
>(
  params?: ReviewControllerFindReviewsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof reviewControllerFindReviews>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getReviewControllerFindReviewsQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 리뷰 수정
 */
export const reviewControllerUpdateReview = (
  reviewId: number,
  updateReviewRequestDto: UpdateReviewRequestDto,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<string>(
    {
      url: `/review/${reviewId}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: updateReviewRequestDto,
    },
    options,
  );
};

export const getReviewControllerUpdateReviewMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof reviewControllerUpdateReview>>,
    TError,
    { reviewId: number; data: UpdateReviewRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof reviewControllerUpdateReview>>,
  TError,
  { reviewId: number; data: UpdateReviewRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof reviewControllerUpdateReview>>,
    { reviewId: number; data: UpdateReviewRequestDto }
  > = (props) => {
    const { reviewId, data } = props ?? {};

    return reviewControllerUpdateReview(reviewId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ReviewControllerUpdateReviewMutationResult = NonNullable<
  Awaited<ReturnType<typeof reviewControllerUpdateReview>>
>;
export type ReviewControllerUpdateReviewMutationBody = UpdateReviewRequestDto;
export type ReviewControllerUpdateReviewMutationError = ErrorType<unknown>;

/**
 * @summary 리뷰 수정
 */
export const useReviewControllerUpdateReview = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof reviewControllerUpdateReview>>,
    TError,
    { reviewId: number; data: UpdateReviewRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof reviewControllerUpdateReview>>,
  TError,
  { reviewId: number; data: UpdateReviewRequestDto },
  TContext
> => {
  const mutationOptions =
    getReviewControllerUpdateReviewMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 리뷰 삭제
 */
export const reviewControllerDeleteReview = (
  reviewId: number,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<string>(
    { url: `/review/${reviewId}`, method: "DELETE" },
    options,
  );
};

export const getReviewControllerDeleteReviewMutationOptions = <
  TError = ErrorType<string>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof reviewControllerDeleteReview>>,
    TError,
    { reviewId: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof reviewControllerDeleteReview>>,
  TError,
  { reviewId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof reviewControllerDeleteReview>>,
    { reviewId: number }
  > = (props) => {
    const { reviewId } = props ?? {};

    return reviewControllerDeleteReview(reviewId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ReviewControllerDeleteReviewMutationResult = NonNullable<
  Awaited<ReturnType<typeof reviewControllerDeleteReview>>
>;

export type ReviewControllerDeleteReviewMutationError = ErrorType<string>;

/**
 * @summary 리뷰 삭제
 */
export const useReviewControllerDeleteReview = <
  TError = ErrorType<string>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof reviewControllerDeleteReview>>,
    TError,
    { reviewId: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof reviewControllerDeleteReview>>,
  TError,
  { reviewId: number },
  TContext
> => {
  const mutationOptions =
    getReviewControllerDeleteReviewMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 리뷰 좋아요
 */
export const reviewControllerLikeReview = (
  reviewId: number,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<void>(
    { url: `/review/${reviewId}/like`, method: "POST" },
    options,
  );
};

export const getReviewControllerLikeReviewMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof reviewControllerLikeReview>>,
    TError,
    { reviewId: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof reviewControllerLikeReview>>,
  TError,
  { reviewId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof reviewControllerLikeReview>>,
    { reviewId: number }
  > = (props) => {
    const { reviewId } = props ?? {};

    return reviewControllerLikeReview(reviewId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ReviewControllerLikeReviewMutationResult = NonNullable<
  Awaited<ReturnType<typeof reviewControllerLikeReview>>
>;

export type ReviewControllerLikeReviewMutationError = ErrorType<unknown>;

/**
 * @summary 리뷰 좋아요
 */
export const useReviewControllerLikeReview = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof reviewControllerLikeReview>>,
    TError,
    { reviewId: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof reviewControllerLikeReview>>,
  TError,
  { reviewId: number },
  TContext
> => {
  const mutationOptions = getReviewControllerLikeReviewMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 리뷰 좋아요 취소
 */
export const reviewControllerUnlikeReview = (
  reviewId: number,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<string>(
    { url: `/review/${reviewId}/like`, method: "DELETE" },
    options,
  );
};

export const getReviewControllerUnlikeReviewMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof reviewControllerUnlikeReview>>,
    TError,
    { reviewId: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof reviewControllerUnlikeReview>>,
  TError,
  { reviewId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof reviewControllerUnlikeReview>>,
    { reviewId: number }
  > = (props) => {
    const { reviewId } = props ?? {};

    return reviewControllerUnlikeReview(reviewId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ReviewControllerUnlikeReviewMutationResult = NonNullable<
  Awaited<ReturnType<typeof reviewControllerUnlikeReview>>
>;

export type ReviewControllerUnlikeReviewMutationError = ErrorType<unknown>;

/**
 * @summary 리뷰 좋아요 취소
 */
export const useReviewControllerUnlikeReview = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof reviewControllerUnlikeReview>>,
    TError,
    { reviewId: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof reviewControllerUnlikeReview>>,
  TError,
  { reviewId: number },
  TContext
> => {
  const mutationOptions =
    getReviewControllerUnlikeReviewMutationOptions(options);

  return useMutation(mutationOptions);
};
