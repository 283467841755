import styled from "@emotion/styled";

import {
  formatClassPeriodWithoutYear,
  formatDate,
} from "@shared/lib/utils/date/formatSchedule";
import { useCustomTranslation } from "@/utils/useCustomTranslation";

import { CourseTypeEnum } from "@shared/generated/api/model/courseTypeEnum";

interface InfoTextProps {
  isLiked?: boolean | false;
  isCycleOngoing: boolean;
  isCycleFinished: boolean;
  isOpenForEnrollment: boolean;
  type: CourseTypeEnum;
  refCycle: {
    startsAt: string;
    endsAt: string;
  };
  totalSessions: number;
}

export const InfoText = ({
  isLiked,
  isCycleOngoing,
  isCycleFinished,
  isOpenForEnrollment,
  type,
  refCycle,
  totalSessions,
}: InfoTextProps) => {
  const { t } = useCustomTranslation();

  const renderStatus = () => {
    if (isCycleFinished) {
      return (
        <DurationText color="#F15746">
          {isLiked ? t("preparing") : t("closed")}
        </DurationText>
      );
    }

    if (!isCycleOngoing) {
      if (isLiked) {
        return (
          <DurationText color="#F15746">
            {isOpenForEnrollment ? t("open") : t("preparing")}
          </DurationText>
        );
      } else {
        return (
          <DurationText color="#F15746">
            {isOpenForEnrollment ? "" : t("end")}
          </DurationText>
        );
      }
    }

    return null;
  };

  return (
    <InfoTextContainer>
      {refCycle && !isLiked ? (
        <>
          <DurationText>{t("duration")}</DurationText>
          <DurationText color="black">
            {type === CourseTypeEnum.MULTI
              ? formatClassPeriodWithoutYear(
                  new Date(refCycle.startsAt),
                  new Date(refCycle.endsAt),
                  totalSessions,
                )
              : formatDate(new Date(refCycle.startsAt), { withTime: true })}
          </DurationText>
          {renderStatus()}
        </>
      ) : (
        <DurationText color="black">{renderStatus()}</DurationText>
      )}
    </InfoTextContainer>
  );
};

const InfoTextContainer = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 2px;
`;

const DurationText = styled.div<{ color?: string }>`
  ${({ theme }) => theme.typography.body2.m};
  color: ${({ theme, color }) =>
    color ? color : theme.colors.icon.inactive.black};
  @media (max-width: 1165px) {
    font-size: 12px;
  }
`;
