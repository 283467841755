import { ChangeEvent } from "react";
import { useMedia } from "react-use";
import { useRouter } from "next/router";
import moment from "moment/moment";
import styled from "@emotion/styled";
import {
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import {
  getInquiryTypePrefix,
  getInquiryTypeText,
  getInstructorName,
} from "@/utils/inquiry";
import { useCustomTranslation } from "@/utils/useCustomTranslation";

import { InquiryResponseDto } from "@shared/generated/api/model";

interface InquiryTableProps {
  total: number;
  curPage: number;
  handlePageChange: (_event: ChangeEvent<unknown>, newPage: number) => void;
  rows: InquiryResponseDto[];
  headers: string[];
  isQna: boolean;
}

export const InquiryTable = ({
  total,
  curPage,
  handlePageChange,
  rows,
  headers,
  isQna,
}: InquiryTableProps) => {
  const { t } = useCustomTranslation();
  const router = useRouter();
  const locale = router.locale as "ko" | "en";
  const isMobile = useMedia(`(max-width: 590px)`, false);

  // console.log(rows);
  return (
    <>
      <StyledContainer>
        {isMobile ? (
          <>
            {rows.length !== 0 ? (
              rows.map((row) => (
                <ResponsiveTable
                  key={row.id}
                  onClick={() => router.push(`/my/inquiry/${row.id}`)}
                >
                  <StyledTableRow className="font-md">
                    {isQna ? (
                      row.subject
                    ) : (
                      <>
                        <InquiryTypeText>
                          {`[${t(getInquiryTypePrefix(row.type))}]`}
                        </InquiryTypeText>
                        {`${t(getInquiryTypeText(row.type))}`}
                      </>
                    )}
                  </StyledTableRow>
                  <StyledTableRow className="font-s">
                    <InquiryTypeText className="font-s">
                      {t("teacher_name")}
                    </InquiryTypeText>
                    {getInstructorName(row.course, locale)}
                  </StyledTableRow>
                  <StyledTableRow className="font-s">
                    <InquiryTypeText className="font-s">
                      {t("writer")}
                    </InquiryTypeText>
                    <span className="pr">{row?.user?.name}</span>
                    <InquiryTypeText className="font-s">
                      {t("registration_date")}
                    </InquiryTypeText>
                    <span>{moment(row.createdAt).format("YYYY-MM-DD")}</span>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StatusText
                      status={row.status}
                      className="font-s"
                    >
                      {row.status === "PENDING"
                        ? t("awaiting_answer")
                        : t("answered")}
                    </StatusText>
                  </StyledTableRow>
                </ResponsiveTable>
              ))
            ) : (
              <ResponsiveTable>
                <NoInquiry>{t("no_registered_posts")}</NoInquiry>
              </ResponsiveTable>
            )}
          </>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                {headers.map((header, index) => (
                  <StyledTableCell key={index}>{header}</StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length !== 0 ? (
                rows.map((row) => (
                  <StyledTableRow
                    key={row.id}
                    onClick={() => router.push(`/my/inquiry/${row.id}`)}
                  >
                    <BodyTableCell style={{ width: "30px" }}>
                      {row.id}
                    </BodyTableCell>
                    <BodyTableCell>
                      {isQna ? (
                        row.subject
                      ) : (
                        <>
                          <InquiryTypeText>
                            {`[${t(getInquiryTypePrefix(row.type))}]`}
                          </InquiryTypeText>
                          {`${t(getInquiryTypeText(row.type))}`}
                        </>
                      )}
                    </BodyTableCell>
                    <BodyTableCell>
                      {getInstructorName(row.course, locale)}
                    </BodyTableCell>
                    <BodyTableCell>{row?.user?.name}</BodyTableCell>
                    <BodyTableCell>
                      {moment(row.createdAt).format("YYYY-MM-DD")}
                    </BodyTableCell>
                    <StyledStatusCell status={row.status}>
                      {row.status === "PENDING"
                        ? t("awaiting_answer")
                        : t("answered")}
                    </StyledStatusCell>
                  </StyledTableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={12}
                    align="center"
                  >
                    <NoInquiry>{t("no_registered_posts")}</NoInquiry>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
        <PaginationWrapper>
          <StyledPagination
            size="small"
            count={total}
            page={curPage}
            onChange={handlePageChange}
            color="primary"
          />
        </PaginationWrapper>
      </StyledContainer>
    </>
  );
};

const StyledContainer = styled(TableContainer)`
  border-radius: 8px;
  border: 1px solid #ddd;
  width: 100%;
`;

const ResponsiveTable = styled.div`
  width: 100%;
  border-bottom: 1px solid #ddd;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colors.brand.white.hover};
  }

  .font-s {
    font-size: 12px;
  }
  .font-md {
    font-size: 16px;
  }
  .pr {
    padding-right: 4px;
  }
`;

const StyledTableCell = styled(TableCell)`
  ${({ theme }) => theme.typography.body2.m}
  color: ${({ theme }) => theme.colors.brand.secondary.default};
  padding: 16px 26px;
  white-space: nowrap;
  @media (max-width: 950px) {
    padding: 12px;
  }
`;

const StyledTableRow = styled(TableRow)`
  ${({ theme }) => theme.typography.body2.m}
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.colors.brand.white.hover};
  }
`;

const BodyTableCell = styled(TableCell)`
  ${({ theme }) => theme.typography.body2.m}
  color: ${({ theme }) => theme.colors.brand.primary.default};
  padding: 16px 26px;
  white-space: nowrap;
  @media (max-width: 950px) {
    padding: 12px;
  }
`;

const InquiryTypeText = styled.span`
  ${({ theme }) => theme.typography.body2.m}
  color: ${({ theme }) => theme.colors.text.medium.black};
  padding-right: 4px;
`;

const StyledStatusCell = styled(TableCell)<{ status: string }>`
  white-space: nowrap;
  ${({ theme }) => theme.typography.body2.m}
  color: ${({ theme, status }) =>
    status === "PENDING"
      ? theme.colors.sub.blue.default
      : theme.colors.sub.green.default};
  padding: 16px 26px;
  @media (max-width: 950px) {
    padding: 12px;
  }
`;

const StatusText = styled.div<{ status: string }>`
  white-space: nowrap;
  ${({ theme }) => theme.typography.body2.m}
  color: ${({ theme, status }) =>
    status === "PENDING"
      ? theme.colors.sub.blue.default
      : theme.colors.sub.green.default};
`;

const PaginationWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
`;

const StyledPagination = styled(Pagination)`
  display: flex;
  justify-content: center;
`;

const NoInquiry = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  ${({ theme }) => theme.typography.body2.m}
  color: ${({ theme }) => theme.colors.text.medium.black}
`;
