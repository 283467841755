import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import styled from "@emotion/styled";

import { Button } from "@shared/lib/components/common/atom/button";
import { useMediaQueryMaxWidth } from "@shared/lib/hooks/use-media-query-max-width";
import { BUTTON_TYPE } from "@shared/lib/styles/theme";
import {
  formatClassPeriod,
  formatClassStartTime,
} from "@shared/lib/utils/date/formatSchedule";
import {
  convertPriceString,
  discountedTotalPrice,
  priceToLocaleString,
} from "@shared/lib/utils/price";

import { useAccessToken } from "@/hooks/auth/use-access-token";
import {
  InquiryType,
  useInquiryTypeStore,
  useModalStore,
  useScheduleStore,
} from "@/stores";
import { useCustomTranslation } from "@/utils/useCustomTranslation";

import {
  CourseInDetailResponseDto,
  CourseTypeEnum,
  CycleResponseDto,
  InstructorInfoDto,
} from "@shared/generated/api/model";
import {
  ALERT_MODAL_TYPE,
  AlertModal,
  AlertModalType,
} from "@shared/lib/components/common/molecules/modal/alert-modal";

export const PriceCard = ({
  classData,
}: {
  classData: CourseInDetailResponseDto;
}) => {
  const { t } = useCustomTranslation();
  const { accessToken } = useAccessToken();
  const { isTabletSm } = useMediaQueryMaxWidth();
  const { setInquiryType } = useInquiryTypeStore();
  const { openModal } = useModalStore();
  const [selectedSchedule, setSelectedSchedule] =
    useState<CycleResponseDto | null>(null);
  const selectedScheduleId = useScheduleStore(
    (state) => state.selectedScheduleId,
  );

  const router = useRouter();
  const locale = router.locale || "ko";
  const isKo = locale === "ko";

  const {
    id: courseId,
    title,
    cycles,
    type,
    price,
    discountRate,
    totalSessions,
    language,
  } = classData as CourseInDetailResponseDto;
  const { artistName } = classData.instructor as InstructorInfoDto;

  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
  const [alertModalTexts, setAlertModalTexts] = useState<{
    type: AlertModalType;
    title?: string;
    subTitle?: string;
  }>({
    type: ALERT_MODAL_TYPE.ERROR,
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    const selected =
      cycles.find((cycle) => cycle.id === selectedScheduleId) || null;
    setSelectedSchedule(selected);
  }, [selectedScheduleId]);

  const handleScheduleInquiry = () => {
    if (accessToken) {
      setInquiryType(InquiryType.Class);
      openModal("inquiryModal");
    } else {
      setAlertModalTexts({
        type: ALERT_MODAL_TYPE.ERROR,
        title: t("login_required"),
        subTitle: "",
      });
      setIsAlertModalOpen(true);
    }
  };

  const handleSubmitCourse = () => {
    // console.log("courseId:", courseId, "cycleId:", selectedScheduleId);
    if (accessToken) {
      router.push({
        pathname: "/payment",
        query: {
          courseId: courseId,
          cycleId: selectedScheduleId,
        },
      });
    } else {
      setAlertModalTexts({
        type: ALERT_MODAL_TYPE.ERROR,
        title: t("login_required"),
        subTitle: "",
      });
      setIsAlertModalOpen(true);
    }
  };

  return (
    <>
      {isAlertModalOpen && (
        <AlertModal
          {...alertModalTexts}
          isOpen={isAlertModalOpen}
          onClose={() => setIsAlertModalOpen(false)}
          onConfirm={() => router.push(`/login?callbackUrl=${router.asPath}`)}
          confirmText={t("signin")}
        />
      )}
      {isTabletSm ? (
        <FixedContainer>
          <BtnWrapper>
            <InquiryButton
              type={"button"}
              styleType={BUTTON_TYPE.FILL}
              onClick={handleScheduleInquiry}
            >
              {t("contact_the_instructor")}
            </InquiryButton>
            <BuyButton
              type={"submit"}
              styleType={BUTTON_TYPE.FILL}
              onClick={handleSubmitCourse}
              disabled={!selectedScheduleId}
            >
              {t("apply_for_class")}
            </BuyButton>
          </BtnWrapper>
        </FixedContainer>
      ) : (
        <StickyContainer isKo={isKo}>
          <StickyCard>
            <StickyTitle>
              {title}
              <div className="teacher">{artistName}</div>
            </StickyTitle>
            {discountRate === 0 ? (
              <PriceSection>
                <DetailPrice>
                  <PriceInfoWrapper position="left">
                    <PriceInfo>
                      {type === CourseTypeEnum.MULTI && (
                        <span className="time">{t("per_session")}</span>
                      )}
                      {language !== "ko" && (
                        <span className="won">{t("dollar")}</span>
                      )}
                      <span>
                        {convertPriceString(
                          price,
                          discountRate,
                          type,
                          totalSessions,
                          language,
                        )}
                      </span>
                      {language === "ko" && (
                        <span className="won">{t("won")}</span>
                      )}
                    </PriceInfo>
                    {type === CourseTypeEnum.MULTI && (
                      <SelectSection>
                        {t("total")} {priceToLocaleString(price || 0)} /{" "}
                        {totalSessions}
                        {t("times")}
                      </SelectSection>
                    )}
                  </PriceInfoWrapper>
                </DetailPrice>
              </PriceSection>
            ) : (
              <PriceSection>
                <DetailPrice>
                  <CancelledText className="cancelled">
                    {language !== "ko" && t("dollar")}
                    {priceToLocaleString(price || 0)}
                    {language === "ko" && t("won")}
                  </CancelledText>
                  <PriceInfoWrapper position="right">
                    <PriceInfo>
                      <span className="discount">
                        {Math.round((classData?.discountRate ?? 0) * 10000) /
                          100}
                        %
                      </span>
                      {type === CourseTypeEnum.MULTI && (
                        <span className="time">{t("per_session")}</span>
                      )}
                      {language !== "ko" && (
                        <span className="won">{t("dollar")}</span>
                      )}
                      <span>
                        {convertPriceString(
                          price,
                          discountRate,
                          type,
                          totalSessions,
                          language,
                        )}
                      </span>
                      {language === "ko" && (
                        <span className="won">{t("won")}</span>
                      )}
                    </PriceInfo>
                    {type === CourseTypeEnum.MULTI && (
                      <SelectSection>
                        {t("total")}{" "}
                        {language === "ko"
                          ? `${discountedTotalPrice(price || 0, discountRate, language)}${t("won")} / ${totalSessions}`
                          : `${t("dollar")}${discountedTotalPrice(price || 0, discountRate, language)} / ${totalSessions}`}
                        {t("times")}
                      </SelectSection>
                    )}
                  </PriceInfoWrapper>
                </DetailPrice>
              </PriceSection>
            )}
            <SplitLine />
            <InfoBox>
              <IconTitle>
                <img src={"/images/icons/line-calendar.svg"} />
              </IconTitle>
              <IconTitle>{t("class_type")}</IconTitle>
              <InfoTitle>
                {type === CourseTypeEnum.SINGLE
                  ? t("one_day")
                  : t("multiple_sessions")}
              </InfoTitle>
            </InfoBox>
            <SplitLine />
            {selectedSchedule && (
              <SelectSection>
                {t("selected_schedule")}
                <span className="date">
                  {type === CourseTypeEnum.SINGLE
                    ? formatClassStartTime(new Date(selectedSchedule.startsAt))
                    : formatClassPeriod(
                        new Date(selectedSchedule.startsAt),
                        new Date(selectedSchedule.endsAt),
                        Number(totalSessions),
                        false,
                      )}
                </span>
              </SelectSection>
            )}
            <BtnWrapper>
              <InquiryButton
                type={"button"}
                styleType={BUTTON_TYPE.FILL}
                onClick={handleScheduleInquiry}
              >
                {t("contact_the_instructor")}
              </InquiryButton>
              <BuyButton
                type={"submit"}
                styleType={BUTTON_TYPE.FILL}
                onClick={handleSubmitCourse}
                disabled={!selectedScheduleId}
              >
                {t("apply_for_class")}
              </BuyButton>
            </BtnWrapper>
          </StickyCard>
        </StickyContainer>
      )}
    </>
  );
};

const StickyContainer = styled.div<{ isKo?: boolean | true }>`
  width: 100%;
  max-width: ${({ isKo }) => (isKo ? "380px" : "450px")};
`;

const StickyCard = styled.div`
  background: ${({ theme }) => theme.colors.brand.secondary.default};
  border-radius: 8px;
  padding: 20px;
  position: sticky;
  top: 120px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${({ theme }) => theme.media.tablet} {
    top: 80px;
  }
`;

const StickyTitle = styled.div`
  ${({ theme }) => theme.typography.h2.b};
  color: ${({ theme }) => theme.colors.brand.white.default};
  .teacher {
    ${({ theme }) => theme.typography.body1.m};
    padding-top: 8px;
    color: ${({ theme }) => theme.colors.text.high.white};
  }
`;

const PriceSection = styled.section`
  ${({ theme }) => theme.typography.h1.b};
  color: ${({ theme }) => theme.colors.brand.white.default};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const DetailPrice = styled.div`
  display: flex;
  gap: 4px;
  justify-content: space-between;
  align-items: flex-start;
`;

const CancelledText = styled.s`
  ${({ theme }) => theme.typography.body1.m};
  color: ${({ theme }) => theme.colors.text.medium.white};
  display: flex;
  height: 34px;
  align-items: center;
`;

const PriceInfoWrapper = styled.div<{ position: string }>`
  display: flex;
  flex-direction: column;
  align-items: ${({ position }) =>
    position === "right" ? "flex-end" : "flex-start"};
`;

const PriceInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  .discount {
    ${({ theme }) => theme.typography.h1.b};
    color: ${({ theme }) => theme.colors.state.error.default};
    padding-right: 4px;
  }
  .time {
    ${({ theme }) => theme.typography.body1.m};
    color: ${({ theme }) => theme.colors.brand.white.default};
  }
  .won {
    ${({ theme }) => theme.typography.body1.m};
    color: ${({ theme }) => theme.colors.brand.white.default};
  }
`;

const SplitLine = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray.gray30};
`;

const InfoBox = styled.div`
  background: ${({ theme }) => theme.colors.brand.secondary.default};
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  letter-spacing: -0.4px;
`;

const IconTitle = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.typography.body2.m};
  color: ${({ theme }) => theme.colors.icon.inactive.white};
`;

const InfoTitle = styled.div`
  ${({ theme }) => theme.typography.body2.b};
  color: ${({ theme }) => theme.colors.brand.white.default};
  padding-left: 4px;
`;

const SelectSection = styled.div`
  ${({ theme }) => theme.typography.body1.m};
  color: ${({ theme }) => theme.colors.text.medium.white};
  .date {
    color: ${({ theme }) => theme.colors.text.high.white};
    padding-left: 8px;
  }
`;

const BtnWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
`;

const InquiryButton = styled(Button)`
  &.btn-${BUTTON_TYPE.FILL.toLowerCase()} {
    ${({ theme }) => theme.typography.body2.b};
    color: ${({ theme }) => theme.colors.text.high.white};
    width: 100%;
    max-width: 130px;
    border-radius: 8px;
    padding: 12px 16px;
    background-color: ${({ theme }) => theme.colors.gray.gray40};
    &:focus,
    &:hover {
      background-color: ${({ theme }) => theme.colors.gray.gray40};
    }
  }
`;

const BuyButton = styled(Button)`
  &.btn-${BUTTON_TYPE.FILL.toLowerCase()} {
    ${({ theme }) => theme.typography.body2.b};
    width: 100%;
    border-radius: 8px;
    padding: 12px 16px;
    background-color: ${({ theme }) => theme.colors.sub.blue.default};
    color: ${({ theme }) => theme.colors.text.high.white};

    &:focus {
      background-color: ${({ theme }) => theme.colors.sub.blue.pressed};
    }
    &:hover {
      background-color: ${({ theme }) => theme.colors.sub.blue.hover};
    }

    &:disabled {
      background-color: ${({ theme }) => theme.colors.brand.white.disabled};
      color: ${({ theme }) => theme.colors.text.disabled.white};
    }
  }
`;

const FixedContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99;
  padding: 16px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.brand.primary.default};
`;
