import { useEffect, useState } from "react";
import styled from "@emotion/styled";

import {
  calculateFinalPrice,
  priceToLocaleString,
} from "@shared/lib/utils/price";
import { ContentCard } from "@/components/common";
import { useCustomTranslation } from "@/utils/useCustomTranslation";
interface PriceProps {
  usedPoints: number;
  price: number;
  discountRate: number;
  onFinalPriceChange?: (finalPrice: number) => void;
  paymentStatus?: boolean | false;
  language: string;
  // isKo: boolean;
}

export const PriceContent = ({
  usedPoints,
  price,
  discountRate,
  onFinalPriceChange,
  paymentStatus,
  language,
  // isKo,
}: PriceProps) => {
  const { t } = useCustomTranslation();
  const isKo = language === "ko";

  const [finalPrice, setFinalPrice] = useState<number>(
    calculateFinalPrice(price, discountRate, usedPoints, language) || 0,
  );

  useEffect(() => {
    const newFinalPrice = calculateFinalPrice(
      price,
      discountRate,
      usedPoints,
      language,
    );
    setFinalPrice(newFinalPrice || 0);

    // 부모 컴포넌트로 값 전달
    if (onFinalPriceChange) {
      onFinalPriceChange(newFinalPrice || 0);
    }
  }, [usedPoints, price, discountRate, onFinalPriceChange]);

  const hasDiscount = price !== 0 && discountRate !== 0;
  const discountedPrice = price * discountRate;
  const formattedPrice = priceToLocaleString(discountedPrice);

  return (
    <ContentCard>
      <PriceContentContainer>
        {!paymentStatus && (
          <CommonWrapper>
            <CardTitle>{t("payment_amount")}</CardTitle>
          </CommonWrapper>
        )}
        <CommonWrapper>
          <PriceInfoWrapper>
            <PriceText>
              {!paymentStatus ? t("original_price") : t("class_price")}
            </PriceText>
            <PriceText>
              {isKo
                ? `${priceToLocaleString(price)}${t("won")}`
                : `$${priceToLocaleString(price)}`}
            </PriceText>
          </PriceInfoWrapper>
          <PriceInfoWrapper isDiscount={true}>
            <PriceText>
              {!paymentStatus ? t("discount_amount") : t("discount")}
            </PriceText>
            <PriceText>
              {isKo
                ? hasDiscount
                  ? `-${formattedPrice}${t("won")}`
                  : 0
                : hasDiscount
                  ? `-$${formattedPrice}`
                  : 0}
            </PriceText>
          </PriceInfoWrapper>
          {isKo && (
            <PriceInfoWrapper>
              <PriceText>{t("used_points")}</PriceText>
              <PriceText>
                {usedPoints === 0
                  ? 0
                  : usedPoints !== 0 && "-" + priceToLocaleString(usedPoints)}
                {t("won")}
              </PriceText>
            </PriceInfoWrapper>
          )}
          <DivideLine />
          <PriceInfoWrapper>
            <CardTitle>{t("total_payment_amount")}</CardTitle>
            <CardTitle>
              {isKo
                ? `${priceToLocaleString(finalPrice || 0)} ${t("won")}`
                : `$${priceToLocaleString(finalPrice || 0)}`}
            </CardTitle>
          </PriceInfoWrapper>
        </CommonWrapper>
      </PriceContentContainer>
    </ContentCard>
  );
};

const PriceContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const CommonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const CardTitle = styled.div`
  ${({ theme }) => theme.typography.h3.b};
  color: ${({ theme }) => theme.colors.text.high.white};
`;

const PriceInfoWrapper = styled.div<{ isDiscount?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  ${({ theme }) => theme.typography.body2.m};
  color: ${({ isDiscount, theme }) =>
    isDiscount
      ? theme.colors.state.error.default
      : theme.colors.text.high.white};
`;

const PriceText = styled.div``;

const DivideLine = styled.div`
  position: relative;
  margin: 12px 0;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.gray.gray30};
  }
`;
