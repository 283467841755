import { useRouter } from "next/router";
import styled from "@emotion/styled";

import { ClassCard } from ".";
import {
  CourseContentsDto,
  MainCourseContentsDto,
} from "@shared/generated/api/model";

interface CourseDataProps<T extends string | null> {
  classSection: T;
  courseData: T extends string ? CourseContentsDto[] : MainCourseContentsDto[];
}

export const ClassCardSection = <T extends string | null>({
  courseData,
  classSection,
}: CourseDataProps<T>) => {
  const router = useRouter();

  return (
    <CardContainer>
      {courseData &&
        courseData.map((item) => {
          if (classSection) {
            return (
              <ClassCard
                type={item.courseType}
                key={item.courseId}
                title={item.title}
                teacherName={item.artistName}
                thumbnailImage={item.thumbnailImage}
                handleRoute={() =>
                  router.push(`/class/live/${classSection}/${item.courseId}`)
                }
              />
            );
          } else {
            const mainItem = item as MainCourseContentsDto;
            return (
              <ClassCard
                type={mainItem.courseType}
                key={mainItem.courseId}
                title={mainItem.title}
                teacherName={mainItem.artistName}
                thumbnailImage={mainItem.thumbnailImage}
                handleRoute={() =>
                  router.push(
                    `/class/live/${mainItem.router}/${mainItem.courseId}`,
                  )
                }
              />
            );
          }
        })}
    </CardContainer>
  );
};

const CardContainer = styled.div`
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(4, 1fr);
  ${({ theme }) => theme.media.tablet} {
    grid-template-columns: repeat(3, 1fr);
  }
  ${({ theme }) => theme.media.tabletSm} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${({ theme }) => theme.media.mobile} {
    gap: 12px;
  }
`;
