import { useRouter } from "next/router";
import styled from "@emotion/styled";

import CalendarIcon from "@shared/lib/assets/images/icons/line/16/line-calender.svg";
import CheckCalendarIcon from "@shared/lib/assets/images/icons/line/16/line-calender-check.svg";
import { Icon } from "@shared/lib/components/common/atom/icon";
import { ICON_SVG } from "@shared/lib/constants/import/icon-svg";
import { formatDate } from "@shared/lib/utils/date/formatSchedule";
import {
  discountedTotalPrice,
  priceToLocaleString,
} from "@shared/lib/utils/price";
import { useCustomTranslation } from "@/utils/useCustomTranslation";

import {
  CourseInDetailResponseDto,
  CycleResponseDto,
} from "@shared/generated/api/model";

interface OrderProps {
  courseInfo: CourseInDetailResponseDto;
  cycleInfo: CycleResponseDto;
}

export const CourseContent = ({ courseInfo, cycleInfo }: OrderProps) => {
  const { t } = useCustomTranslation();
  const router = useRouter();
  const { id, title, type, price, discountRate, category, language } =
    courseInfo;
  const { startsAt } = cycleInfo;

  return (
    <CourseContainer>
      <TitleWrapper>
        <CourseTitle>{title}</CourseTitle>
        <DetailLink
          onClick={() =>
            router.push(`/class/live/${category?.section?.router}/${id}`)
          }
        >
          {t("view_details")}
          <Icon icon={ICON_SVG.LINE.SIZE_16.ICON_ARROW_RIGHT} />
        </DetailLink>
      </TitleWrapper>
      <InfoWrapper>
        <InfoSection>
          <IconTitle>
            <CalendarIcon />
            {t("class_type")}
          </IconTitle>
          <ContentText isType={true}>
            LIVE {type === "SINGLE" ? t("one_day") : t("multiple_sessions")}
          </ContentText>
        </InfoSection>
        <InfoSection>
          <IconTitle>
            <CheckCalendarIcon />
            {t("selected_schedule")}
          </IconTitle>
          <ContentText>
            {formatDate(new Date(startsAt), { withTime: true })}
          </ContentText>
        </InfoSection>
      </InfoWrapper>
      {price && (
        <PriceWrapper>
          <CancelledText>
            {language === "ko"
              ? `${priceToLocaleString(price)}${t("won")}`
              : `${t("dollar")}${priceToLocaleString(price)}`}
          </CancelledText>
          <PriceText>
            {language === "ko"
              ? `${discountedTotalPrice(price, discountRate, language)}${t("won")}`
              : `${t("dollar")}${discountedTotalPrice(price, discountRate, language)}`}
          </PriceText>
        </PriceWrapper>
      )}
    </CourseContainer>
  );
};

const CourseContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const CourseTitle = styled.div`
  ${({ theme }) => theme.typography.h3.m}
`;

const DetailLink = styled.div`
  ${({ theme }) => theme.typography.body2.b}
  color: ${({ theme }) => theme.colors.text.medium.black};
  display: flex;
  height: 100%;
  align-items: center;
  gap: 2px;
  cursor: pointer;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const InfoSection = styled.div`
  display: flex;
  gap: 8px;
`;

const IconTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  ${({ theme }) => theme.typography.body2.m};
  color: ${({ theme }) => theme.colors.icon.inactive.black};
  img {
    path {
      stroke: ${({ theme }) => theme.colors.icon.inactive.black};
    }
  }
  /* width: 90px; */
`;

const ContentText = styled.div<{ isType?: boolean | false }>`
  ${({ theme }) => theme.typography.body2.m};
  color: ${({ theme, isType }) =>
    isType ? theme.colors.sub.orange.default : theme.colors.icon.active.black};
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const CancelledText = styled.s`
  ${({ theme }) => theme.typography.body1.m};
  color: ${({ theme }) => theme.colors.text.medium.black};
  display: flex;
`;

const PriceText = styled.div`
  ${({ theme }) => theme.typography.h3.b};
  color: ${({ theme }) => theme.colors.text.high.black};
`;
