import { useEffect } from "react";
import { useRouter } from "next/router";
import { getSession, signIn } from "next-auth/react";
import styled from "@emotion/styled";
import { useQueryClient } from "@tanstack/react-query";

import {
  AcademyBanner,
  AcademyBrandBanner,
  ClassCardSection,
  LiveClassCategory,
  MainBanner,
  ReviewsBanner,
  TeacherApplyBanner,
  TitleDescriptionSection,
} from "@/components/domain";
import { MAX_WIDTH } from "@/constants/style/layout";
import { useCustomTranslation } from "@/utils/useCustomTranslation";

import { useContentsControllerGetGlobalContents } from "@shared/generated/api/fn/kac/contents/contents";
import { getUserControllerGetProfileQueryKey } from "@shared/generated/api/fn/kac/user/user";

export const MainTemplate = () => {
  const { t } = useCustomTranslation();
  const router = useRouter();
  const locale = (router.locale as "ko" | "en") || "ko";
  const isKo = locale === "ko";
  const { data: mainData } = useContentsControllerGetGlobalContents(locale);
  const proxyToken = (router?.query?.token as string) || null;
  const queryClient = useQueryClient();

  // console.log(mainData);
  const {
    BANNER: bannerData,
    ACADEMY: academyData,
    COURSE: courseData,
  } = mainData || {};

  useEffect(() => {
    const handleSignIn = async () => {
      if (!proxyToken) return;

      const result = await signIn("credentials", {
        accessToken: proxyToken,
        redirect: false,
      });

      if (result?.status === 200) {
        await getSession();

        queryClient.refetchQueries({
          queryKey: getUserControllerGetProfileQueryKey(),
        });
        router.replace("/");
      }
    };

    handleSignIn();
  }, [proxyToken]);

  return (
    <MainTemplateContainer>
      {bannerData && <MainBanner bannerData={bannerData} />}
      <MainTemplateInnerContainer>
        <ClassSectionWrapper>
          {courseData &&
            courseData[0]?.contents.length !== 0 &&
            courseData.map((course) => (
              <section
                key={course.title}
                className="p16"
              >
                <TitleDescriptionSection
                  title={course?.title || ""}
                  description={course?.description || ""}
                />
                <ClassCardSection
                  courseData={course?.contents}
                  classSection={null}
                />
              </section>
            ))}

          {academyData && academyData?.academyBanners.length !== 0 && (
            <section>
              <div className="p16">
                <TitleDescriptionSection
                  title={t("kac_x_academy_brand")}
                  description={t("kac_proposes_academy_brands")}
                />
              </div>
              <AcademyBrandBanner academyData={academyData} />
            </section>
          )}

          {isKo && (
            <section>
              <AcademyBanner isLandingPage={true} />
            </section>
          )}

          <section>
            <ReviewsBanner />
          </section>

          {isKo && (
            <section>
              <TeacherApplyBanner />
            </section>
          )}

          <section>
            <LiveClassCategory />
          </section>
        </ClassSectionWrapper>
      </MainTemplateInnerContainer>
    </MainTemplateContainer>
  );
};

const MainTemplateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.brand.primary.default};
`;

const MainTemplateInnerContainer = styled.div`
  width: 100%;
  max-width: ${MAX_WIDTH.INNER_CONTAINER.PC}px;
`;

const ClassSectionWrapper = styled.section`
  width: 100%;
  max-width: ${MAX_WIDTH.INNER_CONTAINER.PC}px;
  margin: 0 auto;
  padding: 120px 0;
  display: flex;
  flex-direction: column;
  gap: 80px;
  ${({ theme }) => theme.media.tabletSm} {
    padding: 40px 0;
    gap: 40px;
  }
  .p16 {
    padding: 0 16px;
  }
`;
