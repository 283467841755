import { ChangeEvent, useEffect, useState } from "react";
import styled from "@emotion/styled";

import {
  InquirySearchBar,
  InquiryTable,
  InquiryTypeButtons,
} from "@/components/domain";
import { PageHeader } from "@/components/ui/headers";
import { useAccessToken } from "@/hooks/auth/use-access-token";
import { useCustomTranslation } from "@/utils/useCustomTranslation";

import {
  getUserControllerPointHistoryQueryKey,
  useUserControllerInquiries,
} from "@shared/generated/api/fn/kac/user/user";
import { UserControllerInquiriesTypes } from "@shared/generated/api/model";

export const InquiryTemplate = () => {
  const { t } = useCustomTranslation();
  const [selected, setSelected] = useState(t("class_inquiry"));
  const [types, setTypes] = useState<UserControllerInquiriesTypes[]>([
    UserControllerInquiriesTypes.COURSE_GENERAL,
    UserControllerInquiriesTypes.COURSE_SCHEDULE,
  ]);
  const [total, setTotal] = useState(1);
  const [curPage, setCurPage] = useState(1);
  const PAGE_SIZE = 10;
  const [searchParams, setSearchParams] = useState<{
    selected: string;
    searchValue: string;
  }>({ selected: "", searchValue: "" });
  const headers = [
    t("id"),
    t("title"),
    t("teacher_name"),
    t("writer"),
    t("registration_date"),
    t("processing_status"),
  ];

  const { accessToken } = useAccessToken();
  const { data: inquiryData } = useUserControllerInquiries(
    {
      skip: (curPage - 1) * PAGE_SIZE,
      take: PAGE_SIZE,
      types: types.join(",") as unknown as UserControllerInquiriesTypes,
      ...(searchParams.searchValue
        ? searchParams.selected === t("title")
          ? { subjectQuery: searchParams.searchValue }
          : { contentQuery: searchParams.searchValue }
        : {}),
    },
    {
      request: { accessToken },
      query: {
        enabled: !!accessToken,
        queryKey: [
          getUserControllerPointHistoryQueryKey(),
          { curPage, pageSize: PAGE_SIZE, types, searchParams },
        ],
      },
    },
  );

  const handleSearch = (selected: string, searchValue: string) => {
    setSearchParams({ selected, searchValue });
  };

  const handlePageChange = (_event: ChangeEvent<unknown>, newPage: number) => {
    setCurPage(newPage);
  };

  const handleInquiryType = (type: string) => {
    setSelected(type);
  };

  useEffect(() => {
    setTotal(1);
    setSearchParams({ selected: "", searchValue: "" });
    selected === t("class_inquiry")
      ? setTypes([
          UserControllerInquiriesTypes.COURSE_GENERAL,
          UserControllerInquiriesTypes.COURSE_SCHEDULE,
        ])
      : setTypes([UserControllerInquiriesTypes.COURSE_QNA]);
  }, [selected]);

  useEffect(() => {
    inquiryData
      ? setTotal(Math.ceil(inquiryData?.count / PAGE_SIZE))
      : setTotal(1);
  }, [inquiryData]);

  // console.log(inquiryData);

  return (
    <>
      <PageHeader title={t("inquiry_board")} />
      <InquirySection>
        <InquiryTypeButtons
          selected={selected}
          handleInquiryType={handleInquiryType}
        />
        <InquirySearchBar
          selected={selected}
          onSearch={handleSearch}
        />
        <InquiryTable
          total={total}
          curPage={curPage}
          handlePageChange={handlePageChange}
          rows={inquiryData?.rows || []}
          headers={headers}
          isQna={selected !== t("class_inquiry")}
        />
      </InquirySection>
    </>
  );
};

const InquirySection = styled.div`
  width: 100%;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
