import { ReactNode } from "react";
import styled from "@emotion/styled";

import { useCustomTranslation } from "@/utils/useCustomTranslation";

export const IntroductionSection = ({ children }: { children: ReactNode }) => {
  const { t } = useCustomTranslation();

  return (
    <IntroductionContainer>
      <IntroductionTitle>{t("class_introduction")}</IntroductionTitle>
      {children}
    </IntroductionContainer>
  );
};

const IntroductionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  img {
    width: 100%;
  }
`;

const IntroductionTitle = styled.div`
  ${({ theme }) => theme.typography.h3.b}
  color: ${({ theme }) => theme.colors.text.high.white}
`;
