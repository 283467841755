import { useRef, useState } from "react";
import { useRouter } from "next/router";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper/types";
import styled from "@emotion/styled";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import PauseRoundedIcon from "@mui/icons-material/PauseRounded";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";

import { useMediaQueryMaxWidth } from "@shared/lib/hooks/use-media-query-max-width";
import { MAX_WIDTH } from "@/constants/style/layout";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { BannerDto } from "@shared/generated/api/model";

export const MainBanner = ({ bannerData }: { bannerData: BannerDto[] }) => {
  const swiperRef = useRef<SwiperType | null>(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const { isTabletSm } = useMediaQueryMaxWidth();
  const router = useRouter();
  const { locale } = router;
  const isKo = locale === "ko";

  const toggleAutoplay = () => {
    if (swiperRef.current) {
      if (isPlaying) {
        swiperRef.current.autoplay.stop();
      } else {
        swiperRef.current.autoplay.start();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const tempData = [
    {
      academyId: null,
      courseId: null,
      title: isKo ? "[사이트 리뉴얼 오픈]" : "[Site Renewal Open]",
      description: isKo
        ? "25년 3월 4일 케아클 사이트가 리뉴얼 오픈하였습니다.<br/>이번 리뉴얼 통해 보다 나은 서비스 제공을 위해 최선을 다하겠습니다.<br/>(세부 사항 공지사항 참조)"
        : "K-ArtistClass website was renewed in March 2025.<br/>We’ll continue to improve for better service.<br/>(See Notice for details)",
      pcFilePath: "/images/main/notice-banner_pc.jpg",
      mobileFilePath: "/images/main/notice-banner_mo.jpg",
      router:
        "https://kacmediagroup.notion.site/14adc5a604d380a08d48d51e809f3d9d#157dc5a604d3806a931deb37486c8b20",
    },
    ...bannerData,
  ];

  return (
    <SwiperContainer>
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={10}
        slidesPerView={1}
        navigation={{
          prevEl: ".prev-button",
          nextEl: ".next-button",
        }}
        pagination={{
          el: ".custom-pagination",
          // type: "custom",
        }}
        autoplay={{ delay: 3000 }}
        loop
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
      >
        {tempData.map((banner, idx) => (
          <SwiperSlide
            key={idx}
            onClick={() =>
              idx === 0
                ? window.open(
                    "https://kacmediagroup.notion.site/14adc5a604d380a08d48d51e809f3d9d#157dc5a604d3806a931deb37486c8b20",
                  )
                : router.push(
                    banner?.academyId
                      ? `/academy/${banner.academyId}`
                      : `/class/live/${banner.router}/${banner.courseId}`,
                  )
            }
          >
            <StyledImageContainer
              imageUrl={isTabletSm ? banner.mobileFilePath : banner.pcFilePath}
            >
              <ContentContainer>
                <DimLayer>
                  <BannerTitle
                    dangerouslySetInnerHTML={{ __html: banner.title || "" }}
                  />
                  <BannerSummary
                    dangerouslySetInnerHTML={{
                      __html: banner.description || "",
                    }}
                  />
                </DimLayer>
              </ContentContainer>
            </StyledImageContainer>
          </SwiperSlide>
        ))}
        <ControllerContainer>
          <CustomPagination className="custom-pagination" />
          <NavigationWrapper>
            <ButtonWrapper className="prev-button">
              <KeyboardArrowLeftRoundedIcon />
            </ButtonWrapper>
            <ButtonWrapper onClick={toggleAutoplay}>
              {isPlaying ? <PauseRoundedIcon /> : <PlayArrowRoundedIcon />}
            </ButtonWrapper>
            <ButtonWrapper className="next-button">
              <KeyboardArrowRightRoundedIcon />
            </ButtonWrapper>
          </NavigationWrapper>
        </ControllerContainer>
      </Swiper>
    </SwiperContainer>
  );
};

const SwiperContainer = styled.div`
  width: 100%;
  cursor: pointer;
`;

const StyledImageContainer = styled.div<{ imageUrl: string }>`
  width: 100%;
  min-height: 340px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${(props) => props.imageUrl});
  background-size: cover;
  background-position: center;
  ${({ theme }) => theme.media.tabletSm} {
    min-height: auto;
    aspect-ratio: 16 / 9;
  }

  ${({ theme }) => theme.media.mobile} {
    aspect-ratio: 15 / 14;
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: ${MAX_WIDTH.INNER_CONTAINER.PC}px;
  height: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  ${({ theme }) => theme.media.tabletSm} {
    display: flex;
    justify-content: end;
    align-items: center;
    text-align: center;
    padding: 0;
  }
`;

const DimLayer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  ${({ theme }) => theme.media.tabletSm} {
    padding: 24px 16px 0;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0) 90%
    );
  }
`;

const BannerTitle = styled.div`
  color: #fff;
  font-size: 30px;
  letter-spacing: -0.3px;
  font-weight: 700;
  line-height: 125%;
  ${({ theme }) => theme.media.tabletSm} {
    ${({ theme }) => theme.typography.h2.b};
    color: #fff;
  }
`;

const BannerSummary = styled.div`
  ${({ theme }) => theme.typography.body1.b};
  color: ${({ theme }) => theme.colors.gray.gray90};
  padding-bottom: 26px;
  ${({ theme }) => theme.media.tabletSm} {
    ${({ theme }) => theme.typography.body2.m};
    padding-bottom: 8px;
    color: ${({ theme }) => theme.colors.gray.gray90};
  }
`;

const ControllerContainer = styled.div`
  width: 100%;
  max-width: ${MAX_WIDTH.INNER_CONTAINER.PC}px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  gap: 8px;
  z-index: 10;

  ${({ theme }) => theme.media.tabletSm} {
    position: static;
    top: auto;
    left: auto;
    transform: none;
  }
  .swiper-pagination {
    width: 100%;
    max-width: 80px;
    position: static !important;
    display: flex;
    justify-content: center;
    align-items: end;
    background-color: ${({ theme }) => theme.colors.brand.secondary.default};
    border-radius: 36px;
    padding: 4px 12px;
  }

  .custom-pagination {
    width: 100%;
    display: flex;
    align-items: end;
    gap: 8px;
    ${({ theme }) => theme.media.tabletSm} {
      justify-content: center;
    }
  }

  .custom-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background: ${({ theme }) => theme.colors.icon.inactive.white};
    border-radius: 100%;
    opacity: 1;
    transition: background 0.3s ease;
    margin: 0 !important;
  }

  .custom-pagination .swiper-pagination-bullet-active {
    width: 40px;
    height: 12px;
    background: #fff;
    border-radius: 12px;
  }
`;

const CustomPagination = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.gray.gray100};
  font-size: 14px;
  font-weight: 700;
  height: 28px;
  width: 100%;
`;

const NavigationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: ${({ theme }) =>
    theme.colors.brand.secondary.default}; */
  background-color: ${({ theme }) => theme.colors.icon.inactive.white};
  border-radius: 36px;
  padding: 6px;
  height: 28px;
  width: 80px;
  ${({ theme }) => theme.media.tabletSm} {
    display: none;
  }
`;

const ButtonWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  height: 100%;
  color: ${({ theme }) => theme.colors.icon.inactive.black};
`;
