import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import styled from "@emotion/styled";
import { useQueryClient } from "@tanstack/react-query";

import { openToast, TOAST_TYPE } from "@shared/lib/utils/toast";
import { InquiryContent, InquiryTypeButtons } from "@/components/domain";
import { PageHeader } from "@/components/ui/headers";
import { useAccessToken } from "@/hooks/auth/use-access-token";
import { useCustomTranslation } from "@/utils/useCustomTranslation";

import {
  getInquiryControllerGetInquiriesForInstructorQueryKey,
  getInquiryControllerGetInquiriesForStudentQueryKey,
  useInquiryControllerDeleteInquiryByUser,
  useInquiryControllerGetInquiryById,
} from "@shared/generated/api/fn/kac/inquiry/inquiry";
import { UserControllerInquiriesTypes } from "@shared/generated/api/model";

export const InquiryDetailsTemplate = () => {
  const { t } = useCustomTranslation();
  const { accessToken } = useAccessToken();
  const queryClient = useQueryClient();
  const router = useRouter();
  const { id } = router.query;
  const [selected, setSelected] = useState(t("class_inquiry"));

  const { data: InquiryDetailsData } = useInquiryControllerGetInquiryById(
    Number(id),
    { request: { accessToken } },
  );

  const handleInquiryType = (type: string) => {};

  const deleteInquiry = useInquiryControllerDeleteInquiryByUser({
    request: { accessToken },
    mutation: {
      onSuccess: async () => {
        openToast(TOAST_TYPE.SUCCESS, t("inquiry_deletion_completed"));
        const queryKeys = [
          getInquiryControllerGetInquiriesForStudentQueryKey(),
          getInquiryControllerGetInquiriesForInstructorQueryKey(),
        ];
        queryKeys.forEach((queryKey) => {
          queryClient.invalidateQueries({ queryKey });
        });

        router.back();
      },
      onError: (error) => {
        openToast(TOAST_TYPE.ERROR, t("inquiry_deletion_failed"));
        console.error(error);
      },
    },
  });

  const handleDelete = () => {
    // console.log(id);
    InquiryDetailsData?.status === "ANSWERED"
      ? openToast(TOAST_TYPE.ERROR, t("cannot_delete_answered_posts"))
      : deleteInquiry.mutate({ inquiryId: Number(id) });
  };

  useEffect(
    () =>
      InquiryDetailsData?.type ===
        UserControllerInquiriesTypes.COURSE_GENERAL ||
      InquiryDetailsData?.type === UserControllerInquiriesTypes.COURSE_SCHEDULE
        ? setSelected(t("class_inquiry"))
        : setSelected(t("class_qna")),
    [InquiryDetailsData?.type],
  );

  return (
    <>
      <PageHeader title={t("inquiry_board")} />
      <InquirySection>
        <InquiryTypeButtons
          selected={selected}
          handleInquiryType={handleInquiryType}
          readonly={true}
        />
        {InquiryDetailsData && (
          <InquiryContent
            handleDelete={handleDelete}
            InquiryDetailsData={InquiryDetailsData}
            isQna={
              InquiryDetailsData?.type ===
              UserControllerInquiriesTypes.COURSE_QNA
            }
          />
        )}
      </InquirySection>
    </>
  );
};

const InquirySection = styled.div`
  width: 100%;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
