import styled from "@emotion/styled";
import { Skeleton } from "@mui/material";

import {
  BaseModal,
  InquiryForm,
  PriceCard,
  ScrollTab,
  TitleInfoSection,
} from "@/components";
import { MAX_WIDTH } from "@/constants/style/layout";

import { CourseInDetailResponseDto } from "@shared/generated/api/model";

export const LiveClassDetailsTemplate = ({
  classData,
}: {
  classData: CourseInDetailResponseDto;
}) => {
  // console.log(classData);
  return (
    <DetailPageContainer>
      <BaseModal modalKey="inquiryModal">
        <InquiryForm />
      </BaseModal>
      <ContentsWrapper>
        <ThumbnailWrapper>
          {classData?.thumbnailImage ? (
            <img
              src={classData.thumbnailImage}
              alt="class thumbnail image"
            />
          ) : (
            <Skeleton
              variant="rounded"
              width={"100%"}
              height={"660px"}
              animation={false}
              sx={{ background: "#2d2d2d", borderRadius: "8px" }}
            />
          )}
        </ThumbnailWrapper>
        <ContentsSection>
          <ContentsContainer>
            {classData && <TitleInfoSection classData={classData} />}
            {classData && <ScrollTab classData={classData} />}
          </ContentsContainer>
          {classData && <PriceCard classData={classData} />}
        </ContentsSection>
      </ContentsWrapper>
    </DetailPageContainer>
  );
};

const DetailPageContainer = styled.div`
  background: ${({ theme }) => theme.colors.brand.primary.default};
  color: ${({ theme }) => theme.colors.text.high.white} !important;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 530px);
  padding: 40px 0;
  display: flex;
  justify-content: center;
`;

const ContentsWrapper = styled.div`
  width: 100%;
  max-width: ${MAX_WIDTH.INNER_CONTAINER.PC}px;
  margin: 0 auto;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const ThumbnailWrapper = styled.div`
  width: 100%;
  img {
    width: 100%;
    border-radius: 8px;
    object-fit: contain;
  }
`;

const ContentsSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
`;

const ContentsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
