import { useEffect, useState } from "react";
import { SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import Link from "next/link";
import * as yup from "yup";
import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";

import { Button } from "@shared/lib/components/common/atom/button";
import { TextField } from "@shared/lib/components/common/atom/text-feild";
import {
  ALERT_MODAL_TYPE,
  AlertModal,
  AlertModalType,
} from "@shared/lib/components/common/molecules/modal/alert-modal";
import { BUTTON_TYPE } from "@shared/lib/styles/theme";
import { openToast, TOAST_TYPE } from "@shared/lib/utils/toast";
import { MENU } from "@/constants/menu";
import { MAX_WIDTH } from "@/constants/style/layout";
import { useCustomTranslation } from "@/utils/useCustomTranslation";

import { useAuthControllerRequestResetPassword } from "@shared/generated/api/fn/kac/auth/auth";
import { useRouter } from "next/router";

interface FindPasswordForm {
  email: string;
}

export const FindPasswordTemplate = () => {
  const [sendCount, setSendCount] = useState(0);
  const { t } = useCustomTranslation();
  const router = useRouter();

  const resetPasswordSendMutation = useAuthControllerRequestResetPassword({
    mutation: {
      onSuccess: () => {
        const email = getValues("email");
        setAlertModalTexts({
          type: ALERT_MODAL_TYPE.SUCCESS,
          title: t("mail_sent_successfully"),
          subTitle: t("reset_link_sent_to_email", { email }).replaceAll(
            "\\n",
            "\n",
          ),
        });
        setIsAlertModalOpen(true);
      },
      onError: (e) => {
        // console.error(e);
        openToast(TOAST_TYPE.ERROR, t("cannot_request_password_reset"));
      },
    },
  });

  const schema = yup.object({
    email: yup
      .string()
      .trim(t("no_spaces_allowed"))
      .email(t("enter_valid_email_address"))
      .matches(/^(?!.* )/, t("cannot_contain_empty_spaces"))
      .required(t("enter_your_email")),
  });

  const {
    handleSubmit,
    watch,
    setValue,
    getValues,
    setError,
    register,
    trigger,
    clearErrors,
    formState: { errors, isValid },
  } = useForm<FindPasswordForm>({
    resolver: yupResolver<FindPasswordForm>(schema),
    mode: "onChange",
  });
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);

  const [alertModalTexts, setAlertModalTexts] = useState<{
    type: AlertModalType;
    title?: string;
    subTitle?: string;
  }>({
    type: ALERT_MODAL_TYPE.ERROR,
    title: "",
    subTitle: "",
  });

  const onAlertModalClose = () => {
    setIsAlertModalOpen(false);
  };

  const onSubmit: SubmitHandler<FindPasswordForm> = async (data) => {
    resetPasswordSendMutation.mutate({
      data: {
        email: data.email,
      },
    });
    setSendCount(sendCount + 1);
  };

  const onError: SubmitErrorHandler<FindPasswordForm> = async (error) => {
    console.error(error);
  };

  useEffect(() => {
    if (router.query.email) {
      setValue("email", router.query.email as string);
      clearErrors("email");
      trigger("email");
    }
  }, [router.query.email, setValue, clearErrors]);

  return (
    <FindPasswordTemplateContainer>
      <FindPasswordTemplateInnerContainer>
        <FindPasswordCardContainer>
          <FindPasswordTitleContainer>
            <Title>{t("find_password")}</Title>
            <SubTitle>
              <p>{t("new_password_to_your_registered_email_address")}</p>
              <p>{t("we_will_send_you_a_link_to_set_a_new_password")}</p>
            </SubTitle>
          </FindPasswordTitleContainer>

          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <FindPasswordFormContainer>
              <FindPasswordFormTextField
                type="text"
                label={t("email")}
                placeholder={t("enter_your_email")}
                {...register("email")}
                isError={!!errors.email}
                message={errors?.email?.message}
                maxLength={50}
              />

              <FindPasswordButtonContainer>
                <FindPasswordButton
                  styleType={BUTTON_TYPE.FILL}
                  type={"submit"}
                  disabled={!isValid || resetPasswordSendMutation.isPending}
                >
                  {sendCount > 0 ? t("resend") : t("send_email")}
                </FindPasswordButton>
              </FindPasswordButtonContainer>
            </FindPasswordFormContainer>
          </form>

          <RegisteredUserLoginContainer>
            <Link
              href={MENU.LOGIN.link.url}
              passHref
            >
              {t("goto_signin")}
            </Link>
          </RegisteredUserLoginContainer>
        </FindPasswordCardContainer>

        {isAlertModalOpen && (
          <AlertModal
            {...alertModalTexts}
            isOpen={isAlertModalOpen}
            onClose={onAlertModalClose}
          />
        )}
      </FindPasswordTemplateInnerContainer>
    </FindPasswordTemplateContainer>
  );
};

const FindPasswordTemplateContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 50vh;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const FindPasswordTemplateInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 96px 24px;
  width: 100%;
  max-width: ${MAX_WIDTH.INNER_CONTAINER.PC}px;

  ${({ theme }) => theme.media.mobile} {
    padding: 96px 16px;
  }
`;

const FindPasswordCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 365px;

  gap: 40px;

  ${({ theme }) => theme.media.mobile} {
    width: 100%;
    max-width: 343px;
  }
`;

const FindPasswordTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

const Title = styled.div`
  ${({ theme }) => theme.typography.h1.b};
  color: ${({ theme }) => theme.colors.text.high.black};
`;

const SubTitle = styled.div`
  p {
    padding: 0;
    margin: 0;

    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 145%; /* 20.3px */

    color: ${({ theme }) => theme.colors.text.medium.black};
  }
`;

const FindPasswordFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const FindPasswordFormTextField = styled(TextField)`
  gap: 4px;
  flex: 1 0 0;
`;

const FindPasswordButtonContainer = styled.div``;

const FindPasswordButton = styled(Button)`
  width: 100%;

  &.btn-fill {
    border-radius: 4px;
  }
`;

const RegisteredUserLoginContainer = styled.div`
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.colors.text.medium.black};
  a {
    color: ${({ theme }) => theme.colors.text.high.black};
  }
`;
