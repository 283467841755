import { useState } from "react";
import { useMedia } from "react-use";
import { useRouter } from "next/router";
import { Autoplay, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import styled from "@emotion/styled";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";

import { useMediaQueryMaxWidth } from "@shared/lib/hooks/use-media-query-max-width";
import { useCustomTranslation } from "@/utils/useCustomTranslation";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

export const ReviewsBanner = () => {
  const { t } = useCustomTranslation();
  const isTableMd = useMedia(`(max-width: 930px)`, false);
  const isMobileMd = useMedia(`(max-width: 700px)`, false);
  const { isDesktop, isTabletSm, isMobile } = useMediaQueryMaxWidth();
  const [isScrolled, setIsScrolled] = useState(false);

  const router = useRouter();
  const locale = (router.locale as "ko" | "en") || "ko";
  const banners = Array.from({ length: 8 }, (_, index) => ({
    id: index + 1,
    imageUrl: `/images/main/review${index + 1}_${locale}.png`,
  }));

  const handleResize = (swiper: any) => {
    const isOverflowing =
      swiper.wrapperEl.scrollWidth > swiper.wrapperEl.clientWidth;
    setIsScrolled(isOverflowing);
  };

  return (
    <ReviewsBannerContainer>
      <ControllerSection>
        <ReviewTextSection>
          <ReviewTitle>{t("real_live_class_reviews")}</ReviewTitle>
          <ReviewDescription>
            {t("reasons_students_take_kac_classes")}
          </ReviewDescription>
        </ReviewTextSection>
        {!isTableMd && (
          <NavigationWrapper>
            <NavigationButton className="prev-button">
              <KeyboardArrowLeftRoundedIcon />
            </NavigationButton>
            <NavigationButton className="next-button">
              <KeyboardArrowRightRoundedIcon />
            </NavigationButton>
          </NavigationWrapper>
        )}
      </ControllerSection>
      <SwiperSection isScrolled={isScrolled}>
        <Swiper
          modules={[Navigation, Autoplay]}
          slidesPerView={isMobileMd ? 1.2 : 3}
          spaceBetween={isDesktop ? "16px" : "24px"}
          centeredSlides={false}
          navigation={{
            prevEl: ".prev-button",
            nextEl: ".next-button",
          }}
          autoplay={{ delay: 3000 }}
          speed={1500}
          loop
          onInit={handleResize}
          onResize={handleResize}
        >
          {banners.map((banner) => (
            <SwiperSlide key={banner.id}>
              <ImgWrapper>
                <StyledImage
                  src={banner.imageUrl}
                  alt={`Banner ${banner.id}`}
                />
              </ImgWrapper>
            </SwiperSlide>
          ))}
        </Swiper>
      </SwiperSection>
    </ReviewsBannerContainer>
  );
};

const ReviewsBannerContainer = styled.div`
  display: flex;
  gap: 24px;
  justify-content: space-between;
  padding: 0 16px;
  @media (max-width: 930px) {
    flex-direction: column;
    gap: 16px;
    padding: 0;
  }
  ${({ theme }) => theme.media.tablet} {
    gap: 16px;
  }
`;

const ControllerSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  max-width: 210px;
  @media (max-width: 930px) {
    padding: 0 16px;
  }
`;

const ReviewTextSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ReviewTitle = styled.div`
  ${({ theme }) => theme.typography.h1.b};
  color: ${({ theme }) => theme.colors.text.high.white};
  ${({ theme }) => theme.media.tablet} {
    ${({ theme }) => theme.typography.h2.b};
    color: ${({ theme }) => theme.colors.text.high.white};
  }
`;

const ReviewDescription = styled.div`
  ${({ theme }) => theme.typography.body1.b};
  color: ${({ theme }) => theme.colors.text.medium.white};
  max-width: 140px;

  ${({ theme }) => theme.media.tablet} {
    max-width: 120px;
    ${({ theme }) => theme.typography.body2.m};
    color: ${({ theme }) => theme.colors.text.medium.white};
  }
`;

const ImgWrapper = styled.div`
  width: 100%;
  max-width: 325px;
  ${({ theme }) => theme.media.tablet} {
    max-width: 100%;
    max-height: fit-content;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  object-fit: contain;
`;

const NavigationWrapper = styled.div`
  display: flex;
  align-items: center;
  display: flex;
  gap: 8px;
`;

const NavigationButton = styled.button`
  background-color: ${({ theme }) => theme.colors.brand.secondary.default};
  border-radius: 40px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  color: ${({ theme }) => theme.colors.icon.inactive.white};
`;

const SwiperSection = styled.div<{ isScrolled: boolean }>`
  position: relative;
  padding: ${({ isScrolled }) => (isScrolled ? "0" : "0 16px")};
  width: 100%;
  max-width: calc(100% - 205px);
  display: flex;
  @media (max-width: 930px) {
    max-width: 100%;
  }
  .swiper-slide:first-of-type {
    margin-left: 16px;
  }
`;
