import moment from "moment";

import "moment/locale/ko";

export const formatLocaleYMD = (
  dateString: string,
  locale: "ko" | "en" = "ko",
) => {
  moment.locale(locale);
  return moment(dateString).format("YYYY-MM-DD");
};
