import { ChangeEvent } from "react";
import styled from "@emotion/styled";
import { TextField } from "@mui/material";

import { ContentCard } from "@/components/common";
import { useCustomTranslation } from "@/utils/useCustomTranslation";

interface CustomerInfoProps {
  customerData: {
    name: string;
    phoneNumber: string;
    email: string;
  };
  onChange?: (
    field: keyof CustomerInfoProps["customerData"],
  ) => (event: ChangeEvent<HTMLInputElement>) => void;
}

export const CustomerInfo = ({ customerData, onChange }: CustomerInfoProps) => {
  const { t } = useCustomTranslation();

  return (
    <ContentCard>
      {onChange ? (
        <CustomerInfoContainer>
          <CommonWrapper>
            <CardTitle>{t("orderer_information")}</CardTitle>
            <Description>
              {t("enter_contact_information_for_class_guidance")}
            </Description>
          </CommonWrapper>
          <CommonWrapper>
            <InputLabel>{t("name_real_name")}*</InputLabel>
            <CustomTextField
              placeholder={t("enter_your_real_name")}
              value={customerData?.name}
              onChange={onChange("name")}
            />
          </CommonWrapper>
          <CommonWrapper>
            <InputLabel>{t("phone_number")}*</InputLabel>
            <CustomTextField
              placeholder={t("enter_your_mobile_phone_number")}
              value={customerData?.phoneNumber}
              onChange={onChange("phoneNumber")}
            />
          </CommonWrapper>
          <CommonWrapper>
            <InputLabel>{t("email")}*</InputLabel>
            <CustomTextField
              placeholder={t("enter_your_email")}
              value={customerData?.email}
              onChange={onChange("email")}
            />
          </CommonWrapper>
        </CustomerInfoContainer>
      ) : (
        <CustomerInfoContainer>
          <CommonWrapper>
            <CardTitle>{t("orderer_information")}</CardTitle>
          </CommonWrapper>
          <CommonWrapper>
            <InfoTitle>{t("orderer_name")}</InfoTitle>
            <InfoContent>{customerData?.name}</InfoContent>
          </CommonWrapper>
          <CommonWrapper>
            <InfoTitle>{t("phone_number")}</InfoTitle>
            <InfoContent>{customerData?.phoneNumber}</InfoContent>
          </CommonWrapper>
          <CommonWrapper>
            <InfoTitle>{t("email")}</InfoTitle>
            <InfoContent>{customerData?.email}</InfoContent>
          </CommonWrapper>
        </CustomerInfoContainer>
      )}
    </ContentCard>
  );
};

const CustomerInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const CommonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-transition: background-color 9999s ease-out;
    -webkit-box-shadow: 0 0 0px 1000px background-color inset !important;
    -webkit-text-fill-color: white !important;
  }
`;

const CardTitle = styled.div`
  ${({ theme }) => theme.typography.h3.b};
  color: ${({ theme }) => theme.colors.text.high.white};
`;

const Description = styled.div`
  ${({ theme }) => theme.typography.body2.m};
  color: ${({ theme }) => theme.colors.text.medium.white};
`;

const InputLabel = styled.div`
  ${({ theme }) => theme.typography.body2.m};
  color: ${({ theme }) => theme.colors.text.high.white};
`;

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    backgroundColor: "black",
    color: "white",
    "&.Mui-focused": {
      backgroundColor: "black",
      color: "white !important",
    },
    "&:-webkit-autofill": {
      backgroundColor: "black",
      WebkitBoxShadow: "0 0 0px 1000px black inset",
      color: "white !important",
    },
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.colors.brand.white.disabled,
    },
    "&:hover fieldset": {
      borderColor: theme.colors.gray.gray80,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.colors.gray.gray80,
    },
  },
}));

const InfoTitle = styled.div`
  ${({ theme }) => theme.typography.body1.m};
  color: ${({ theme }) => theme.colors.text.medium.white};
`;

const InfoContent = styled.div`
  ${({ theme }) => theme.typography.body1.r};
  color: ${({ theme }) => theme.colors.text.high.white};
`;
