import styled from "@emotion/styled";

import { useMediaQueryMaxWidth } from "@shared/lib/hooks/use-media-query-max-width";
import { ContentCard } from "@/components/common";
import { AcademyBanner } from "@/components/domain";
import {
  academyAdContent,
  compositionTags,
  reasonContent,
  vocalTags,
  voiceActorTags,
} from "@/constants";
import { MAX_WIDTH } from "@/constants/style/layout";
import { useCustomTranslation } from "@/utils/useCustomTranslation";

export const AcademyTemplate = () => {
  const { isTabletSm, isMobile } = useMediaQueryMaxWidth();
  const { t } = useCustomTranslation();

  return (
    <AcademyAdContainer>
      <AcademyBanner />
      <FulledWidthArea color="#000000">
        <ContentContainer>
          <SectionTitle isWhite={true}>{t("why_choose_kac")}</SectionTitle>
          <ReasonCardSection>
            {reasonContent.map((content) => (
              <ContentCard
                backgroundColor="#fff"
                padding="24px"
                key={content.title}
              >
                <ContentWrapper>
                  <LeftWrapper>
                    <TitleWrapper>
                      <Body1M>{content.title}</Body1M>
                      <H1Bold>{content.quantity}</H1Bold>
                    </TitleWrapper>
                    <Body3Bold>{content.description}</Body3Bold>
                  </LeftWrapper>
                  <RightWrapper>
                    <img
                      src={content.img}
                      alt={content.title}
                    />
                  </RightWrapper>
                </ContentWrapper>
              </ContentCard>
            ))}
          </ReasonCardSection>
        </ContentContainer>
      </FulledWidthArea>

      <FulledWidthArea color={"#F4F4F4"}>
        <ContentContainer>
          <SectionTitleWrapper>
            <H3Bold>{t("academy_exclusive_hall")}</H3Bold>
            <SectionTitle
              dangerouslySetInnerHTML={{ __html: t("kac_can_promote_academy") }}
            />
          </SectionTitleWrapper>
          <ReasonCardSection>
            {academyAdContent.map((content) => (
              <AdContent key={content.title}>
                <ImgWrapper>
                  <img
                    src={content.img}
                    className="pt8"
                  />
                </ImgWrapper>
                <Body1M color={"#2065EC"}>{content.title}</Body1M>
                <H1Bold
                  className="center"
                  dangerouslySetInnerHTML={{ __html: content.description }}
                />
                {content.subDescription !== "" && (
                  <Body3Bold>{content.subDescription}</Body3Bold>
                )}
              </AdContent>
            ))}
          </ReasonCardSection>
        </ContentContainer>
      </FulledWidthArea>

      <FulledWidthArea>
        <ContentContainer>
          <SectionTitleWrapper>
            <H3Bold>{t("live_class_operation_support")}</H3Bold>
            <SectionTitle
              dangerouslySetInnerHTML={{
                __html: t(
                  "people_who_cannot_come_to_the_academy_can_also_become_our_students",
                ),
              }}
            />
          </SectionTitleWrapper>
        </ContentContainer>
        <ReviewSection>
          <ReasonCardWrapper>
            <Body1Bold>{t("student_review")}</Body1Bold>
            <ReasonCardSection>
              <BorderCard isGray={true}>
                <LeftWrapper padding={isMobile ? "24px" : "24px 0 24px 24px"}>
                  <Body1M color="#33333399">
                    {t("na_student_composition_subject")}
                  </Body1M>
                  <H1Bold
                    dangerouslySetInnerHTML={{
                      __html: t(
                        "irregular_work_hours_difficult_to_go_to_academy_good_because_non_face_to_face_education_can_be_adjusted_to_my_situation",
                      ),
                    }}
                  />
                </LeftWrapper>
                <ReviewImgWrapper>
                  <img
                    src="/images/academy/memoji1.png"
                    alt=""
                  />
                </ReviewImgWrapper>
              </BorderCard>
              <BorderCard isGray={true}>
                <LeftWrapper padding={isMobile ? "24px" : "24px 0 24px 24px"}>
                  <Body1M color="#33333399">
                    {t("choi_student_voice_actor_subject")}
                  </Body1M>
                  <H1Bold
                    dangerouslySetInnerHTML={{
                      __html: t(
                        "living_in_provinces_hard_to_commute_to_seoul_academy_good_to_meet_good_instructors_non_face_to_face",
                      ),
                    }}
                  />
                </LeftWrapper>
                <ReviewImgWrapper>
                  <img
                    src="/images/academy/memoji2.png"
                    alt="review card image"
                  />
                </ReviewImgWrapper>
              </BorderCard>
            </ReasonCardSection>
          </ReasonCardWrapper>
          <ReasonCardWrapper>
            <Body1Bold>{t("instructor_review")}</Body1Bold>
            <ReasonCardSection>
              <BorderCard>
                <ColorImgWrapper color={"#D3B18D61"}>
                  <ReviewImgWrapper>
                    <img
                      src="/images/academy/memoji3.png"
                      alt=""
                    />
                  </ReviewImgWrapper>
                </ColorImgWrapper>
                <ColorCardContent>
                  <H1Bold
                    dangerouslySetInnerHTML={{
                      __html: t("reduce_cs_burden_focus_on_work_and_lectures"),
                    }}
                  />
                  <H3M
                    dangerouslySetInnerHTML={{
                      __html: t(
                        "student_cs_handled_by_kac_from_lecture_payment_to_completion_allows_focus_on_lectures",
                      ),
                    }}
                  />
                  <Body1M color="#33333399">
                    {t("andrew_instructor_composition_subject")}
                  </Body1M>
                </ColorCardContent>
              </BorderCard>
              <BorderCard>
                <ColorImgWrapper color={"#FECF3961"}>
                  <ReviewImgWrapper>
                    <img
                      src="/images/academy/memoji4.png"
                      alt="review card image"
                    />
                  </ReviewImgWrapper>
                </ColorImgWrapper>
                <ColorCardContent>
                  <H1Bold
                    dangerouslySetInnerHTML={{
                      __html: t(
                        "easier_to_recruit_students_since_starting_kac_lectures",
                      ),
                    }}
                  />
                  <H3M
                    dangerouslySetInnerHTML={{
                      __html: t(
                        "found_four_new_students_in_two_weeks_of_lecture_open_without_worrying_about_complex_marketing",
                      ),
                    }}
                  />
                  <Body1M color="#33333399">
                    {t("sally_instructor_composition_subject")}
                  </Body1M>
                </ColorCardContent>
              </BorderCard>
            </ReasonCardSection>
          </ReasonCardWrapper>
        </ReviewSection>
      </FulledWidthArea>

      <FulledWidthArea color={"#F4F4F4"}>
        <ContentContainer>
          <SectionTitleWrapper>
            <H3Bold>{t("store_entry_procedure")}</H3Bold>
            <SectionTitle
              dangerouslySetInnerHTML={{
                __html: t(
                  "easy_store_entry_with_simple_procedure_and_fast_consultation",
                ),
              }}
            />
          </SectionTitleWrapper>
          <ReasonCardSection noGap={true}>
            <BorderCard>
              <AcademyApplyWrapper>
                <StepButton>STEP 1</StepButton>
                <ImgWrapper>
                  <img
                    src="/images/academy/fill-apply.png"
                    alt="케아클 학원 입점 문의 등록 이미지"
                  />
                </ImgWrapper>
                <H1Bold>입점 문의 항목 입력</H1Bold>
                <Body1M
                  color="#00000099"
                  textAlign="center"
                >
                  입점 문의하기 버튼을 눌러
                  <br />
                  각 항목을 입력 후 입점 문의를
                  <br />
                  완료해 주세요.
                </Body1M>
              </AcademyApplyWrapper>
            </BorderCard>
            <IconWrapper>
              <img
                src={
                  isTabletSm
                    ? "/images/academy/down-arrow-icon.png"
                    : "/images/academy/right-arrow-icon.png"
                }
                alt="right arrow icon"
              />
            </IconWrapper>
            <BorderCard>
              <AcademyApplyWrapper>
                <StepButton>STEP 2</StepButton>
                <ImgWrapper>
                  <img
                    src="/images/academy/fill-chat.png"
                    alt="케아클 담당자 상담 진행 이미지"
                  />
                </ImgWrapper>
                <H1Bold>담당자 상담 진행</H1Bold>
                <Body1M
                  color="#00000099"
                  textAlign="center"
                >
                  문의 확인 후, 담당자가 따로 연락을
                  <br />
                  드립니다. 신청 처리에 따라 시간이
                  <br />
                  조금 소요될 수 있습니다.
                </Body1M>
              </AcademyApplyWrapper>
            </BorderCard>
            <IconWrapper>
              <img
                src={
                  isTabletSm
                    ? "/images/academy/down-arrow-icon.png"
                    : "/images/academy/right-arrow-icon.png"
                }
                alt="right arrow icon"
              />
            </IconWrapper>
            <BorderCard>
              <AcademyApplyWrapper>
                <StepButton>STEP 3</StepButton>
                <ImgWrapper>
                  <img
                    src="/images/academy/fill-store.png"
                    alt="케아클 학원 입점 완료 이미지"
                  />
                </ImgWrapper>
                <H1Bold>{t("store_entry_completed")}</H1Bold>
                <Body1M
                  color="#00000099"
                  textAlign="center"
                  dangerouslySetInnerHTML={{
                    __html: t(
                      "application_completed_upon_submission_of_required_information_and_completion_of_consultation_with_person_in_charge",
                    ),
                  }}
                />
              </AcademyApplyWrapper>
            </BorderCard>
          </ReasonCardSection>
          <ConsultingButton
            onClick={() =>
              window.open(
                "https://docs.google.com/forms/d/e/1FAIpQLSczs-zVBSmIWcJ62cq5-22aiggqUu6dTdANLiSZUYqv-ZoayQ/viewform",
              )
            }
          >
            {t("go_to_apply_consultation")}
          </ConsultingButton>
        </ContentContainer>
      </FulledWidthArea>

      <FulledWidthArea>
        <ContentContainer>
          <SectionTitleWrapper>
            <H3Bold>{t("available_categories_for_store_entry")}</H3Bold>
            <SectionTitle
              dangerouslySetInnerHTML={{
                __html: t("kac_is_waiting_for_these_academies"),
              }}
            />
          </SectionTitleWrapper>
          <ReasonCardSection>
            <BorderCard isShadow={true}>
              <AcademyApplyWrapper>
                <ImgWrapper>
                  <img
                    src="/images/academy/illust-entrance.png"
                    alt="케아클 실용음악 입시"
                  />
                </ImgWrapper>
                <H1Bold color={"#2065EC"}>
                  {t("practical_music_entrance_exam")}
                </H1Bold>
                <TagWrapper>
                  {vocalTags.map((tag, idx) => (
                    <GreyTag key={idx}>#{tag}</GreyTag>
                  ))}
                </TagWrapper>
              </AcademyApplyWrapper>
            </BorderCard>
            <BorderCard isShadow={true}>
              <AcademyApplyWrapper>
                <ImgWrapper>
                  <img
                    src="/images/academy/illust-vocal.png"
                    alt="케아클 실용음악 입시"
                  />
                </ImgWrapper>
                <H1Bold color={"#2065EC"}>
                  {t("composition_vocal_debut")}
                </H1Bold>
                <TagWrapper>
                  {compositionTags.map((tag) => (
                    <GreyTag key={tag}>#{tag}</GreyTag>
                  ))}
                </TagWrapper>
              </AcademyApplyWrapper>
            </BorderCard>
            <BorderCard isShadow={true}>
              <AcademyApplyWrapper>
                <ImgWrapper>
                  <img
                    src="/images/academy/illust-voiceactor.png"
                    alt="케아클 실용음악 입시"
                  />
                </ImgWrapper>
                <H1Bold color={"#2065EC"}>{t("voice_actor")}</H1Bold>
                <TagWrapper>
                  {voiceActorTags.map((tag) => (
                    <GreyTag key={tag}>#{tag}</GreyTag>
                  ))}
                </TagWrapper>
              </AcademyApplyWrapper>
            </BorderCard>
          </ReasonCardSection>
        </ContentContainer>
      </FulledWidthArea>

      <FulledWidthArea color={"#2065EC"}>
        <BottomWrapper>
          <SectionTitle
            isWhite={true}
            dangerouslySetInnerHTML={{
              __html: t("increase_academy_sales_with_kac_manager"),
            }}
          />
          <ApplyButton
            onClick={() =>
              window.open(
                "https://docs.google.com/forms/d/e/1FAIpQLSczs-zVBSmIWcJ62cq5-22aiggqUu6dTdANLiSZUYqv-ZoayQ/viewform",
              )
            }
            dangerouslySetInnerHTML={{
              __html: t("online_application_for_store_entry"),
            }}
          />
        </BottomWrapper>
      </FulledWidthArea>
    </AcademyAdContainer>
  );
};

const AcademyAdContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const FulledWidthArea = styled.div<{ color?: string | "white" }>`
  display: flex;
  width: 100%;
  flex-direction: column;
  background: ${({ color }) => color};
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: ${MAX_WIDTH.INNER_CONTAINER.PC}px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 56px;
  padding: 80px 16px;
`;

const SectionTitle = styled.div<{ isWhite?: boolean | false }>`
  font-weight: 700;
  font-size: 30px;
  letter-spacing: -0.4px;
  line-height: 37.5px;
  text-align: center;
  color: ${({ theme, isWhite }) =>
    isWhite ? theme.colors.text.high.white : theme.colors.text.high.black};
`;

const ReasonCardSection = styled.div<{ noGap?: boolean | false }>`
  width: 100%;
  display: flex;
  gap: ${({ noGap }) => (noGap ? 0 : "20px")};
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.media.tabletSm} {
    flex-direction: column;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 122px;
  justify-content: space-between;
`;

const LeftWrapper = styled.div<{ padding?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  padding: ${({ padding }) => (padding ? padding : 0)};

  ${({ theme }) => theme.media.mobile} {
    .review-title {
      padding-bottom: 24px;
    }
  }
`;

const ColorCardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  padding: 24px 24px 24px 35%;
  @media (min-width: 599px) and (max-width: 768px) {
    padding: 24px 24px 24px 200px;
  }
  ${({ theme }) => theme.media.mobile} {
    padding: 24px;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const RightWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  max-width: 95px;
  img {
    width: 100%;
  }
`;

const Body1M = styled.div<{ color?: string | null; textAlign?: string | null }>`
  ${({ theme }) => theme.typography.body1.m};
  color: ${({ theme, color }) =>
    color ? color : theme.colors.text.high.black};
  text-align: ${({ textAlign }) => (textAlign ? textAlign : "none")};
`;

const H1Bold = styled.div<{ color?: string | null }>`
  ${({ theme }) => theme.typography.h1.b};
  color: ${({ theme, color }) =>
    color ? color : theme.colors.text.high.black};
`;

const Body3Bold = styled.div`
  ${({ theme }) => theme.typography.body3.b};
  color: ${({ theme }) => theme.colors.text.disabled.black};
`;

const H3Bold = styled.div`
  ${({ theme }) => theme.typography.h3.b};
  color: ${({ theme }) => theme.colors.sub.blue.default};
`;

const AdContent = styled.div`
  flex-direction: column;
  gap: 8px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .center {
    text-align: center;
  }
`;

const ImgWrapper = styled.div`
  width: 100%;
  max-width: 120px;
  img {
    width: 100%;
  }
  .pt8 {
    padding-bottom: 8px;
  }
`;

const ReviewSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: ${MAX_WIDTH.INNER_CONTAINER.PC}px;
  margin: 0 auto;
  padding: 0 16px 80px;
  gap: 56px;
`;

const Body1Bold = styled.div`
  ${({ theme }) => theme.typography.body1.b};
  color: ${({ theme }) => theme.colors.text.high.black};
`;

const BorderCard = styled.div<{
  isGray?: boolean | false;
  isShadow?: boolean | false;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: auto;
  position: relative;
  border: 1px solid
    ${({ theme, isShadow }) =>
      isShadow ? "transparent" : theme.colors.gray.gray80};
  border-radius: 8px;
  background: ${({ theme, isGray }) =>
    isGray ? theme.colors.gray.gray95 : theme.colors.text.high.white};
  box-shadow: ${({ isShadow }) =>
    isShadow ? "4px 4px 24px rgba(0, 0, 0, 0.15)" : "none"};
  ${({ theme }) => theme.media.tabletSm} {
    margin-bottom: 0;
  }
  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
  }
`;

const ColorImgWrapper = styled.div<{ color: string }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: ${({ color }) => color};
  width: 30%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  max-width: 170px;
  justify-content: center;
  align-items: center;
  border-radius: 7px 0 0 7px;
  padding: 16px 0;
  ${({ theme }) => theme.media.mobile} {
    border-radius: 7px 7px 0 0;
    max-width: 100%;
    position: relative;
    width: 100%;
    height: auto;
  }
`;

const ReviewImgWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: 140px;
  display: flex;
  align-items: center;

  img {
    width: 100%;
  }
`;

const H3M = styled.div`
  ${({ theme }) => theme.typography.h3.m};
  color: ${({ theme }) => theme.colors.text.high.black};
`;

const StepButton = styled.div`
  ${({ theme }) => theme.typography.body2.m};
  padding: 8px 16px;
  color: ${({ theme }) => theme.colors.sub.blue.default};
  background: #d7e4ff;
  border-radius: 40px;
  max-width: 76px;
  max-height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AcademyApplyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 24px 8px;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 40px;
  height: 100%;
  max-height: 40px;
  margin: 0 8px;
  img {
    width: 100%;
  }
  ${({ theme }) => theme.media.tabletSm} {
    margin: 0 auto;
  }
`;

const ConsultingButton = styled.div`
  ${({ theme }) => theme.typography.h2.b};
  color: ${({ theme }) => theme.colors.brand.white.default};
  background: ${({ theme }) => theme.colors.sub.blue.default};
  border-radius: 8px;
  padding: 12px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 300px;
  height: 100%;
  max-height: 60px;
`;

const TagWrapper = styled.div`
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 300px;
`;

const GreyTag = styled.div`
  ${({ theme }) => theme.typography.body1.m};
  color: ${({ theme }) => theme.colors.text.high.black};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.gray.gray90};
  padding: 4px 8px;
`;

const ApplyButton = styled.div`
  ${({ theme }) => theme.typography.h2.b};
  background: ${({ theme }) => theme.colors.brand.white.default};
  color: ${({ theme }) => theme.colors.text.high.black};
  border-radius: 8px;
  padding: 12px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 300px;
  height: 100%;
  max-height: 60px;
  text-align: center;
`;

const BottomWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  padding: 80px 16px;
  ${({ theme }) => theme.media.tabletSm} {
    flex-direction: column;
  }
  ${({ theme }) => theme.media.mobile} {
    padding: 32px 40px;
  }
`;

const SectionTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const ReasonCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
  width: 100%;
`;
