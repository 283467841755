export const Purchase_Status = {
  COMPLETED: "COMPLETED",
  IN_PROGRESS: "IN_PROGRESS",
} as const;

export type PurchaseStatus = keyof typeof Purchase_Status;

export const Purchase_Type = {
  CARD: "CARD",
  VIRTUAL_ACCOUNT: "VIRTUAL_ACCOUNT",
  MOBILE_PHONE: "MOBILE_PHONE",
  TRANSFER: "TRANSFER",
  POINT: "POINT",
  FOREIGN_EASY_PAY: "FOREIGN_EASY_PAY",
} as const;

export type PurchaseTypes = keyof typeof Purchase_Type;

const purchaseTypeMap: Record<PurchaseTypes, string> = {
  CARD: "card",
  VIRTUAL_ACCOUNT: "virtual_account",
  MOBILE_PHONE: "mobile_phone",
  TRANSFER: "bank_transfer",
  POINT: "point_payment",
  FOREIGN_EASY_PAY: "PayPal",
};

export const convertPurchaseStatus = (status: PurchaseStatus) => {
  if (status === Purchase_Status.COMPLETED) {
    return "payment_completed";
  } else if (status === Purchase_Status.IN_PROGRESS) {
    return "virtual_account_issuance_completed";
  }
  return "";
};

export const convertPurchaseType = (type: PurchaseTypes): string => {
  if (type === null) {
    return "";
  }
  // console.log(purchaseTypeMap[type])
  return purchaseTypeMap[type] || "";
};

export type PaymentStatus =
  | "PENDING"
  | "IN_PROGRESS"
  | "COMPLETED"
  | "FAILED"
  | "CANCELED"
  | "REFUNDED";

type StatusInfo = {
  text: string;
  color: string;
};

export const getStatusInfo = (status: PaymentStatus): StatusInfo => {
  const statusMap: Record<PaymentStatus, StatusInfo> = {
    PENDING: {
      text: "payment_in_progress",
      color: "#9F9F9F",
    },
    IN_PROGRESS: {
      text: "waiting_for_deposit",
      color: "#9F9F9F",
    },
    COMPLETED: {
      text: "payment_completed",
      color: "#0FA858",
    },
    FAILED: {
      text: "payment_failed",
      color: "#9F9F9F",
    },
    CANCELED: {
      text: "order_cancellation",
      color: "#D2D2D2",
    },
    REFUNDED: {
      text: "refund",
      color: "#9F9F9F",
    },
  };

  return statusMap[status];
};
