import { useRouter } from "next/router";
import styled from "@emotion/styled";

import { useMediaQueryMaxWidth } from "@shared/lib/hooks/use-media-query-max-width";

interface ClassBannerProps {
  name: string;
  description: string;
}

export const ClassTypeBanner = ({ name, description }: ClassBannerProps) => {
  const router = useRouter();
  const { category } = router.query;
  const { isTabletSm } = useMediaQueryMaxWidth();
  // console.log(category);

  // 이미지 경로 설정
  const imageUrl = `/images/class/${
    typeof category === "string" ? category.toLowerCase() : ""
  }_${isTabletSm ? "mo" : "pc"}.png`;

  return (
    <BannerWrapper imageUrl={imageUrl}>
      <BannerContent>
        <Title>{name}</Title>
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      </BannerContent>
    </BannerWrapper>
  );
};

const BannerWrapper = styled.div<{ imageUrl: string }>`
  width: 100%;
  height: 200px;
  background-image: url(${(props) => props.imageUrl});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const BannerContent = styled.div`
  width: 100%;
  height: 100%;
  max-width: 500px;
  padding: 0 40px;
  ${({ theme }) => theme.media.tablet} {
    padding: 0 16px;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.colors.text.high.white};
  font-size: 30px;
  font-weight: 700;
  letter-spacing: -0.3px;
`;

const Description = styled.p`
  ${({ theme }) => theme.typography.body2.b}
  color: ${({ theme }) => theme.colors.text.high.white};
  margin: 0;
`;
