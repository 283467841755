import styled from "@emotion/styled";

import { formatDate } from "@shared/lib/utils/date/formatSchedule";
import { priceToLocaleString } from "@shared/lib/utils/price";
import { useCustomTranslation } from "@/utils/useCustomTranslation";

import {
  convertPurchaseType,
  getStatusInfo,
  PaymentStatus,
} from "../../payment";
import {
  PaymentGatewayLogsForPurchase,
  PurchaseResponseDtoCourse,
} from "@shared/generated/api/model";

interface OrderProps {
  courseInfo: PurchaseResponseDtoCourse;
  paidAmount: number;
  status: PaymentStatus;
  paymentGatewayLogs: PaymentGatewayLogsForPurchase[];
  completedAt: string;
  onClick: () => void;
}

export const OrderContent = ({
  courseInfo,
  paidAmount,
  status,
  paymentGatewayLogs,
  completedAt,
  onClick,
}: OrderProps) => {
  const { t } = useCustomTranslation();
  const paymentLog = paymentGatewayLogs?.[0];
  const { paymentMethod } = paymentLog || {};
  const { text, color } = getStatusInfo(status) || {};
  const isKo = courseInfo?.language === "ko";

  return (
    <OrderContentContainer onClick={onClick}>
      <ClassTitle>{(courseInfo?.title as string) ?? ""}</ClassTitle>
      <SplitLine />
      <ContentWrapper>
        <ClassTitle>{t("payment_amount")}</ClassTitle>
        <PriceText>
          {isKo
            ? priceToLocaleString(paidAmount) + t("won")
            : "$" + priceToLocaleString(paidAmount)}
        </PriceText>
      </ContentWrapper>
      <SplitLine />
      <OrderInfoWrapper>
        <ContentWrapper>
          <StatusText>{t("status")}</StatusText>
          <StatusButton color={color}>{t(`${text}`)}</StatusButton>
        </ContentWrapper>
        <ContentWrapper>
          <StatusText>{t("purchase_date")}</StatusText>
          <ValueText>
            {completedAt &&
              formatDate(new Date(completedAt), { withTime: true })}
          </ValueText>
        </ContentWrapper>
        <ContentWrapper>
          <StatusText>{t("payment_method")}</StatusText>
          <ValueText>
            {paymentMethod
              ? t(convertPurchaseType(paymentMethod))
              : t(convertPurchaseType("POINT"))}
          </ValueText>
        </ContentWrapper>
      </OrderInfoWrapper>
    </OrderContentContainer>
  );
};

const OrderContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  cursor: pointer;
`;

const ClassTitle = styled.div`
  ${({ theme }) => theme.typography.h3.b};
`;

const SplitLine = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray.gray90};
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const OrderInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
`;

const PriceText = styled.div`
  ${({ theme }) => theme.typography.h3.b};
  color: ${({ theme }) => theme.colors.state.error.default};
`;

const StatusText = styled.div`
  ${({ theme }) => theme.typography.body2.m};
  color: ${({ theme }) => theme.colors.text.medium.black};
  display: flex;
  align-items: center;
`;

const StatusButton = styled.div<{ color: string }>`
  padding: 4px 8px;
  border-radius: 8px;
  background: ${({ theme, color }) =>
    color ? color : theme.colors.sub.green.default};
  ${({ theme }) => theme.typography.body3.b};
  color: ${({ theme }) => theme.colors.text.high.white};
  display: flex;
  align-items: center;
`;

const ValueText = styled.div`
  ${({ theme }) => theme.typography.body2.m};
  display: flex;
  align-items: center;
`;
