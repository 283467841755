import styled from "@emotion/styled";

import AccountIcon from "@shared/lib/assets/images/icons/line/18/line-account.svg";
import CardIcon from "@shared/lib/assets/images/icons/line/18/line-card.svg";
import CardTextIcon from "@shared/lib/assets/images/icons/line/18/line-card-text.svg";
import PointIcon from "@shared/lib/assets/images/icons/line/18/line-point.svg";
import PaypalIcon from "@shared/lib/assets/images/icons/fill/etc/paypal.svg";
import { ContentCard } from "@/components/common";
import { useCustomTranslation } from "@/utils/useCustomTranslation";

import {
  convertPurchaseStatus,
  convertPurchaseType,
  PurchaseStatus,
  PurchaseTypes,
} from "./convertPurchaseResult";
import { WidgetsPaymentMethodCode } from "./payment.types";
import { PurchaseResultDto } from "@shared/generated/api/model";

const paymentOptions = [
  { icon: <CardIcon />, text: "card", type: WidgetsPaymentMethodCode.CARD },
  {
    icon: <AccountIcon />,
    text: "bank_transfer",
    type: WidgetsPaymentMethodCode.TRANSFER,
  },
  {
    icon: <CardTextIcon />,
    text: "virtual_account",
    type: WidgetsPaymentMethodCode.VIRTUAL_ACCOUNT,
  },
  {
    icon: <PointIcon />,
    text: "point_payment",
    isPoint: true,
    type: WidgetsPaymentMethodCode.POINT,
  },
  {
    icon: <PaypalIcon />,
    text: "PayPal",
    type: WidgetsPaymentMethodCode.FOREIGN_EASY_PAY,
    isGlobalOnly: true,
  },
];

interface PurchaseTypeProps {
  selectedPurchaseType?: string | null;
  handlePurchaseType?: (type: WidgetsPaymentMethodCode) => void;
  purchaseResult?: PurchaseResultDto;
  isKo: boolean;
}

export const PurchaseType = ({
  selectedPurchaseType,
  handlePurchaseType,
  purchaseResult,
  isKo,
}: PurchaseTypeProps) => {
  const { t } = useCustomTranslation();

  return (
    <ContentCard>
      <PurchaseTypeContainer>
        <CardTitle>{t("payment_method")}</CardTitle>
        {handlePurchaseType ? (
          <BtnWrapper isKo={isKo}>
            {paymentOptions.map(
              ({ icon, text, isPoint = false, type, isGlobalOnly }, index) => {
                if (isKo && isGlobalOnly) return null;
                if (!isKo && !isGlobalOnly) return null;

                return (
                  <StyledButton
                    key={index}
                    type="button"
                    isActive={type === selectedPurchaseType}
                    onClick={() => handlePurchaseType(type)}
                  >
                    <IconWrapper
                      isKo={isKo}
                      isActive={type === selectedPurchaseType}
                      isPoint={isPoint}
                    >
                      {icon}
                      <TypeText isActive={type === selectedPurchaseType}>
                        {t(`${text}`)}
                      </TypeText>
                    </IconWrapper>
                  </StyledButton>
                );
              },
            )}
          </BtnWrapper>
        ) : (
          <>
            <InfoWrapper>
              <InfoText>{t("status")}</InfoText>
              <InfoText>
                {purchaseResult?.purchaseStatus &&
                  t(
                    convertPurchaseStatus(
                      purchaseResult?.purchaseStatus as PurchaseStatus,
                    ),
                  )}
              </InfoText>
            </InfoWrapper>
            <InfoWrapper>
              <InfoText>{t("payment_method")}</InfoText>
              <InfoText>
                {purchaseResult?.purchaseType &&
                  t(
                    convertPurchaseType(
                      purchaseResult?.purchaseType as PurchaseTypes,
                    ),
                  )}
              </InfoText>
            </InfoWrapper>
          </>
        )}
      </PurchaseTypeContainer>
    </ContentCard>
  );
};

const PurchaseTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const CardTitle = styled.div`
  ${({ theme }) => theme.typography.h3.b};
  color: ${({ theme }) => theme.colors.text.high.white};
  padding-bottom: 16px;
`;

const BtnWrapper = styled.div<{ isKo: boolean | true }>`
  display: grid;
  grid-template-columns: ${({ isKo }) =>
    isKo ? "repeat(4, 1fr)" : "repeat(1, 1fr)"};
  gap: 8px;
  @media (max-width: 600px) {
    grid-template-columns: ${({ isKo }) =>
      isKo ? "repeat(2, 1fr)" : "repeat(1, 1fr)"};
  }
`;

const StyledButton = styled.button<{ isActive: boolean }>`
  border: 1px solid ${({ theme }) => theme.colors.text.high.white};
  border-radius: 8px;
  padding: 12px 16px;
  width: 100%;
  background: ${({ isActive, theme }) =>
    isActive ? theme.colors.brand.white.default : "transparent"};
`;

const IconWrapper = styled.div<{
  isPoint?: boolean;
  isActive: boolean;
  isKo: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  ${({ isKo, isPoint, isActive, theme }) =>
    isKo &&
    `
    path {
      stroke: ${
        !isPoint
          ? isActive
            ? theme.colors.text.high.black
            : theme.colors.brand.white.default
          : "none"
      };
      fill: ${
        isPoint
          ? isActive
            ? theme.colors.text.high.black
            : theme.colors.brand.white.default
          : "none"
      };
    }

    circle {
      stroke: ${
        isPoint
          ? isActive
            ? theme.colors.text.high.black
            : theme.colors.brand.white.default
          : "none"
      };
    }
  `}
`;

const TypeText = styled.div<{ isActive: boolean }>`
  ${({ theme }) => theme.typography.body1.b};
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.text.high.black : theme.colors.brand.white.default};
`;

const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const InfoText = styled.div`
  ${({ theme }) => theme.typography.body2.m};
  color: ${({ theme }) => theme.colors.text.high.white};
`;
