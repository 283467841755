import { ChangeEvent, useEffect, useState } from "react";

import { PointInfo } from "@/components/domain";
import { useAccessToken } from "@/hooks/auth/use-access-token";

import {
  getUserControllerPointHistoryQueryKey,
  getUserControllerPointInfoQueryKey,
  useUserControllerPointHistory,
  useUserControllerPointInfo,
} from "@shared/generated/api/fn/kac/user/user";

export const PointsTemplate = () => {
  const [total, setTotal] = useState(1);
  const [curPage, setCurPage] = useState(1);
  const PAGE_SIZE = 3;

  const { accessToken } = useAccessToken();
  const { data: pointsData } = useUserControllerPointInfo({
    request: { accessToken },
    query: {
      enabled: !!accessToken,
      queryKey: getUserControllerPointInfoQueryKey(),
    },
  });

  const { data: pointsHistoryData } = useUserControllerPointHistory(
    { skip: (curPage - 1) * PAGE_SIZE, take: PAGE_SIZE },
    {
      request: { accessToken },
      query: {
        enabled: !!accessToken,
        queryKey: [
          getUserControllerPointHistoryQueryKey(),
          { curPage, pageSize: PAGE_SIZE },
        ],
      },
    },
  );

  const handlePageChange = (_event: ChangeEvent<unknown>, newPage: number) => {
    setCurPage(newPage);
  };

  useEffect(() => {
    pointsHistoryData
      ? setTotal(Math.ceil(pointsHistoryData?.count / PAGE_SIZE))
      : setTotal(1);
  }, [pointsHistoryData]);

  return (
    <>
      {pointsData && pointsHistoryData && (
        <PointInfo
          pointsData={pointsData}
          pointsHistoryData={pointsHistoryData}
          total={total}
          curPage={curPage}
          handlePageChange={handlePageChange}
        />
      )}
    </>
  );
};
