import { useRouter } from "next/router";
import styled from "@emotion/styled";
import { useQueryClient } from "@tanstack/react-query";

import { convertLevelText } from "@shared/lib/utils/classData";
import { openToast, TOAST_TYPE } from "@shared/lib/utils/toast";
import { BaseModal } from "@/components/common";
import { useAccessToken } from "@/hooks/auth/use-access-token";
import { useModalStore } from "@/stores";
import { useCustomTranslation } from "@/utils/useCustomTranslation";

import { ShareContent } from "./ShareContent";
import { getCourseControllerCourseQueryKey } from "@shared/generated/api/fn/kac/course/course";
import {
  getUserControllerGetCourseWishlistByCourseIdQueryKey,
  useUserControllerAddToWishlist,
  useUserControllerGetCourseWishlistByCourseId,
  useUserControllerRemoveFromWishlist,
} from "@shared/generated/api/fn/kac/user/user";
import {
  CourseInDetailResponseDto,
  CourseStatsResponseDto,
  CourseTypeEnum,
  DifficultyEnum,
  InstructorInfoDto,
} from "@shared/generated/api/model";
import {
  convertPriceString,
  discountedTotalPrice,
  priceToLocaleString,
} from "@shared/lib/utils/price";
import { useMediaQueryMaxWidth } from "@shared/lib/hooks/use-media-query-max-width";

export const TitleInfoSection = ({
  classData,
}: {
  classData: CourseInDetailResponseDto;
}) => {
  const { t } = useCustomTranslation();
  const { openModal } = useModalStore();
  const {
    title,
    type,
    durationPerSession,
    difficulty,
    defaultMinEnrollments,
    defaultMaxEnrollments,
    language,
    price,
    discountRate,
    totalSessions,
  } = classData;
  const { artistName } = classData.instructor as InstructorInfoDto;
  const { favoriteCount } = classData.stats as CourseStatsResponseDto;
  const router = useRouter();
  const { id } = router.query;
  const { accessToken } = useAccessToken();
  const queryClient = useQueryClient();
  const { isTabletSm } = useMediaQueryMaxWidth();
  console.log(classData);

  const { data: userWishes } = useUserControllerGetCourseWishlistByCourseId(
    Number(id),
    {
      request: { accessToken },
      query: {
        enabled: !!accessToken,
        queryKey: getUserControllerGetCourseWishlistByCourseIdQueryKey(
          Number(id),
        ),
      },
    },
  );

  const postLikeClass = useUserControllerAddToWishlist({
    request: { accessToken },
    mutation: {
      onSuccess: () => {
        openToast(TOAST_TYPE.LIKE, t("added_to_wishlist"));
        queryClient.invalidateQueries({
          queryKey: getUserControllerGetCourseWishlistByCourseIdQueryKey(
            Number(id),
          ),
        }),
          queryClient.invalidateQueries({
            queryKey: getCourseControllerCourseQueryKey(Number(id)),
          });
      },
      onError: (error) => {
        console.error(error);
      },
    },
  });

  const deleteLikeClass = useUserControllerRemoveFromWishlist({
    request: { accessToken },
    mutation: {
      onSuccess: () => {
        openToast(TOAST_TYPE.INFO, t("class_wish_canceled"));
        queryClient.invalidateQueries({
          queryKey: getUserControllerGetCourseWishlistByCourseIdQueryKey(
            Number(id),
          ),
        }),
          queryClient.invalidateQueries({
            queryKey: getCourseControllerCourseQueryKey(Number(id)),
          });
      },
      onError: (error) => console.error(error),
    },
  });

  const handleLikeBtn = () => {
    if (accessToken) {
      userWishes?.isWishlisted
        ? deleteLikeClass.mutate({ courseId: Number(id) })
        : postLikeClass.mutate({ courseId: Number(id) });
    } else {
      openToast(TOAST_TYPE.INFO, t("login_required"));
    }
  };

  const handleShareBtn = () => {
    openModal("shareModal");
  };

  return (
    <ContentsContainer>
      <BaseModal
        modalKey="shareModal"
        backgroundColor="#fff"
      >
        <ShareContent />
      </BaseModal>
      <IconsWrapper>
        <LikesWrapper>
          <img src={"/images/icons/filled-heart.svg"} />
          <span>{favoriteCount || 0}</span>
        </LikesWrapper>
        <LikesWrapper>
          <button onClick={handleLikeBtn}>
            <img
              src={
                userWishes?.isWishlisted
                  ? "/images/icons/fill-heart.svg"
                  : "/images/icons/line-heart.svg"
              }
            />
          </button>
          <button onClick={handleShareBtn}>
            <img src={"/images/icons/share.svg"} />
          </button>
        </LikesWrapper>
      </IconsWrapper>
      <ClassTitle>
        {title}
        <TeacherName>{artistName}</TeacherName>
      </ClassTitle>

      {isTabletSm && (
        <PriceInfoWrapper>
          {discountRate !== 0 && (
            <CancelledText className="cancelled">
              {language !== "ko" && t("dollar")}
              {priceToLocaleString(price || 0)}
              {language === "ko" && t("won")}
            </CancelledText>
          )}
          <PriceInfo>
            {discountRate !== 0 && (
              <span className="discount">
                {Math.round((classData?.discountRate ?? 0) * 10000) / 100}%
              </span>
            )}
            {type === CourseTypeEnum.MULTI && (
              <span className="time">{t("per_session")}</span>
            )}
            {language !== "ko" && <span className="won">{t("dollar")}</span>}
            <span className="price">
              {convertPriceString(
                price,
                discountRate,
                type,
                totalSessions,
                language,
              )}
            </span>
            {language === "ko" && <span className="won">{t("won")}</span>}
          </PriceInfo>
          {type === CourseTypeEnum.MULTI && (
            <SelectSection>
              {discountRate === 0 ? (
                <>
                  {t("total")} {priceToLocaleString(price || 0)} /{" "}
                  {totalSessions}
                  {t("times")}
                </>
              ) : (
                <SelectSection>
                  {t("total")}{" "}
                  {language === "ko"
                    ? `${discountedTotalPrice(price || 0, discountRate, language)}${t("won")} / ${totalSessions}`
                    : `${t("dollar")}${discountedTotalPrice(price || 0, discountRate, language)} / ${totalSessions}`}
                  {t("times")}
                </SelectSection>
              )}
            </SelectSection>
          )}
        </PriceInfoWrapper>
      )}

      <InfoWrapper>
        <InfoBox>
          <IconTitle>
            <img src={"/images/icons/line-calendar.svg"} />
          </IconTitle>
          <IconTitle>{t("class_type")}</IconTitle>
          <InfoTitle>
            {type === "SINGLE" ? t("one_day") : t("multiple_sessions")}
          </InfoTitle>
        </InfoBox>
        <InfoBox>
          <IconTitle>
            <img src={"/images/icons/line-time.svg"} />
          </IconTitle>
          <IconTitle>{t("class_time")}</IconTitle>
          <InfoTitle>
            {t("per_session")} {durationPerSession}
            {t("minutes")}
          </InfoTitle>
        </InfoBox>
        <InfoBox>
          <IconTitle>
            <img src={"/images/icons/line-level.svg"} />
          </IconTitle>
          <IconTitle>{t("difficulty")}</IconTitle>
          <InfoTitle>
            {`${t(convertLevelText(difficulty || DifficultyEnum.BEGINNER))}`}
          </InfoTitle>
        </InfoBox>
        <InfoBox>
          <IconTitle>
            <img src={"/images/icons/line-user.svg"} />
          </IconTitle>
          <IconTitle>{t("number_of_participants")}</IconTitle>
          <InfoTitle>
            {defaultMinEnrollments} ~ {defaultMaxEnrollments}
            {t("people")}
          </InfoTitle>
        </InfoBox>
      </InfoWrapper>
    </ContentsContainer>
  );
};

const ContentsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const IconsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const LikesWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  span {
    ${({ theme }) => theme.typography.body2.m}
    color: ${({ theme }) => theme.colors.text.medium.white};
  }
  button {
    cursor: pointer;
  }
`;

const ClassTitle = styled.div`
  ${({ theme }) => theme.typography.h2.b}
  color: ${({ theme }) => theme.colors.brand.white.default};
`;

const TeacherName = styled.div`
  ${({ theme }) => theme.typography.body1.m}
  color: ${({ theme }) => theme.colors.brand.white.default};
`;

const PriceInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const CancelledText = styled.s`
  ${({ theme }) => theme.typography.body1.m};
  color: ${({ theme }) => theme.colors.text.medium.white};
  display: flex;
  align-items: center;
`;

const PriceInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  .discount {
    ${({ theme }) => theme.typography.h1.b};
    color: ${({ theme }) => theme.colors.state.error.default};
    padding-right: 4px;
  }
  .price {
    ${({ theme }) => theme.typography.h1.b};
    color: ${({ theme }) => theme.colors.text.high.white};
  }
  .time {
    ${({ theme }) => theme.typography.body1.m};
    color: ${({ theme }) => theme.colors.brand.white.default};
  }
  .won {
    ${({ theme }) => theme.typography.body1.m};
    color: ${({ theme }) => theme.colors.brand.white.default};
  }
`;

const SelectSection = styled.div`
  ${({ theme }) => theme.typography.body1.m};
  color: ${({ theme }) => theme.colors.text.medium.white};
  .date {
    color: ${({ theme }) => theme.colors.text.high.white};
    padding-left: 8px;
  }
`;

const InfoWrapper = styled.div`
  width: 100%;
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(4, 1fr);
  ${({ theme }) => theme.media.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const InfoBox = styled.div`
  background: ${({ theme }) => theme.colors.brand.secondary.default};
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px;
  font-size: 14px;
  letter-spacing: -0.4px;
`;

const IconTitle = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.typography.body2.m}
  color: ${({ theme }) => theme.colors.icon.inactive.white};
`;

const InfoTitle = styled.div`
  ${({ theme }) => theme.typography.body2.b}
  color: ${({ theme }) => theme.colors.gray.gray100};
`;
