import { ChangeEvent, useEffect, useState } from "react";
import { useRouter } from "next/router";
import styled from "@emotion/styled";
import { useQueryClient } from "@tanstack/react-query";

import { Button } from "@shared/lib/components/common/atom/button";
import { Icon } from "@shared/lib/components/common/atom/icon";
import { ICON_SVG } from "@shared/lib/constants/import/icon-svg";
import { BUTTON_TYPE } from "@shared/lib/styles/theme";
import { SortOrderEnum } from "@shared/lib/types/api/pagination";
import { openToast, TOAST_TYPE } from "@shared/lib/utils/toast";
import { CustomTextArea } from "@/components/common";
import { useAccessToken } from "@/hooks/auth/use-access-token";
import { InquiryType, useInquiryTypeStore, useModalStore } from "@/stores";
import { useCustomTranslation } from "@/utils/useCustomTranslation";

import {
  getInquiryControllerGetInquiriesForInstructorQueryKey,
  getInquiryControllerGetInquiriesForStudentQueryKey,
  useInquiryControllerCreateInquiry,
} from "@shared/generated/api/fn/kac/inquiry/inquiry";
import {
  CreateCourseInquiryRequestDtoContent,
  InquiryControllerGetInquiriesForInstructorTypes,
  UserControllerInquiriesTypes,
} from "@shared/generated/api/model";

export const InquiryForm = () => {
  const { t } = useCustomTranslation();
  const { accessToken } = useAccessToken();
  const router = useRouter();
  const { id } = router.query;
  const queryClient = useQueryClient();

  const [inquiry, setInquiry] = useState("");
  const [isEmpty, setIsEmpty] = useState(true);
  const [placeholder, setPlaceholder] = useState("");
  const [titleText, setTitleText] = useState("");
  const [subTitleText, setSubTitleText] = useState("");
  const handleChangeInquiry = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setInquiry(e.target.value);
  };
  const { inquiryType } = useInquiryTypeStore();
  const { closeModal } = useModalStore();

  useEffect(
    () => (inquiry.length === 0 ? setIsEmpty(true) : setIsEmpty(false)),
    [inquiry],
  );

  useEffect(() => {
    const text =
      inquiryType === InquiryType.Schedule
        ? t("coordinate_schedule_with_instructor")
        : t("detailed_information_after_admin_approval");
    setPlaceholder(text);
    const title =
      inquiryType === InquiryType.Schedule
        ? t("inquire_about_schedule")
        : t("inquire_to_instructor");
    setTitleText(title);
    const subTitle =
      inquiryType === InquiryType.Schedule
        ? t("enter_available_dates")
        : t("ask_about_the_class");
    setSubTitleText(subTitle);
    // console.log("inquiryType", inquiryType);
  }, [inquiryType]);

  const queryParamsForInquiriesList = {
    types: [
      InquiryControllerGetInquiriesForInstructorTypes.COURSE_QNA,
      InquiryControllerGetInquiriesForInstructorTypes.COURSE_SCHEDULE,
    ].join(","),
    skip: 0,
    take: 10,
    orderBy: { createdAt: SortOrderEnum.DESC },
  } as any;

  const postInquiry = useInquiryControllerCreateInquiry({
    request: { accessToken },
    mutation: {
      onSuccess: () => {
        openToast(TOAST_TYPE.SUCCESS, t("inquiry_successfully_submitted"));

        const queryKeys = [
          getInquiryControllerGetInquiriesForStudentQueryKey(),
          getInquiryControllerGetInquiriesForInstructorQueryKey(),
        ];
        queryKeys.forEach((queryKey) => {
          queryClient.invalidateQueries({ queryKey });
        });
        closeModal("inquiryModal");
      },
      onError: (error) => {
        console.error(error);
        openToast(TOAST_TYPE.ERROR, t("failed_to_submit_inquiry"));
      },
    },
  });

  const handleInquiry = () => {
    const data = {
      courseId: Number(id),
      content: {
        ops: [
          {
            insert: inquiry.replace(/\r?\n/g, "\n"),
          },
        ],
      } as CreateCourseInquiryRequestDtoContent,
      type:
        inquiryType === InquiryType.Schedule
          ? UserControllerInquiriesTypes.COURSE_SCHEDULE
          : UserControllerInquiriesTypes.COURSE_GENERAL,
    };
    postInquiry.mutate({ data });
  };

  return (
    <InquiryFormContainer>
      <InquiryTitle>
        {titleText}
        <CloseIcon
          onClick={() => closeModal("inquiryModal")}
          icon={ICON_SVG.LINE.SIZE_24.ICON_CLOSE}
        />
      </InquiryTitle>
      <SubTitle>{subTitleText}</SubTitle>
      <CustomTextArea
        placeholder={placeholder}
        maxLength={500}
        value={inquiry}
        onChange={handleChangeInquiry}
      />
      <DescriptionText
        dangerouslySetInnerHTML={{
          __html: t("contact_customer_center_check_in_my_menu"),
        }}
      />
      <SubmitButton
        type={"submit"}
        styleType={BUTTON_TYPE.FILL}
        disabled={isEmpty}
        onClick={handleInquiry}
      >
        {t("inquire")}
      </SubmitButton>
    </InquiryFormContainer>
  );
};

const InquiryFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 356px;
`;

const InquiryTitle = styled.div`
  ${({ theme }) => theme.typography.h3.b};
  color: ${({ theme }) => theme.colors.text.high.white};
  display: flex;
  justify-content: space-between;
`;

const SubTitle = styled.div`
  ${({ theme }) => theme.typography.caption.m};
  color: ${({ theme }) => theme.colors.text.medium.white};
  padding-bottom: 16px;
`;

const CloseIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  &:hover {
    cursor: pointer;
  }

  path {
    stroke: ${({ theme }) => theme.colors.brand.white.default};
  }
`;

const DescriptionText = styled.div`
  width: 100%;
  ${({ theme }) => theme.typography.caption.m};
  color: ${({ theme }) => theme.colors.text.medium.white};
  padding-bottom: 16px;
  span {
    font-weight: 700;
  }
`;

const SubmitButton = styled(Button)`
  &.btn-${BUTTON_TYPE.FILL.toLowerCase()} {
    width: 356px;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.sub.blue.default};
    &:focus,
    &:hover {
      background-color: ${({ theme }) => theme.colors.sub.blue.default};
    }

    &:disabled {
      background-color: ${({ theme }) => theme.colors.mui.text.disabled};
    }
    ${({ theme }) => theme.media.mobile} {
      width: 81vw;
    }
  }
`;
